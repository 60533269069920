import React, { useEffect } from "react";
import { Switch, useParams, Redirect, Route } from "react-router-dom";

import { useAdmin } from "context/admin";

import OrdersPage from "views/OrdersPage/OrdersPage";
import LocationsPage from "views/LocationsPage/LocationsPage";
import SettingsPage from "views/SettingsPage/SettingsPage";
import OrderPage from "views/OrdersPage/OrderPage";
import DeliveriesPage from "views/DeliveriesPage/DeliveriesPage";
import SalesPage from "views/Sales/SalesPage";
import { useUser } from "context/user";
import VendorPage from "views/VendorPage/VendorPage";
import ShopUsersPage from "views/UsersPage/ShopUsersPage";
import FileImportsPage from "views/FileImportsPage/FileImportsPage";

export default function ShopPage({ match }) {
  const { shopId } = useParams();
  const admin = useAdmin();
  const user = useUser();

  useEffect(() => {
    if (admin.setSelectedShopId !== shopId) {
      admin.setSelectedShopId(shopId);
    }
  }, [admin, shopId]);

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/orders/:orderId`}
        component={OrderPage}
      />
      <Route
        exact
        path={`${match.url}/orders`}
        component={user.isVendor ? VendorPage : OrdersPage}
      />
      <Route
        exact
        path={`${match.url}/deliveries`}
        component={DeliveriesPage}
      />
      <Route exact path={`${match.url}/users`} component={ShopUsersPage} />
      {/* <Route exact path={`${match.url}/customers`} component={CustomersPage} /> */}
      <Route exact path={`${match.url}/locations`} component={LocationsPage} />
      <Route path={`${match.url}/sales`} component={SalesPage} />
      <Route path={`${match.url}/file-imports`} component={FileImportsPage} />
      <Route path={`${match.url}/settings`} component={SettingsPage} />
      <Redirect to={`${match.url}/orders`} />
    </Switch>
  );
}
