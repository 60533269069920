import React, { useState } from 'react'
import { ActionList, Card, DataTable, FormLayout, Heading, Layout, Modal, Popover, TextContainer, TextField, TextStyle, Select } from '@shopify/polaris'
import { MoreHoriz as MoreIcon } from '@material-ui/icons';
import { useAdmin } from 'context/admin';

export default function DeliveryDiscountSettings() {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [discountPopover, setDiscountPopover] = useState(false);
  const [newDiscountPopover, setNewDiscountPopover] = useState(false);
  const admin = useAdmin();

  const handleAddPostalCodeDiscount = () => {
    const newDiscount = { name: '', condition_type: 'postal_code', condition_value: null, discount_type: 'fixed', discount_value: null, start_date: null, end_date: null };
    setEditing(newDiscount);
  }

  const handleCreateCartValueDiscount = () => {
    const newDiscount = { name: '', condition_type: 'cart_value', condition_value: null, discount_type: '%', discount_value: null, start_date: null, end_date: null };
    setEditing(newDiscount);
  }

  const handleCreateCartValueAndDistanceDiscount = () => {
    const newDiscount = { name: '', condition_type: 'cart_value_distance', condition_value: { distance: 0, value: 0 }, discount_type: '%', discount_value: null, start_date: null, end_date: null };
    setEditing(newDiscount);
  }

  const handleEdit = (discount) => {
    setEditing(discount);
    setDiscountPopover(false);
  }

  const handleChange = (key, val) => {
    setEditing({
      ...editing,
      [key]: val
    });
  }

  const handleSave = (discount) => {
    setSaving(true);
    setEditing(false);
    setDiscountPopover(false);
    admin.saveDeliveryDiscount(discount).finally(() => setSaving(false));
  }

  const handleRemove = (discount) => {
    setSaving(true);
    admin.deleteDeliveryDiscount(discount.name).finally(() => setSaving(false))
  }

  const deliveryDiscounts = admin.selectedShop.shop_config ? admin.selectedShop.shop_config.delivery_discounts : [];

  // Disabled if no value, start_date is greater than end_date, only start_date or end_date is given, or if no settings
  const saveDisabled = !editing || !editing.name
    || (editing.start_date && editing.end_date && editing.start_date > editing.end_date)
    || !editing.condition_value
    || (editing.condition_value === 'cart_value_distance' && (!editing.condition_value?.value || !editing.condition_value?.distance))
    || !editing.discount_value;

  const errors = editing ? {
    dates: editing.start_date && editing.end_date && editing.start_date > editing.end_date ? 'Start date must be before end date' : null
  } : {};

  const headers = [
    { type: 'text', label: 'Name', col: 'name', fn: (x) => x },
    { type: 'text', label: 'Condition', fn: (x) => {
      if (x.condition_type === 'cart_value') {
        return `Order is >= $${(x.condition_value / 100.0).toFixed(2)}`;
      }
      if (x.condition_type === 'postal_code') {
        return x.condition_value;
      }
      if (x.condition_type === 'cart_value_distance') {
        return `Order is >= $${((x.condition_value?.value || 1) / 100.0).toFixed(2)} && within ${(x.condition_value?.distance)}km`;
      }
    }},
    { type: 'text', label: 'Discount Value', fn: (x) => x.discount_type === 'fixed' ? `$${x.discount_value} off` : `${x.discount_value}% off` },
    { type: 'text', label: 'Start Date', col: 'start_date', fn: (x) => x},
    { type: 'text', label: 'End Date', col: 'end_date', fn: (x) => x},
    { type: 'numeric', col: null, label: '', fn: (discount) => {
      return <Popover
        active={discountPopover === discount.name}
        activator={<MoreIcon style={{cursor: 'pointer'}} onClick={() => setDiscountPopover(discount.name)} />}
        onClose={() => setDiscountPopover(false)}
      >
        <ActionList items={[
          {content: 'Edit', onAction: () => handleEdit(discount)},
          {content: 'Delete', onAction: () => handleRemove(discount), destructive: true }
        ]} />
      </Popover>
    }}
  ];

  const discountTypeField = editing ?
    <Select
      label="Discount Type"
      options={[
        { label: 'Fixed', value: 'fixed' },
        { label: 'Percentage', value: '%' }
      ]}
      onChange={(val) => {
        if (val === 'fixed') {
          handleChange('discount_type', val)
        } else {
          handleChange('discount_type', val)
        }
      }}
      value={editing.discount_type}
    />
    : null;

  const discountValueFields = editing && editing.discount_type === 'fixed' ?
    <TextField
      label="Discount Value ($)"
      helpText="This is a fixed amount discount. Eg. $5 off."
      type="number"
      min={0}
      prefix="$"
      value={`${editing.discount_value}`}
      onChange={(val) => handleChange('discount_value', +val)}
    />
  :
    <TextField
      label="Discount Value (%)"
      helpText="Percentage discount. eg: 50% off."
      type="number"
      prefix="%"
      min={0}
      max={100}
      value={`${editing.discount_value}`}
      onChange={(val) => handleChange('discount_value', +val)}
    />


  return (
    <Layout.AnnotatedSection
      title="Delivery Discounts"
      description="Give customers discounts on local delivery rates.">
      <Card>
        <Card.Header
          actions={[
            { content: <Popover
              active={newDiscountPopover}
              activator={<span onClick={() => setNewDiscountPopover(true)}>Add</span>}
              onClose={() => setNewDiscountPopover(null)}
            >
              <ActionList items={[
                {content: 'By Postal Code', onAction: () => handleAddPostalCodeDiscount()},
                {content: 'By Cart Value', onAction: () => handleCreateCartValueDiscount() },
                {content: 'By Cart Value And Distance', onAction: () => handleCreateCartValueAndDistanceDiscount() }
              ]} />
            </Popover> },

          ]}
          title="Delivery Discounts"
        >
        </Card.Header>
        { deliveryDiscounts.length > 0 ?
          <DataTable
            columnContentTypes={headers.map(h => h.type)}
            headings={headers.map(h => h.label)}
            rows={deliveryDiscounts.map(row => headers.map(header => header.fn(header.col ? row[header.col] : row)))}
          />
          :
          <Card.Section>
            <TextContainer>
              Add discounts based on minimum cart value. For example, you can offer free delivery on orders of $100 or more.
            </TextContainer>
          </Card.Section>
        }
      </Card>

      <Modal
        title="Delivery Discount Rule"
        open={editing}
        onClose={() => setEditing(false)}
        secondaryActions={[{ content: 'Cancel', onAction: () => setEditing(false) }]}
        primaryAction={{content: 'Save', loading: saving, onAction: () => handleSave(editing), disabled: saving || saveDisabled }}>
        <Modal.Section>
          <FormLayout>
            <TextField
              label="Name"
              type="text"
              helpText="Changing the name will create a new discount."
              min={0}
              value={`${editing.name}`}
              onChange={(val) => handleChange('name', val)}
            />

            {editing && editing.condition_type === 'postal_code' ?
              <React.Fragment>
                <TextField
                  label="Postal Codes"
                  helpText="List of comma separated postal codes. eg: 'A1B2C3, B3C4F5'"
                  type="text"
                  min={0}
                  value={editing.condition_value || ''}
                  onChange={(val) => handleChange('condition_value', val.toUpperCase().trim())}
                  multiline={3}
                />

                {discountTypeField}
                {discountValueFields}
              </React.Fragment>
            : null}

            {editing && editing.condition_type === 'cart_value' ?
              <React.Fragment>
                <TextField
                  label="Minimum Order ($)"
                  type="number"
                  min={0}
                  value={`${editing.condition_value / 100.0}`}
                  onChange={(val) => handleChange('condition_value', +val * 100)}
                />

                {discountTypeField}
                {discountValueFields}
              </React.Fragment>
            : null}

            {editing && editing.condition_type === 'cart_value_distance' ?
              <React.Fragment>
                <TextField
                  label="Minimum Order ($)"
                  type="number"
                  min={0}
                  value={`${editing.condition_value.value / 100.0}`}
                  onChange={(val) => handleChange('condition_value', { ...editing.condition_value, value: +val * 100 })}
                />
                <TextField
                  label="Maximum Distance (km)"
                  type="number"
                  min={0}
                  value={`${editing.condition_value.distance}`}
                  onChange={(val) => handleChange('condition_value', { ...editing.condition_value, distance: +val })}
                />

                {discountTypeField}
                {discountValueFields}
              </React.Fragment>
            : null}

            <Heading>Available Only Within A Certain Date Range</Heading>

            <FormLayout.Group>
              <TextField
                label="Start Date"
                type="date"
                value={`${editing.start_date}`}
                onChange={(val) => handleChange('start_date', val || null)}
              />

              <TextField
                label="End Date"
                type="date"
                value={`${editing.end_date}`}
                onChange={(val) => handleChange('end_date', val || null)}
              />
            </FormLayout.Group>
          </FormLayout>

          {errors.dates ? <TextStyle variation="negative">{errors.dates}</TextStyle> : '' }
        </Modal.Section>
      </Modal>
    </Layout.AnnotatedSection>
  )
}
