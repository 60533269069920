import React, { useState } from 'react'

import { Layout, SettingToggle } from '@shopify/polaris'

import { useAdmin } from 'context/admin';

export default function AdminSettings() {
  const [saving, setSaving] = useState(false);

  const admin = useAdmin();

  const handleRegister = () => {
    setSaving(true);
    admin.configureShop().finally(() => setSaving(false))
  }

  return (
    <Layout.AnnotatedSection
      title="Admin Settings"
      description=""
    >
      <SettingToggle
        action={{
          content: 'Register',
          onAction: () => handleRegister(),
          loading: saving
        }}
      >
        Register carrier and webhooks
      </SettingToggle>
    </Layout.AnnotatedSection>
  )
}
