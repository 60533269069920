import React from 'react';

import PropTypes from 'prop-types';

import { useAdmin } from 'context/admin';

import { Button, Card, Layout, DataTable } from '@shopify/polaris';

export default function TestWindows({ shop }) {
  const admin = useAdmin();

  const fetchRates = (shopId) => {
    admin.fetchRates(shopId);
  }

  const headers = [
    { type: 'text', label: 'Name', col: 'service_name', fn: (x) => x },
    { type: 'text', label: 'Description', col: 'description', fn: (x) => x },
    { type: 'numeric', label: 'Price', col: 'total_price', fn: (x) => `$${(x / 100.0).toFixed(2)}` },
  ];

  const rows = admin.rates ? admin.rates.sort((a, b) => a.total_price - b.total_price) : [];

  const fetchRatesBtn = (
    <Button data-cy="fetchRatesBtn" onClick={() => fetchRates(shop.id)} loading={admin.loading}>
      Fetch Rates
    </Button>
  )

  return (
    <Layout.AnnotatedSection
      title="Test"
      description="Test your settings with a mock delivery to a location 3.5 km from your shop.">
      { admin.rates ?
        <Card primaryFooterAction={{ content: 'Fetch Rates', onAction: () => fetchRates(shop.id), loading: admin.loading }}>
          {admin.rates && admin.rates.length === 0 ? <p data-cy="noRatesMSG" >No rates available with the given settings</p> :
            <div data-cy='fetchRatesTbl'>
              <DataTable
                columnContentTypes={headers.map(h => h.type)}
                headings={headers.map(h => h.label)}
                rows={rows.map(order => headers.map(header => header.fn(header.col ? order[header.col] : order)))}
              />
            </div>
          }
        </Card>
      : fetchRatesBtn}
    </Layout.AnnotatedSection>
  );
}

TestWindows.propTypes = {
  shop: PropTypes.object
}