import React, { useEffect, useState } from 'react'

import { Layout, Card, FormLayout, TextField, Modal, Popover, ActionList, DataTable, Select, Stack, Tag, Button } from '@shopify/polaris'

import { useAdmin } from 'context/admin';
import { MoreHoriz as MoreIcon } from '@material-ui/icons';
import { postData, putData } from 'util/fetch';
import { fetcher } from 'util/fetch';
import { validEmail } from 'util/helpers';

export default function OrderSummaryEmailSettings() {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [editing, setEditing] = useState(false);
  const [popover, setPopover] = useState(false);
  const [orderSummaryEmails, setOrderSummaryEmails] = useState([]);
  const { selectedShop } = useAdmin();
  const [newRecipientAddress, setNewRecipientAddress] = useState('');
  const [newRecipientProductType, setNewRecipientProductType] = useState('');
  const { setToast } = useAdmin();

  const newTrigger = { hour: 17, type: 'date', value: 'next_day' }
  const newRecipient = { type: 'product_type', values: [], addresses: [] }

  useEffect(() => {
    if (selectedShop && !loaded && !loading) {
      setLoading(true);
      fetcher(`/api/shops/${selectedShop.id}/order_summary_emails`)
        .then(res => res.json())
        .then(res => {
          setOrderSummaryEmails(res);
        })
        .finally(() => {
          setLoading(false);
          setLoaded(true);
        })
    }
  }, [selectedShop, loaded, loading]);

  const handleAdd = () => {
    postData(`/api/shops/${selectedShop.id}/order_summary_emails`)
      .then(res => res.json())
      .then(res => {
        setOrderSummaryEmails([res]);
      })
  }

  const handleSave = (summary) => {
    setSaving(true);
    putData(`/api/shops/${selectedShop.id}/order_summary_emails/${summary.id}`, { order_summary_email: summary })
      .then(res => res.json())
      .then(res => {
        setOrderSummaryEmails(orderSummaryEmails.map(x => x.id === summary.id ? res : x));
        setEditing(false);
        setToast('Order summary email saved.')
      })
      .finally(() => setSaving(false))
  }

  const handleSaveRecipient = ({ recipient, summaryIndex, recipientIndex }) => {
    const summary = orderSummaryEmails[summaryIndex];
    handleSave({
      ...summary,
      recipients: recipientIndex === undefined
        ? [...summary.recipients, recipient]
        : summary.recipients.map((x, i) => i === recipientIndex ? recipient : x)
    })
  }

  const handleSaveTrigger = ({ trigger, summaryIndex, triggerIndex }) => {
    const summary = orderSummaryEmails[summaryIndex];
    handleSave({
      ...summary,
      triggers: triggerIndex === undefined
      ? [...summary.triggers, trigger]
      : summary.triggers.map((x, i) => i === triggerIndex ? trigger : x)
    })
  }

  const handleRemoveTrigger = (summaryIndex, index) => {
    const summary = orderSummaryEmails[summaryIndex];
    handleSave({
      ...summary,
      triggers: summary.triggers.filter((r, i) => i !== index)
    })
  }

  const handleRemoveRecipient = (summaryIndex, index) => {
    const summary = orderSummaryEmails[summaryIndex];
    handleSave({
      ...summary,
      recipients: summary.recipients.filter((r, i) => i !== index)
    })
  }

  const triggerHeaders = (summaryIndex) => [
    { type: 'text', label: 'Hour', fn: (x) => `${x.hour}:00` },
    { type: 'text', label: 'Type', fn: (x) => {
      if (x.type === 'date' && x.value === 'next_day') {
        return 'Next Days Orders'
      }
      if (x.type === 'hours_ago') {
        return `Orders created after ${x.hour - x.value}:00`;
      }
    }},
    { type: 'numeric', col: null, label: '', fn: (trigger, index) => {
      const key = `${trigger.hour}-${index}`;
      return <Popover
        active={popover === key}
        activator={<MoreIcon style={{cursor: 'pointer'}} onClick={() => setPopover(key)} />}
        onClose={() => setPopover(false)}
      >
        <ActionList items={[
          {content: 'Edit', onAction: () => setEditing({ trigger, summaryIndex, triggerIndex: index }) },
          {content: 'Delete', onAction: () => handleRemoveTrigger(summaryIndex, index), destructive: true }
        ]} />
      </Popover>
    }}
  ];

  const recipientHeaders = (summaryIndex) => [
    { type: 'text', label: 'Product Types', fn: (x) => <div>{x.values.map(type => <div key={type}>{type}</div>)}</div> },
    { type: 'text', label: 'Emails', fn: (x) => <div>{x.addresses.map(address => <div key={address}>{address}</div>)}</div>},
    { type: 'numeric', col: null, label: '', fn: (recipient, index) => {
      const key = `${recipient.values.join('-')}__${recipient.addresses.join('-')}-${index}`;
      return (
        <Popover
          active={popover === key}
          activator={<MoreIcon style={{cursor: 'pointer'}} onClick={() => setPopover(key)} />}
          onClose={() => setPopover(false)}
        >
          <ActionList items={[
            {content: 'Edit', onAction: () => setEditing({ recipient, summaryIndex, recipientIndex: index }) },
            {content: 'Delete', onAction: () => handleRemoveRecipient(summaryIndex, index), destructive: true }
          ]} />
        </Popover>
      )
    }}
  ];

  return (
    <Layout.AnnotatedSection
      title="Order Summary Emails"
      description="Get daily emails with order summary based on your department."
    >
        {!loading && orderSummaryEmails.length === 0 ?
          <Card>
            <Card.Header
              title="Summary Emails"
              actions={[{ content: 'Add', onAction: handleAdd }]}
            >
            </Card.Header>
            <Card.Section>
              No order summary emails
            </Card.Section>
          </Card>
        : null}

        {!loading && orderSummaryEmails.length > 0 ?
          orderSummaryEmails.map((summary, index) =>
            <Card key={index}>
              <Card.Section title="Triggers" actions={[{ content: 'Add', onAction: () => setEditing({ trigger: newTrigger, summaryIndex: index, triggerIndex: undefined }) }]}>
                {summary.triggers.length > 0 ?
                  <DataTable
                    columnContentTypes={triggerHeaders(index).map(h => h.type)}
                    headings={triggerHeaders(index).map(h => h.label)}
                    rows={summary.triggers.map((row, idx) => triggerHeaders(index).map(header => header.fn(row, idx)))}
                  />
                : 'Add a trigger'}
              </Card.Section>

              <Card.Section title="Recipients" actions={[{ content: 'Add', onAction: () => setEditing({ recipient: newRecipient, summaryIndex: index, recipientIndex: undefined }) }]}>
                {summary.recipients.length > 0 ?
                  <DataTable
                    columnContentTypes={recipientHeaders(index).map(h => h.type)}
                    headings={recipientHeaders(index).map(h => h.label)}
                    rows={summary.recipients.map((row, idx) => recipientHeaders(index).map(header => header.fn(row, idx)))}
                  />
                : 'Add recipients'}
              </Card.Section>
            </Card>
          )
        : null}

      <Modal
        title="Trigger Modal"
        open={editing.trigger}
        onClose={() => setEditing(false)}
        secondaryActions={[{ content: 'Cancel', onAction: () => setEditing(false) }]}
        primaryAction={{content: 'Save', loading: saving, onAction: () => handleSaveTrigger(editing), disabled: saving }}>
        {editing.trigger ?
          <Modal.Section>
            {editing.trigger.type === 'hours_ago' ? <p>At {editing.trigger.hour}:00, get a summary of orders created since {editing.trigger.hour - editing.trigger.value}:00.</p> : null}
            {editing.trigger.type === 'date' ? <p>At {editing.trigger.hour}:00, get a summary of orders due to be delivered or picked up {editing.trigger.value === 'next_day' ? 'tomorrow' : editing.trigger.value}.</p> : null}

            {editing.trigger ?
              <FormLayout>
                <TextField
                  label="Hour"
                  type="number"
                  suffix=":00"
                  min={0}
                  max={23}
                  value={`${editing.trigger.hour}`}
                  onChange={(val) => setEditing({ ...editing, trigger: { ...editing.trigger, hour: +val } })}
                />

                <Select
                  label="Type"
                  options={[
                    {label: 'Next Day', value: 'date'},
                    {label: 'Hours Ago', value: 'hours_ago'}
                  ]}
                  onChange={(val) => setEditing({ ...editing, trigger: { ...editing.trigger, type: val, value: val === 'date' ? 'next_day' : 5 } })}
                  value={editing.trigger.type}
                />

                {editing.trigger.type === 'hours_ago' ?
                  <TextField
                    label="Hours Ago"
                    type="number"
                    helpText={`Orders created within the last ${editing.trigger.value} hours since ${editing.trigger.hour}`}
                    min={1}
                    max={24}
                    value={`${editing.trigger.value}`}
                    onChange={(val) => setEditing({ ...editing, trigger: { ...editing.trigger, value: +val } })}
                  />
                : null}
              </FormLayout>
            : null}
          </Modal.Section>
        : null}
      </Modal>

      <Modal
        title="Recipient Modal"
        open={editing.recipient}
        onClose={() => setEditing(false)}
        secondaryActions={[{ content: 'Cancel', onAction: () => setEditing(false) }]}
        primaryAction={{content: 'Save', loading: saving, onAction: () => handleSaveRecipient(editing), disabled: saving }}>
        <Modal.Section>
          {editing.recipient ?
            <FormLayout>
              <TextField
                label="Email"
                type="email"
                value={newRecipientAddress}
                onChange={(val) => setNewRecipientAddress(val)}
                connectedRight={
                  <Button
                    disabled={!validEmail(newRecipientAddress)}
                    onClick={() => {
                    setEditing({ ...editing, recipient: { ...editing.recipient, addresses: [...editing.recipient.addresses, newRecipientAddress] }});
                    setNewRecipientAddress('');
                  }}>
                    Add
                  </Button>}
              />

              <Stack spacing="tight">
                {editing.recipient.addresses.map(address =>
                  <Tag key={address} onRemove={() => {
                    setEditing({ ...editing, recipient: { ...editing.recipient, addresses: editing.recipient.addresses.filter(tag => tag !== address) }})
                  }}>
                    {address}
                  </Tag>
                )}
              </Stack>


              <TextField
                label="Product Type"
                type="string"
                value={newRecipientProductType}
                onChange={(val) => {
                  setNewRecipientProductType(val)
                }}
                connectedRight={
                  <Button
                    onClick={() => {
                      setEditing({ ...editing, recipient: { ...editing.recipient, values: [...editing.recipient.values, newRecipientProductType] }});
                      setNewRecipientProductType('');
                    }}
                  >
                    Add
                  </Button>}
              />

              <Stack spacing="tight">
                {editing.recipient.values.map(product_type =>
                  <Tag key={product_type} onRemove={() => {
                    setEditing({ ...editing, recipient: { ...editing.recipient, values: editing.recipient.values.filter(value => value !== product_type)} })
                  }}>
                    {product_type}
                  </Tag>
                )}
              </Stack>
            </FormLayout>
          : null}
        </Modal.Section>
      </Modal>
    </Layout.AnnotatedSection>
  )
}
