import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const hist = createBrowserHistory();

export default function AuthenticatedApp() {
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Redirect to="/auth" />
      </Switch>
    </Router>
  )
}
