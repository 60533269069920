import React, { useEffect, useState } from "react";

import useLocalStorage from "hooks/useLocalStorage";
import { fetcher, postData } from "util/fetch";

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [user, setUser] = useLocalStorage("user", null);
  const [error, setError] = useState();
  const [users, setUsers] = useState();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      window.Beacon("identify", {
        name: user.name,
        email: user.email,
      });
    }
  }, [user]);

  const login = async (email, password) => {
    setLoading(true);
    try {
      const res = await postData("/api/sign_in", {
        session: { email, password },
      });
      if (res.status !== 200) {
        const error = await res.json();
        setError(error);
      } else {
        const response = await res.json();
        localStorage.setItem("auth_token", response.auth_token);
        setUser(response.user);
        setError(null);
        window.location.pathname = "/shops";
      }
    } catch (error) {
      console.log({ error });
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const register = ({ name, email, password, shop_ids }) => {
    setSaving(true);
    return postData("/api/users", { user: { name, email, password }, shop_ids })
      .then((res) => res.json())
      .then((user) => {
        setUsers([...users, user]);
        setError(null);
      })
      .catch((error) => {
        console.log({ error });
        setError(error);
      })
      .finally(() => setSaving(false));
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("auth_token");
    setUser(null);
  };

  const getUsers = async () => {
    const res = await fetcher("/api/users");
    const users = await res.json();
    setUsers(users);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        users,
        getUsers,
        login,
        logout,
        register,
        error,
        loading,
        saving,
      }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
