const dev = {
  mapsApiKey: "AIzaSyBOevggIYEqTjq-b07RlfRJxfEtG4b7-uI",
  apiBaseUri: "https://grocerbox.ngrok.io",
};

const prod = {
  mapsApiKey: "AIzaSyD41pbUg8GYDl_lmkSaM_EW1eyzHKB2kfQ",
  apiBaseUri: "https://api.getgrocerbox.com",
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default {
  // Add common config values here
  ...config,
};
