import React, { useEffect, useState } from "react";

import { Card, Layout, Page, SkeletonBodyText, Tabs } from "@shopify/polaris";

import { useAdmin } from "context/admin";
import LoadingPage from "shared/LoadingPage";
import { getWeekday } from "util/helpers";
import DeliveryList from "./DeliveryList";

export default function DeliveriesPage() {
  const admin = useAdmin();
  const [selectedTab, setSelectedTab] = useState(0);
  const [grouped, setGrouped] = useState({
    today: [],
    tomorrow: [],
    upcoming: [],
  });
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const tomorrow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1
  );
  const dayAfterTomorrow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 2
  );
  const weekdayAfterTomorrow = getWeekday(dayAfterTomorrow.getDay());

  const handleTabChange = (selectedTabIndex) =>
    setSelectedTab(selectedTabIndex);

  const dateIsBetween = (date, first, second) => {
    if (first && second) {
      return first <= date && date <= second;
    }
    if (first) {
      return first <= date;
    }
    if (second) {
      return date <= second;
    }
    return false;
  };

  useEffect(() => {
    if (
      !admin.deliveries ||
      !admin.deliveries.shops[admin.selectedShopId] ||
      admin.deliveries.shops[admin.selectedShopId].length === 0
    ) {
      return;
    }
    const deliveries = admin.deliveries.shops[admin.selectedShopId].map(
      (id) => admin.deliveries.entities[id]
    );

    // assigns the order assigned location to the delivery origin
    deliveries.forEach((delivery) => {
      const origin =
        admin.orders?.entities[delivery.shopify_order_id]?.fulfillment_order
          .assigned_location;
      if (origin) {
        delivery.origin = origin;
      } else {
        console.log("delivery has no origin");
      }
    });

    const groupedDeliveries = deliveries.reduce(
      (grouped, delivery) => {
        const now = new Date();
        const today = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );
        const tomorrow = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1
        );
        const dayAfterTomorrow = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 2
        );
        const dayAfterThat = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 3
        );

        if (delivery.data) {
          if (
            dateIsBetween(new Date(delivery.data.ready_by), today, tomorrow)
          ) {
            return { ...grouped, today: [...grouped.today, delivery] };
          }

          if (
            dateIsBetween(
              new Date(delivery.data.ready_by),
              tomorrow,
              dayAfterTomorrow
            )
          ) {
            return { ...grouped, tomorrow: [...grouped.tomorrow, delivery] };
          }

          if (
            dateIsBetween(
              new Date(delivery.data.ready_by),
              dayAfterTomorrow,
              dayAfterThat
            )
          ) {
            return {
              ...grouped,
              dayAfterTomorrow: [...grouped.dayAfterTomorrow, delivery],
            };
          }

          return { ...grouped, upcoming: [...grouped.upcoming, delivery] };
        }

        return grouped;
      },
      { today: [], tomorrow: [], dayAfterTomorrow: [], upcoming: [] }
    );

    setGrouped(groupedDeliveries);
  }, [admin.selectedShopId, admin.deliveries, admin.orders]);

  if (!admin.selectedShop || !admin.deliveries) {
    return <LoadingPage />;
  }

  const tabs = [
    {
      id: "today",
      content: "today",
      title: `Deliveries for Today, ${today.toLocaleDateString()}`,
      panelID: "todays-deliveries",
    },
    {
      id: "tomorrow",
      content: "tomorrow",
      title: `Deliveries for Tomorrow, ${tomorrow.toLocaleDateString()}`,
      panelID: "tomorrows-deliveries",
    },
    {
      id: "dayAfterTomorrow",
      content: weekdayAfterTomorrow,
      title: `Deliveries for ${weekdayAfterTomorrow}, ${dayAfterTomorrow.toLocaleDateString()}`,
      panelID: `${weekdayAfterTomorrow.toLowerCase()}-deliveries`,
    },
    {
      id: "upcoming",
      content: "upcoming",
      title: "Upcoming Deliveries",
      panelID: "upcoming-deliveries",
    },
  ];

  const selectedDeliveries = grouped[tabs[selectedTab].id] || [];

  return (
    <Page data-cy="DeliveriesPage" title="Deliveries">
      <Layout>
        <Layout.Section>
          {/*<Banner title="There have been some updates!" status="info">
            <ul>
              <li>You can now filter deliveries by the "Ready By" time.</li>
              <li>You can print all store deliveries or filter them first to only print a subset.</li>
              <li>If you have store deliveries you can view them in Google Maps using the "Google Maps" button below.</li>
            </ul>
          </Banner>
          */}
          <Tabs
            tabs={tabs}
            selected={selectedTab}
            onSelect={handleTabChange}
          ></Tabs>
          {admin.loading ? (
            <Card sectioned>
              <SkeletonBodyText />
            </Card>
          ) : (
            <DeliveryList
              title={tabs[selectedTab].title}
              deliveries={selectedDeliveries}
              orderEntities={admin.orders.entities}
            />
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
}
