import React, { useState } from 'react'
import { Banner, FormLayout, Modal, TextField } from '@shopify/polaris';
import { getCourier, getTrackingUrl } from 'util/trackingLinks';


export default function TrackingLinkModal({ order, handleClose, handleSend, loading }) {
  const [trackingNumber, setTrackingNumber] = useState('');
  const courier = getCourier(trackingNumber) || '';

  return (
    <Modal
      title="Send Tracking Link"
      open={!!order}
      onClose={() => handleClose()}
      primaryAction={{content: 'Email to Customer', onAction: () => handleSend(getTrackingUrl(trackingNumber)), disabled: courier.length === 0, loading: loading }}
      secondaryActions={{ content: 'Cancel', onAction: () => handleClose() }}>
      <Modal.Section>
        <p>Enter tracking number below to generate and test the link before sending it to the customer.</p>
        <br />
        <FormLayout>
          <TextField
            type="text"
            label="Tracking Number"
            value={trackingNumber}
            onChange={val => setTrackingNumber(val)}
            placeholder="Enter tracking number here"
          />
        </FormLayout>
        <br />

        { courier.length > 0 ?
          <Banner status="success" action={{content: 'Check Tracking Link', onAction: () => {window.open(getTrackingUrl(trackingNumber), '_blank')}}}>
            Tracking number matches pattern for {courier}.<br />
            Check the generated tracking link below to make sure it will work for your customer. <br />
          </Banner>
        : '' }
        { trackingNumber.length > 9 && courier.length === 0 ?
          <Banner status="warning">No matching courier found for tracking link: {courier}</Banner>
        : '' }
      </Modal.Section>
    </Modal>
  )
}
