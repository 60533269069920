import React, { useEffect, useState } from "react";

import { MoreHoriz as MoreIcon } from "@material-ui/icons";
import {
  ActionList,
  Button,
  Card,
  DataTable,
  FormLayout,
  Layout,
  Modal,
  Popover,
  Select,
  SettingToggle,
  Stack,
  Tag,
  TextField,
  TextStyle,
} from "@shopify/polaris";

import { useAdmin } from "context/admin";
import { useUser } from "context/user";
import { deleteRequest, fetcher, postData, putData } from "util/fetch";
import { validEmail } from "util/helpers";

const CARRIER_URI_OPTIONS = {
  "1courier": [
    { label: "production", value: "https://api.1courier.com" },
    { label: "staging", value: "https://apiqa.1courier.com" },
  ],
  "1courierV2": [
    { label: "production", value: "https://ecommerce.v3.1courier.com/api" },
    { label: "staging", value: "https://ecommerceqa.v2.1courier.com/api" },
  ],
  Trexity: [
    { label: "production", value: "https://trexity.app/api" },
    {
      label: "staging",
      value: "https://trexity-driver-mobile.uc.r.appspot.com/api",
    },
  ],
  METROSPEEDY: [{ label: "production", value: "https://onfleet.com/api/v2" }],
  // 'Onfleet': [
  //   { label: 'production', value: 'https://onfleet.com/api/v2' }
  // ],
  Shiptrack: [
    { label: "production", value: "https://dispatch.shiptrackapi.com/api" },
    { label: "staging", value: "https://stage-dispatch.shiptrackapp.com/api" },
  ],
  DeliverNorth: [
    {
      label: "production",
      value: "https://integrations-api.delivernorth.com/standard/v1",
    },
    {
      label: "staging",
      value: "https://integrations-api.delivernorth.com/standard/v1",
    },
  ],
  Postmates: [{ label: "production", value: "https://api.postmates.com" }],
  Boxknight: [
    { label: "production", value: "https://api.boxknight.com/v1" },
    { label: "staging", value: "https://api-stage.boxknight.com/v1" },
  ],
  Nash: [
    { label: "production", value: "https://api.usenash.com/v1" },
    {
      label: "sandbox",
      value: "https://api.sandbox.usenash.com/v1",
    },
  ],
  Sameday: [
    { label: "production", value: "https://www.samedayxcelerator.com/Axis/" },
  ],
};

const CARRIERS = {
  "1courier": {
    name: "1courier",
    provider: "1courier",
    auth_type: "token",
    carrier_uri: CARRIER_URI_OPTIONS["1courier"][0].value,
  },
  "1courierV2": {
    carrier_uri: CARRIER_URI_OPTIONS["1courierV2"][0].value,
    name: "1courierV2",
    provider: "1courierV2",
    auth_type: "token",
  },
  Trexity: {
    carrier_uri: CARRIER_URI_OPTIONS["Trexity"][0].value,
    name: "Trexity",
    provider: "Trexity",
    auth_type: "api_key",
  },
  METROSPEEDY: {
    carrier_uri: CARRIER_URI_OPTIONS["METROSPEEDY"][0].value,
    name: "Metrospeedy",
    provider: "METROSPEEDY",
    auth_type: "api_key",
  },
  // 'Onfleet': {
  //   carrier_uri: CARRIER_URI_OPTIONS['METROSPEEDY'][0].value,
  //   name: 'Onfleet',
  //   provider: 'Onfleet',
  //   auth_type: 'api_key'
  // },
  Shiptrack: {
    carrier_uri: CARRIER_URI_OPTIONS["Shiptrack"][0].value,
    name: "Shiptrack",
    provider: "Shiptrack",
    auth_type: "basic",
  },
  DeliverNorth: {
    carrier_uri: CARRIER_URI_OPTIONS["DeliverNorth"][0].value,
    name: "DeliverNorth",
    provider: "DeliverNorth",
    auth_type: "api_key",
  },
  Postmates: {
    carrier_uri: CARRIER_URI_OPTIONS["Postmates"][0].value,
    name: "Postmates",
    provider: "Postmates",
    auth_type: "api_key",
  },
  Boxknight: {
    carrier_uri: CARRIER_URI_OPTIONS["Boxknight"][0].value,
    name: "Boxknight",
    provider: "Boxknight",
    auth_type: "basic",
  },
  Nash: {
    carrier_uri: CARRIER_URI_OPTIONS["Nash"][0].value,
    name: "Nash",
    provider: "Nash",
    auth_type: "api_key",
  },
  Sameday: {
    carrier_uri: CARRIER_URI_OPTIONS["Sameday"][0].value,
    name: "Sameday",
    provider: "Sameday",
    auth_type: "basic",
  },
};

// TODO: Set auth type based on carrier.

export default function CarrierSettings() {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [carrierPopover, setCarrierPopover] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedShopId, setToast } = useAdmin();
  const user = useUser();
  const [showPassword, setShowPassword] = useState(false);
  const [newRecipientAddress, setNewRecipientAddress] = useState("");
  // const user = useUser();

  const newCarrier = {
    provider: "",
    carrier_uri: "",
    auth_type: "",
  };

  useEffect(() => {
    // fetcher(`/api/shops/${selectedShopId}/vendors`)
    //   .then(res => res.json())
    //   .then((res) => console.log({ vendors: res }))
    if (selectedShopId) {
      setLoading(true);
      fetcher(`/api/shops/${selectedShopId}/carriers`)
        .then((res) => res.json())
        .then((res) => {
          setCarriers(res);
          // console.log({ res })
        })
        .catch((err) => console.log({ err }))
        .finally(() => setLoading(false));
    }
  }, [selectedShopId]);

  const handleEdit = (carrier) => {
    setEditing(carrier);
    setCarrierPopover(false);
  };

  const handleChange = (key, val) => {
    setEditing({
      ...editing,
      [key]: val,
    });
  };

  const handleSave = () => {
    if (editing.id) {
      handleUpdate(editing);
    } else {
      handleCreate(editing);
    }
  };

  const handleCreate = (carrier) => {
    setSaving(true);
    postData(`/api/shops/${selectedShopId}/carriers`, { carrier })
      .then((res) => res.json())
      .then((res) => {
        setCarriers((state) => [carrier, ...state]);
        setEditing(false);
      })
      .catch((err) => {
        setToast("There was an error creating carrier.");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleUpdate = (carrier) => {
    setSaving(true);
    // Destructure to remove these keys from payload before sending to server
    const {
      id,
      shopify_domain,
      customer_id,
      created_at,
      updated_at,
      ...payload
    } = carrier;
    putData(`/api/shops/${selectedShopId}/carriers/${carrier.id}`, {
      carrier: payload,
    })
      .then((res) => res.json())
      .then((res) => {
        setCarriers((state) =>
          state.map((c) => (c.id === carrier.id ? res : c))
        );
        setEditing(false);
      })
      .catch((err) => {
        setToast("There was an error updating carrier.");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleRemove = (carrier) => {
    setSaving(true);
    deleteRequest(`/api/shops/${selectedShopId}/carriers/${carrier.id}`)
      .then(() => {
        setCarriers((state) => state.filter((c) => c.id !== carrier.id));
      })
      .catch((err) => console.log({ err }))
      .finally(() => {
        setSaving(false);
        setCarrierPopover(false);
      });
  };

  const handleSelectCarrier = (provider) => {
    console.log({ provider });
    setEditing((state) => ({
      ...CARRIERS[provider],
    }));
  };

  const headers = [
    { type: "text", label: "Name", col: "name", fn: (x) => x },
    {
      type: "text",
      label: "Mode",
      fn: (x) => (x.test_mode ? "TEST" : "ACTIVE"),
    },
    {
      type: "numeric",
      col: null,
      label: "",
      fn: (carrier) => {
        return (
          <Popover
            active={carrierPopover === carrier.id}
            activator={
              <MoreIcon
                style={{ cursor: "pointer" }}
                onClick={() => setCarrierPopover(carrier.id)}
              />
            }
            onClose={() => setCarrierPopover(false)}
          >
            <ActionList
              items={[
                { content: "Edit", onAction: () => handleEdit(carrier) },
                {
                  content: "Delete",
                  onAction: () => handleRemove(carrier),
                  destructive: true,
                },
              ]}
            />
          </Popover>
        );
      },
    },
  ];

  const disabled =
    !editing.provider || !editing.auth_type || !editing.carrier_uri;

  const carrierProviders = [
    { label: "Choose a provider", value: "" },
    ...Object.keys(CARRIERS).map((key) => ({
      label: CARRIERS[key].name,
      value: CARRIERS[key].provider,
    })),
  ];
  const authTypes = [
    { label: "API Key", value: "api_key" },
    { label: "Token", value: "token" },
    { label: "Basic", value: "basic" },
  ];

  const defaultCarriers = carriers?.filter((c) => c.default) || [];
  const otherCarriers = carriers?.filter((c) => !c.default) || [];

  return (
    <Layout.AnnotatedSection
      title="Carriers"
      description="Configure shipping carriers."
    >
      <Card>
        <Card.Header
          actions={[{ content: "Add", onAction: () => setEditing(newCarrier) }]}
          title="Carriers"
        ></Card.Header>

        {carriers?.length === 0 ? (
          <Card.Section>No carriers.</Card.Section>
        ) : null}

        {defaultCarriers.length > 0 ? (
          <Card.Section title="Active Carrier">
            <DataTable
              columnContentTypes={headers.map((h) => h.type)}
              headings={headers.map((h) => h.label)}
              rows={carriers
                .filter((c) => c.default)
                .map((row) =>
                  headers.map((header) =>
                    header.fn(header.col ? row[header.col] : row)
                  )
                )}
            />
          </Card.Section>
        ) : null}

        {otherCarriers.length > 0 ? (
          <Card.Section title="Other Carriers">
            {otherCarriers?.length > 0 ? (
              <DataTable
                columnContentTypes={headers.map((h) => h.type)}
                headings={headers.map((h) => h.label)}
                rows={carriers
                  .filter((c) => !c.default)
                  .map((row) =>
                    headers.map((header) =>
                      header.fn(header.col ? row[header.col] : row)
                    )
                  )}
              />
            ) : null}
          </Card.Section>
        ) : null}
      </Card>

      <Modal
        title="Carrier"
        open={editing}
        onClose={() => setEditing(false)}
        secondaryActions={[
          { content: "Cancel", onAction: () => setEditing(false) },
        ]}
        primaryAction={{
          content: "Save",
          loading: saving,
          onAction: () => handleSave(editing),
          disabled: disabled || loading || saving,
        }}
      >
        <Modal.Section>
          <FormLayout>
            <Select
              label="Select Carrier"
              options={carrierProviders}
              onChange={handleSelectCarrier}
              value={editing.provider}
            />
          </FormLayout>
          {editing.provider ? (
            <FormLayout>
              <SettingToggle
                action={{
                  content: editing.default ? "Default" : "Not Default",
                  destructive: !editing.default,
                  onAction: () =>
                    setEditing((state) => ({
                      ...editing,
                      default: !editing.default,
                    })),
                }}
              >
                This carrier{" "}
                <TextStyle variation="strong">
                  {editing.default ? "is the default" : "is not the default"}
                </TextStyle>
              </SettingToggle>

              <SettingToggle
                action={{
                  content: editing.test_mode ? "Test Mode On" : "Test Mode Off",
                  destructive: editing.test_mode,
                  onAction: () =>
                    setEditing((state) => ({
                      ...editing,
                      test_mode: !editing.test_mode,
                    })),
                }}
              >
                This carrier{" "}
                <TextStyle variation="strong">
                  {editing.test_mode
                    ? "is in test mode"
                    : "is not in test mode"}
                </TextStyle>
              </SettingToggle>

              <Select
                label="Carrier URI"
                options={[
                  ...(CARRIER_URI_OPTIONS[editing.provider] || []),
                  { label: "Select or Enter Below", value: "" },
                ]}
                onChange={(val) => {
                  console.log({ val });
                  handleChange("carrier_uri", val);
                }}
                value={editing.carrier_uri}
              />

              <TextField
                label="URI"
                type="text"
                value={editing.carrier_uri}
                onChange={(val) => handleChange("carrier_uri", val)}
              />

              {/* {editing.provider === CARRIERS['Onfleet'].provider ?
                <TextField
                  label="Organization ID"
                  type="text"
                  value={editing.onfleet_organization_id}
                  onChange={(val) => handleChange('onfleet_organization_id', val)}
                />
              : null} */}

              {/* 1Courier V2 */}
              {editing.provider === CARRIERS["1courierV2"].provider ? (
                <TextField
                  label="1Courier Customer ID"
                  type="text"
                  value={editing.one_courier_v2_customer_id}
                  onChange={(val) =>
                    handleChange("one_courier_v2_customer_id", val)
                  }
                />
              ) : null}

              {/* Postmates */}
              {editing.provider === CARRIERS["Postmates"].provider ? (
                <TextField
                  label="Postmates Customer ID"
                  type="text"
                  value={editing.postmates_customer_id}
                  onChange={(val) => handleChange("postmates_customer_id", val)}
                />
              ) : null}

              {/* Shiptrack */}
              {editing.provider === CARRIERS["Shiptrack"].provider ? (
                <React.Fragment>
                  <TextField
                    label="Email"
                    type="email"
                    helpText="These emails will be notified when an order is cancelled"
                    value={newRecipientAddress}
                    onChange={(val) => setNewRecipientAddress(val)}
                    connectedRight={
                      <Button
                        disabled={!validEmail(newRecipientAddress)}
                        onClick={() => {
                          setEditing({
                            ...editing,
                            cancellation_recipients: [
                              ...(editing.cancellation_recipients || []),
                              newRecipientAddress,
                            ],
                          });
                          setNewRecipientAddress("");
                        }}
                      >
                        Add
                      </Button>
                    }
                  />

                  {editing.cancellation_recipients ? (
                    <Stack spacing="tight">
                      {editing.cancellation_recipients.map((emailAddress) => (
                        <Tag
                          key={emailAddress}
                          onRemove={() => {
                            setEditing({
                              ...editing,
                              cancellation_recipients: editing.cancellation_recipients.filter(
                                (email) => email !== emailAddress
                              ),
                            });
                          }}
                        >
                          {emailAddress}
                        </Tag>
                      ))}
                    </Stack>
                  ) : null}
                </React.Fragment>
              ) : null}

              {/* Deliver North */}
              {editing.provider === CARRIERS["DeliverNorth"].provider ? (
                <React.Fragment>
                  <TextField
                    label="Delivery North Company ID"
                    type="text"
                    value={editing.deliver_north_company_id}
                    onChange={(val) =>
                      handleChange("deliver_north_company_id", val)
                    }
                  />

                  <TextField
                    label="Delivery North Client ID"
                    type="text"
                    value={editing.deliver_north_client_id}
                    onChange={(val) =>
                      handleChange("deliver_north_client_id", val)
                    }
                  />
                </React.Fragment>
              ) : null}

              <Select
                label="Select Auth Type"
                options={authTypes}
                onChange={(val) => handleChange("auth_type", val)}
                value={editing.auth_type || ""}
              />

              {editing.auth_type === "basic" ? (
                <React.Fragment>
                  <TextField
                    label="Username"
                    type="username"
                    value={editing.username}
                    onChange={(val) => handleChange("username", val)}
                  />

                  <TextField
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={editing.password}
                    onChange={(val) => handleChange("password", val)}
                    connectedRight={
                      <Button onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    }
                  />
                </React.Fragment>
              ) : null}

              {editing.auth_type === "api_key" ? (
                <TextField
                  label="API Key"
                  type="text"
                  value={editing.api_key}
                  onChange={(val) => handleChange("api_key", val)}
                />
              ) : null}

              {editing.auth_type === "token" ? (
                <TextField
                  label="Token"
                  type="text"
                  value={editing.token}
                  onChange={(val) => handleChange("token", val)}
                />
              ) : null}

              <TextField
                label="Flat Rate"
                type="number"
                min={0}
                value={`${Number(editing.flat_rate_cents / 100.0)}`}
                onChange={(val) => handleChange("flat_rate_cents", +val * 100)}
              />

              <TextField
                label="Minimum Price"
                type="number"
                min={0}
                value={`${Number(editing.minimum_price_cents / 100.0)}`}
                onChange={(val) => {
                  console.log({ val });
                  handleChange("minimum_price_cents", +val * 100);
                }}
              />

              <TextField
                label="Postal Code/ Zip Code Blacklist"
                helpText="Comma separated list of postal codes or zip codes"
                min={0}
                value={
                  editing.exclude_postal_codes
                    ? editing.exclude_postal_codes.join(",")
                    : ""
                }
                onChange={(val) => {
                  handleChange(
                    "exclude_postal_codes",
                    val.split(",").map((x) => x.trim())
                  );
                }}
              />

              <TextField
                label='Custom Service Name (eg: "NYC Local Delivery")'
                type="text"
                value={editing.service_name}
                onChange={(val) => handleChange("service_name", val)}
              />

              {/* Nash */}
              {editing.provider === CARRIERS["Nash"].provider ? (
                <React.Fragment>
                  <React.Fragment>
                    <TextField
                      label="Nash Option Group ID"
                      type="text"
                      value={editing.nash_option_group}
                      onChange={(val) => handleChange("nash_option_group", val)}
                    />
                  </React.Fragment>
                  <React.Fragment>
                    <TextField
                      label="Store Phone Number"
                      type="text"
                      value={editing.nash_store_phone}
                      onChange={(val) => handleChange("nash_store_phone", val)}
                    />
                  </React.Fragment>
                  <React.Fragment>
                    <TextField
                      label="Default Driver Tip"
                      type="number"
                      min={0}
                      value={`${Number(editing.nash_tip_cents / 100.0)}`}
                      onChange={(val) => {
                        console.log({ val });
                        handleChange("nash_tip_cents", +val * 100);
                      }}
                      connectedLeft={
                        <Select
                          value={editing.nash_tip_unit}
                          label="Weight unit"
                          onChange={(val) => {
                            console.log({ val });
                            handleChange("nash_tip_unit", val);
                          }}
                          labelHidden
                          options={["%", "$"]}
                        />
                      }
                    />
                  </React.Fragment>
                </React.Fragment>
              ) : null}

              {/* Trexity */}
              {editing.provider === CARRIERS["Trexity"].provider ? (
                <React.Fragment>
                  <TextField
                    label="Delivery Scheduling Buffer (in minutes)"
                    type="number"
                    min={0}
                    value={editing.delivery_scheduling_buffer}
                    onChange={(val) =>
                      handleChange("delivery_scheduling_buffer", val)
                    }
                  />
                  <Select
                    label="Enable Trexity In Person Handoffs"
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    onChange={(val) => handleChange("trexity_handoff", val)}
                    value={editing.trexity_handoff}
                  />
                </React.Fragment>
              ) : null}

              {user && user.isAdmin ? (
                <React.Fragment>
                  {/* <TextField
                    label="Delivery Subsidy"
                    type="number"
                    min={0}
                    value={`${Number(editing.delivery_markup_cents / 100.0)}`}
                    onChange={(val) => {
                      console.log({ val });
                      handleChange("delivery_markup_cents", +val * 100);
                    }}
                  /> */}

                  <TextField
                    label="Delivery Markup"
                    type="number"
                    min={0}
                    value={`${Number(editing.delivery_markup_cents / 100.0)}`}
                    onChange={(val) => {
                      console.log({ val });
                      handleChange("delivery_markup_cents", +val * 100);
                    }}
                    connectedLeft={
                      <Select
                        value={editing.delivery_markup_unit}
                        label="Weight unit"
                        onChange={(val) => {
                          console.log({ val });
                          handleChange("delivery_markup_unit", val);
                        }}
                        labelHidden
                        options={["%", "$"]}
                      />
                    }
                  />
                </React.Fragment>
              ) : null}
            </FormLayout>
          ) : null}
        </Modal.Section>
      </Modal>
    </Layout.AnnotatedSection>
  );
}
