import {
  Button,
  Card,
  ChoiceList,
  DataTable,
  Filters,
  TextStyle,
} from "@shopify/polaris";
import { useAdmin } from "context/admin";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { STORE_PROVIDER } from "shared/constants";
import { debounce } from "underscore";
import PrintDeliveries from "./PrintDeliveries";
import DeliveryReadyBy from "./components/DeliveryReadyBy";

export default function DeliveryList({ title, deliveries, orderEntities }) {
  const admin = useAdmin();
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [readyBy, setReadyBy] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const [results, setResults] = useState(deliveries);

  const getLocationName = useCallback(
    (address1) => {
      const locations = admin.selectedShop?.locations;
      if (!locations) {
        return null;
      }
      const locationName =
        typeof address1 === "string"
          ? locations.filter((l) => l.address1 === address1)
          : locations.filter((l) => l.address1 === address1[0]);
      return locationName[0]?.name;
    },
    [admin.selectedShop]
  );

  const readyByOptions = deliveries
    .reduce((options, delivery) => {
      const readyHour =
        delivery.data.ready_by &&
        new Date(delivery.data.ready_by).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
      if (readyHour && options.indexOf(readyHour) === -1) {
        return [...options, readyHour];
      } else {
        return options;
      }
    }, [])
    .sort(); // TODO: Fix sorting 09:00AM < 01:00PM

  const handleReadyByChange = useCallback((value) => {
    // Only take the last one that was selected
    if (value && value.length === 0) {
      value = null;
    }
    if (value && value.length > 1) {
      value = [value[value.length - 1]];
    }
    setReadyBy(value);
  }, []);
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleReadyByRemove = useCallback(() => setReadyBy(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleOriginRemove = useCallback(() => setOrigin(null), []);

  const handleFiltersClearAll = useCallback(() => {
    handleReadyByRemove();
    handleQueryValueRemove();
    handleOriginRemove();
  }, [handleReadyByRemove, handleQueryValueRemove, handleOriginRemove]);

  useEffect(() => {
    const newAppliedFilters = [];
    if (origin) {
      newAppliedFilters.push({
        key: "origin",
        label: `Origin: ${getLocationName(origin)}`,
        onRemove: handleOriginRemove,
      });
    }

    if (readyBy) {
      const key = "readyBy";
      newAppliedFilters.push({
        key,
        label: `Ready By: ${readyBy}`,
        onRemove: handleReadyByRemove,
      });
    }
    setAppliedFilters(newAppliedFilters);
  }, [
    origin,
    readyBy,
    handleReadyByRemove,
    handleOriginRemove,
    getLocationName,
  ]);

  const originOptions = deliveries
    .reduce((options, delivery) => {
      const originAddress = delivery.origin && `${delivery.origin.address1}`;
      if (originAddress && !options.includes(originAddress)) {
        return [...options, originAddress];
      } else {
        return options;
      }
    }, [])
    .sort();

  const handleOriginChange = useCallback((value) => {
    // Only take the last one that was selected
    if (value && value.length === 0) {
      value = null;
    }
    if (value && value.length > 1) {
      value = [value[value.length - 1]];
    }
    setOrigin(value);
  }, []);

  console.log({ deliveries });

  const filters = [
    {
      key: "origin",
      label: "Origin",
      filter: (
        <ChoiceList
          title="Origin"
          titleHidden
          choices={originOptions.map((option) => ({
            label: getLocationName(option),
            value: option,
          }))}
          selected={origin || []}
          onChange={handleOriginChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "readyBy",
      label: "Ready By",
      filter: (
        <ChoiceList
          title="Ready By"
          titleHidden
          choices={readyByOptions.map((option) => ({
            label: option,
            value: option,
          }))}
          selected={readyBy || []}
          onChange={handleReadyByChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const debounced = useRef(
    debounce((query, toSearch) => {
      if (toSearch) {
        const r = toSearch.filter(
          (delivery) =>
            delivery.receiver &&
            delivery.receiver.name.indexOf(`${query}`) !== -1
        );
        setResults(r);
      }
    }, 300)
  );

  useEffect(() => {
    if (deliveries.length === 0) {
      return;
    }
    if (!queryValue && appliedFilters.length === 0) {
      setResults(deliveries);
      return;
    }
    let filtered;
    if (appliedFilters.length > 0) {
      filtered = deliveries.filter((delivery) => {
        let passes = true;
        appliedFilters.forEach((filter) => {
          if (filter.key === "readyBy") {
            const values = filter.label.split("Ready By: ")[1].split(",");
            if (
              values.indexOf(
                new Date(delivery.data.ready_by).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              ) === -1
            ) {
              passes = false;
            }
          } else if (filter.key === "origin") {
            const values = filter.label.split("Origin: ")[1];
            if (getLocationName(delivery.origin.address1) !== values) {
              console.log(delivery.origin.address1, values);
              passes = false;
            }
          }
        });
        console.log({ filtered });
        return passes;
      });
    }

    if (queryValue && queryValue.length > 2) {
      debounced.current(queryValue, filtered || deliveries);
    } else if (filtered) {
      setResults(filtered);
    }
  }, [deliveries, queryValue, appliedFilters, getLocationName]);

  const headers = [
    { type: "text", label: "provider", fn: (d) => d.shipping_provider },
    {
      type: "text",
      label: "Customer",
      fn: (d) => d.data && d.data.receiver.name,
    },
    {
      type: "text",
      label: "Origin",
      fn: (d) => d.data && getLocationName(d.origin.address1),
    },
    {
      type: "text",
      label: "Destination",
      fn: (d) =>
        d.data && (d.data.destination.street || d.data.destination.address1),
    },
    {
      type: "text",
      label: "Ready By",
      fn: (delivery) => <DeliveryReadyBy delivery={delivery} />,
    },
    {
      type: "text",
      label: "Status",
      fn: (delivery) => {
        let variation =
          delivery.status === "pending" || delivery.status === "complete"
            ? "positive"
            : "negative";
        return <TextStyle variation={variation}>{delivery.status}</TextStyle>;
      },
    },
  ];
  const storeDeliveries = results
    .filter((d) => d.shipping_provider === STORE_PROVIDER)
    .filter((d) => d.status !== "cancelled");

  const rows = results.map((row) =>
    headers.map((header) => header.fn(header.col ? row[header.col] : row))
  );

  const showGoogleMaps = () => {
    const storeDeliveryAddresses = storeDeliveries
      .map((d) => d.data && d.data.destination)
      .filter((d) => !!d)
      .map((d) => `${d.address1}, ${d.city}, ${d.province}`);
    const origin = storeDeliveries[0].origin;
    const originAddress = origin
      ? `${origin.address1}, ${origin.city}, ${origin.province}`
      : null;
    const routeAddresses = originAddress
      ? [originAddress, ...storeDeliveryAddresses]
      : storeDeliveryAddresses;
    if (routeAddresses.length <= 1) {
      return "";
    }
    const addressString = routeAddresses
      .map((a) => encodeURIComponent(a.replace(/ /g, "+")))
      .join("/");
    const directionsUrl = `https://www.google.ca/maps/dir/${addressString}`;
    admin.navigateExternal(directionsUrl);
  };

  if (deliveries.length > 0) {
    return (
      <Card title={title}>
        <Card.Header>
          <span>
            Showing {results.length} Deliver{results.length !== 1 ? "ies" : "y"}
          </span>
          <PrintDeliveries
            deliveries={storeDeliveries}
            orderEntities={orderEntities}
            title={title}
            trigger={() => (
              <Button
                size="slim"
                disabled={storeDeliveries.length === 0}
                primary
              >
                {storeDeliveries.length > 0
                  ? "Print Store Deliveries"
                  : "No Store Deliveries"}
              </Button>
            )}
          />

          <Button
            size="slim"
            disabled={storeDeliveries.length === 0}
            primary
            onClick={showGoogleMaps}
          >
            Google Maps
          </Button>
        </Card.Header>
        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
          <DataTable
            columnContentTypes={headers.map((h) => h.type)}
            headings={headers.map((h) => h.label)}
            rows={rows}
          />
        </Card.Section>
      </Card>
    );
  }

  return <Card sectioned>No deliveries found.</Card>;
}
