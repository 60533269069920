import React from 'react'
import { parseOrderData } from 'util/helpers';

// Some older orders will only have service objects,
// newer orderes will have data stored in the note_attributes
export default function OrderCollectionDate({ order }) {
  const { collectionDate, pickupTime } = parseOrderData(order);

  if (!collectionDate) {
    return '';
  }

  const time = pickupTime || collectionDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    // <span>{time} - </span>
    <span>{time}<br />{collectionDate.toLocaleDateString()}</span>
  )
}
