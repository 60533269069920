import React, { useCallback, useState } from "react";
// @material-ui/core components

import {
  Banner,
  Card,
  DatePicker,
  FormLayout,
  Layout,
  Page,
  Select,
  Tabs,
} from "@shopify/polaris";
import { useAdmin } from "context/admin";
import OrderList from "./OrderList";

import { Modal } from "@shopify/polaris";

import { useUser } from "context/user";
import useLocalStorage from "hooks/useLocalStorage";
import { useHistory, useLocation } from "react-router-dom";
import LoadingPage from "shared/LoadingPage";
import { fetcher, postData } from "util/fetch";
import { getWeekday, parseOrderData } from "util/helpers";
import LineItemList from "./LineItemList";

export default function OrdersPage() {
  const admin = useAdmin();
  const user = useUser();
  const { pathname } = useLocation();
  const history = useHistory();

  const [printSpreadsheetModalOpen, setPrintSpreadsheetModalOpen] = useState(
    false
  );

  const [locations, setLocations] = useState();

  const [currentLocation, setCurrentLocation] = useState(null);

  const spreadSheetOptions = ["Order Picking Sheet", "Item Summary Sheet"];

  const [spreadSheetType, setSpreadSheetType] = useState("Order Picking Sheet");

  const viewModes = {
    orders: "orders",
    lineItems: "line_items",
  };

  const handleClose = () => {
    setPrintSpreadsheetModalOpen(null);
  };

  const todayDate = new Date();
  const [{ month, year }, setDate] = useState({
    month: todayDate.getMonth(todayDate),
    year: todayDate.getFullYear(todayDate),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  // const viewModeKey = `viewMode-${admin.selectedShopID}`;
  const ordersTabKey = `ordersTab-${admin.selectedShopID}`;

  if (admin.selectedShop && locations == null) {
    (async () => {
      const res = await fetcher(
        `/api/shops/${admin.selectedShop.id}/locations`
      );
      setLocations(await res.json());
    })();
  }

  // const [viewMode, setViewMode] = useLocalStorage(viewModeKey, viewModes.orders);
  const [viewMode] = useState(viewModes.orders);
  const [selected, setSelected] = useLocalStorage(ordersTabKey, 0);
  const [showUpdates, setShowUpdates] = useState(false);
  // const [showUpdates, setShowUpdates] = useLocalStorage(`showUpdates-${admin.selectedShopId}-20201210`, true);

  // const toggleViewMode = () => {
  //   setViewMode(mode => mode === viewModes.orders ? viewModes.lineItems : viewModes.orders);
  // }

  const handleSelect = (order) => {
    history.push(`${pathname}/${order.id}`);
  };

  if (
    !admin.selectedShop ||
    !admin.orders ||
    !admin.orders.shops[admin.selectedShopId] ||
    (admin.orders.shops[admin.selectedShopId].length === 0 && admin.loading)
  ) {
    return <LoadingPage />;
  }

  const orderIds = admin.orders.shops[admin.selectedShop.id] || [];
  const orders = orderIds.map((id) => admin.orders.entities[id]);

  const newDate = new Date();
  const today = new Date(
    newDate.getFullYear(),
    newDate.getMonth(),
    newDate.getDate()
  );
  // need to update this so that i can get 6 days from today
  const yesterday = new Date(today);
  const tomorrow = new Date(today);
  const dayAfterTomorrow = new Date(today);
  const twoDaysAfterTomorrow = new Date(today);
  const threeDaysAfterTomorrow = new Date(today);
  const fourDaysAfterTomorrow = new Date(today);
  const fiveDaysAfterTomorrow = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  tomorrow.setDate(today.getDate() + 1);
  dayAfterTomorrow.setDate(today.getDate() + 2);
  const weekdayAfterTomorrow = getWeekday(dayAfterTomorrow.getDay());
  twoDaysAfterTomorrow.setDate(today.getDate() + 3);
  const weekdayTwoDaysAfterTomorrow = getWeekday(twoDaysAfterTomorrow.getDay());
  threeDaysAfterTomorrow.setDate(today.getDate() + 4);
  const weekdayThreeDaysAfterTomorrow = getWeekday(
    threeDaysAfterTomorrow.getDay()
  );
  fourDaysAfterTomorrow.setDate(today.getDate() + 5);
  const weekdayFourDaysAfterTomorrow = getWeekday(
    fourDaysAfterTomorrow.getDay()
  );
  fiveDaysAfterTomorrow.setDate(today.getDate() + 6);
  const weekdayFiveDaysAfterTomorrow = getWeekday(
    fiveDaysAfterTomorrow.getDay()
  );

  const grouped = orders.reduce(
    (groups, order) => {
      try {
        const { year, month, day, hour } = parseOrderData(order);
        if (year && month && day && hour) {
          const collectionDate = new Date(year, month - 1, day);
          const isToday = collectionDate.getTime() === today.getTime();
          const isTomorrow = collectionDate.getTime() === tomorrow.getTime();
          const isYesterday = collectionDate.getTime() === yesterday.getTime();
          const isDayAfterTomorrow =
            collectionDate.getTime() === dayAfterTomorrow.getTime();
          // collectionDate.setHours(hour);
          const isTwoDaysAfterTomorrow =
            collectionDate.getTime() === twoDaysAfterTomorrow.getTime();
          const isThreeDaysAfterTomorrow =
            collectionDate.getTime() === threeDaysAfterTomorrow.getTime();
          const isFourDaysAfterTomorrow =
            collectionDate.getTime() === fourDaysAfterTomorrow.getTime();
          const isFiveDaysAfterTomorrow =
            collectionDate.getTime() === fiveDaysAfterTomorrow.getTime();

          if (isToday) {
            return {
              ...groups,
              today: [...groups.today, order],
            };
          } else if (isYesterday) {
            return {
              ...groups,
              yesterday: [...groups.yesterday, order],
            };
          } else if (isTomorrow) {
            return {
              ...groups,
              tomorrow: [...groups.tomorrow, order],
            };
          } else if (isDayAfterTomorrow) {
            return {
              ...groups,
              dayAfterTomorrow: [...groups.dayAfterTomorrow, order],
            };
          } else if (isTwoDaysAfterTomorrow) {
            return {
              ...groups,
              twoDaysAfterTomorrow: [...groups.twoDaysAfterTomorrow, order],
            };
          } else if (isThreeDaysAfterTomorrow) {
            return {
              ...groups,
              threeDaysAfterTomorrow: [...groups.threeDaysAfterTomorrow, order],
            };
          } else if (isFourDaysAfterTomorrow) {
            return {
              ...groups,
              fourDaysAfterTomorrow: [...groups.fourDaysAfterTomorrow, order],
            };
          } else if (isFiveDaysAfterTomorrow) {
            return {
              ...groups,
              fiveDaysAfterTomorrow: [...groups.fiveDaysAfterTomorrow, order],
            };
          } else if (collectionDate < new Date()) {
            return {
              ...groups,
              past: [...groups.past, order],
            };
          } else {
            return {
              ...groups,
              upcoming: [...groups.upcoming, order],
            };
          }
        }
      } catch (error) {
        console.log(error);
      }

      return {
        ...groups,
        notScheduled: [...groups.notScheduled, order],
      };
    },
    {
      today: [],
      tomorrow: [],
      dayAfterTomorrow: [],
      twoDaysAfterTomorrow: [],
      threeDaysAfterTomorrow: [],
      fourDaysAfterTomorrow: [],
      fiveDaysAfterTomorrow: [],
      upcoming: [],
      yesterday: [],
      past: [],
      notScheduled: [],
    }
  );

  const handleTabChange = (selectedTabIndex) => setSelected(selectedTabIndex);

  const tabs = [
    {
      id: "yesterday",
      content: "Yesterday",
      title: `Orders for Yesterday, ${yesterday.toLocaleDateString()}`,
      lineItemTitle: `Line Items for Yesterday, ${yesterday.toLocaleDateString()}`,
      panelID: "yesterdays-orders",
      date: null,
    },
    {
      id: "today",
      content: "Today",
      title: `Orders for Today, ${today.toLocaleDateString()}`,
      lineItemTitle: `Line Items for Today, ${today.toLocaleDateString()}`,
      panelID: "todays-orders",
      date: today,
    },
    {
      id: "tomorrow",
      content: "Tomorrow",
      title: `Orders for Tomorrow, ${tomorrow.toLocaleDateString()}`,
      lineItemTitle: `Line Items for Tomorrow, ${tomorrow.toLocaleDateString()}`,
      panelID: "tomorrows-orders",
      date: tomorrow,
    },
    {
      id: "dayAfterTomorrow",
      content: weekdayAfterTomorrow,
      title: `Orders for ${weekdayAfterTomorrow}, ${dayAfterTomorrow.toLocaleDateString()}`,
      lineItemTitle: `Line Items for ${weekdayAfterTomorrow}, ${dayAfterTomorrow.toLocaleDateString()}`,
      panelID: `${weekdayAfterTomorrow.toLowerCase()}-orders`,
      date: dayAfterTomorrow,
    },
    {
      id: "upcoming",
      content: "Upcoming",
      title: "Upcoming Orders",
      lineItemTitle: "Upcoming Line Items",
      panelID: "upcoming-orders",
      date: null,
    },
    {
      id: "notScheduled",
      content: "Not Scheduled",
      title: "Not Scheduled",
      panelID: "not-scheduled-orders",
      date: null,
    },
  ];

  if (admin.selectedShop?.shopify_domain.includes("sanagans")) {
    tabs.splice(
      4,
      0,
      {
        id: "twoDaysAfterTomorrow",
        content: weekdayTwoDaysAfterTomorrow,
        title: `Orders for ${weekdayTwoDaysAfterTomorrow}, ${twoDaysAfterTomorrow.toLocaleDateString()}`,
        lineItemTitle: `Line Items for ${weekdayTwoDaysAfterTomorrow}, ${twoDaysAfterTomorrow.toLocaleDateString()}`,
        panelID: `${weekdayTwoDaysAfterTomorrow.toLowerCase()}-orders`,
        date: twoDaysAfterTomorrow,
      },
      {
        id: "threeDaysAfterTomorrow",
        content: weekdayThreeDaysAfterTomorrow,
        title: `Orders for ${weekdayThreeDaysAfterTomorrow}, ${threeDaysAfterTomorrow.toLocaleDateString()}`,
        lineItemTitle: `Line Items for ${weekdayThreeDaysAfterTomorrow}, ${threeDaysAfterTomorrow.toLocaleDateString()}`,
        panelID: `${weekdayThreeDaysAfterTomorrow.toLowerCase()}-orders`,
        date: threeDaysAfterTomorrow,
      },
      {
        id: "fourDaysAfterTomorrow",
        content: weekdayFourDaysAfterTomorrow,
        title: `Orders for ${weekdayFourDaysAfterTomorrow}, ${fourDaysAfterTomorrow.toLocaleDateString()}`,
        lineItemTitle: `Line Items for ${weekdayFourDaysAfterTomorrow}, ${fourDaysAfterTomorrow.toLocaleDateString()}`,
        panelID: `${weekdayFourDaysAfterTomorrow.toLowerCase()}-orders`,
        date: fourDaysAfterTomorrow,
      },
      {
        id: "fiveDaysAfterTomorrow",
        content: weekdayFiveDaysAfterTomorrow,
        title: `Orders for ${weekdayFiveDaysAfterTomorrow}, ${fiveDaysAfterTomorrow.toLocaleDateString()}`,
        lineItemTitle: `Line Items for ${weekdayFiveDaysAfterTomorrow}, ${fiveDaysAfterTomorrow.toLocaleDateString()}`,
        panelID: `${weekdayFiveDaysAfterTomorrow.toLowerCase()}-orders`,
        date: fiveDaysAfterTomorrow,
      }
    );
  }

  const printActions = [
    {
      title: "Print",
      accessibilityLabel: "Print",
      actions: [
        {
          content: "Print Spreadsheet",
          accessibilityLabel: "Print Spreadsheet",
          onAction: () => {
            setPrintSpreadsheetModalOpen(true);
          },
        },
      ],
    },
  ];

  const handlePrintSpreadsheet = () => {
    console.log(spreadSheetType);
    const date = selectedDates.start.toLocaleDateString("en-ca");
    // There is an issue with the date format in past months

    // get location id from location
    // console.log(currentLocation.id);
    let params = `date=${date}`;

    console.log(admin.selectedShop?.shopify_domain);
    if (
      admin.selectedShop?.shopify_domain.includes("sanagans") &&
      currentLocation != null
    ) {
      params += `&location=${currentLocation.id}`;
    }
    console.log(params);

    if (spreadSheetType === "Order Picking Sheet") {
      admin.downloadFromLink(
        `/api/shops/${admin.selectedShopId}/orders/summary.xlsx?${params}`,
        currentLocation != null &&
          admin.selectedShop?.shopify_domain.includes("sanagans")
          ? `order-summary-${date}_${currentLocation.name
              .toLowerCase()
              .replace(" ", "-")}.xlsx`
          : `order-summary-${date}.xlsx`
      );
    }

    if (spreadSheetType === "Item Summary Sheet") {
      admin.downloadFromLink(
        `/api/shops/${admin.selectedShopId}/orders/item_summary.xlsx?${params}`,
        currentLocation != null &&
          admin.selectedShop?.shopify_domain.includes("sanagans")
          ? `order-item-summary-${date}_${currentLocation.name
              .toLowerCase()
              .replace(" ", "-")}.xlsx`
          : `order-item-summary-${date}.xlsx`
      );
    }
  };

  const ambrosiaActions =
    admin.selectedShop?.shopify_domain === "ambrosia-express.myshopify.com"
      ? [
          {
            title: "Ambrosia",
            accessibilityLabel: "Ambrosia Actions",
            actions: [
              {
                content: "Run Full Sync",
                accessibilityLabel: "Synchronize Shopify products with POS",
                onAction: () => {
                  const ambrosiaSyncAt =
                    localStorage.getItem("ambrosia_full_sync") || 0;
                  if (
                    new Date().getTime() - Number(ambrosiaSyncAt) <
                    1000 * 60 * 60
                  ) {
                    admin.setToast(
                      "Cannot run sync more than once per hour. Please wait and try again."
                    );
                    return;
                  }

                  postData(
                    `/api/shops/${admin.selectedShopId}/ambrosia_full_sync`
                  )
                    .then(() => {
                      localStorage.setItem(
                        "ambrosia_full_sync",
                        new Date().getTime()
                      );
                      admin.setToast(
                        "Full sync started, this may take up to 20 minutes to reflect in the store."
                      );
                    })
                    .catch(() => {
                      admin.setToast(
                        "There was an error running sync, it may already have been run within the last hour. Please wait and try again."
                      );
                    });
                },
              },
            ],
          },
        ]
      : [];

  const sanagansActions = Array.isArray(locations)
    ? locations.map((location) => ({
        title: `Print for ${location.name}`, // Assuming each location has a 'name' property
        accessibilityLabel: `Print for ${location.name}`,
        actions: [
          {
            content: "Print Spreadsheet",
            accessibilityLabel: "Print Spreadsheet",
            onAction: () => {
              setPrintSpreadsheetModalOpen(true);
              // You might want to set the current location here as well, to know which location's spreadsheet to print
              setCurrentLocation(location);
            },
          },
        ],
      }))
    : [];

  return (
    <Page
      data-cy="OrdersPage"
      title="Orders"
      actionGroups={
        user.isVendor
          ? []
          : admin.selectedShop?.shopify_domain.includes("sanagans")
          ? [...sanagansActions]
          : [...printActions, ...ambrosiaActions]
      }
    >
      <Layout>
        <Layout.Section>
          {showUpdates ? (
            <Banner
              title="There have been some updates!"
              status="info"
              onDismiss={() => setShowUpdates(false)}
            >
              <ul>
                <li>
                  You can now filter orders by Order # by searching below or by
                  Shipping Method.
                </li>
                <li>
                  You can now print store deliveries from the Deliveries page.
                </li>
                <li>
                  You can dismiss this banner by clicking the 'X' in the top
                  right corner.
                </li>
              </ul>
            </Banner>
          ) : (
            ""
          )}
        </Layout.Section>
        <Layout.Section>
          <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
            {tabs[selected] ? (
              <Card
                title={
                  viewMode === viewModes.orders
                    ? tabs[selected].title
                    : tabs[selected].lineItemTitle
                }
                // actions={[{content: viewMode === viewModes.orders ? 'View Line Items' : 'View Orders', onAction: toggleViewMode}]}
              >
                {viewMode === viewModes.orders ? (
                  <OrderList
                    shop={admin.selectedShop}
                    title={tabs[selected].title}
                    date={tabs[selected].date}
                    orders={grouped[tabs[selected].id]}
                    deliveries={admin.deliveries}
                    handleSelect={handleSelect}
                    tab={selected}
                  />
                ) : (
                  <LineItemList
                    orders={grouped[tabs[selected].id]}
                    loading={admin.loadingProducts}
                    products={admin.products}
                    tab={selected}
                  />
                )}
              </Card>
            ) : null}
          </Tabs>
        </Layout.Section>
      </Layout>

      {printSpreadsheetModalOpen ? (
        <Modal
          open={printSpreadsheetModalOpen}
          onClose={handleClose}
          title={
            currentLocation == null
              ? "Print Spreadsheet"
              : `Print Spreadsheet for ${currentLocation.name}`
          }
          primaryAction={{
            content: "Download",
            onAction: handlePrintSpreadsheet,
          }}
        >
          <Modal.Section>
            <FormLayout>
              <Select
                label="Select Spreadsheet"
                options={spreadSheetOptions}
                onChange={(value) => setSpreadSheetType(value)}
                value={spreadSheetType}
              />
              <DatePicker
                month={month}
                year={year}
                onChange={setSelectedDates}
                onMonthChange={handleMonthChange}
                selected={selectedDates}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      ) : null}
    </Page>
  );
}
