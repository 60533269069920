import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { AdminProvider } from "context/admin";

import AuthLayout from "layouts/Auth.js";
import DashboardLayout from "layouts/Dashboard.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const hist = createBrowserHistory();

export default function AuthenticatedApp() {
  return (
    <Router history={hist}>
      <AdminProvider>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <Route path="/" component={DashboardLayout} />
          <Redirect from="/" to="/shops" />
        </Switch>
      </AdminProvider>
    </Router>
  )
}
