import React, { useEffect, useState, useRef } from "react";

import {
  Card,
  Page,
  Modal,
  Popover,
  TextField,
  ActionList,
  Heading,
  FormLayout,
  Stack,
  Tag,
  DataTable,
  OptionList,
  Button,
} from "@shopify/polaris";
import { MoreHoriz as MoreIcon } from "@material-ui/icons";
import { useUser } from "context/user";
import { useAdmin } from "context/admin";
import { postData, putData, deleteRequest, fetcher } from "util/fetch";
import { debounce } from "underscore";

export default function ShopUsersPage() {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [addingUser, setAddingUser] = useState(false);
  const [userQuery, setUserQuery] = useState("");
  const [popover, setPopover] = useState(false);
  const [users, setUsers] = useState([]);
  const [results, setResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const user = useUser();
  const { selectedShopId, setToast, setConfirmation } = useAdmin();

  const validRoles = ["manager", "import"]; //, 'vendor', 'beta', 'catalog'];
  const newUser = { name: "", email: "", roles: [], password: "" };

  useEffect(() => {
    if (selectedShopId) {
      setLoading(true);
      fetcher(`/api/shops/${selectedShopId}/users`)
        .then((res) => res.json())
        .then((users) => {
          setUsers(users);
        })
        .catch((error) => console.log({ error }))
        .finally(() => {
          setLoading(false);
          setLoaded(true);
        });
    }
  }, [selectedShopId]);

  const debounced = useRef(
    debounce((userQuery) => {
      setSearching(true);
      fetcher(`/api/users?query=${encodeURIComponent(userQuery)}`)
        .then((res) => res.json())
        .then((res) => {
          const existingShopUserIds = users.map((u) => u.id);
          setResults(
            res.filter((user) => existingShopUserIds.indexOf(user.id) === -1)
          );
        })
        .catch((error) => console.log({ error }))
        .finally(() => {
          setSearching(false);
        });
    }, 300)
  );

  useEffect(() => {
    if (!userQuery || userQuery.length < 3) {
      return;
    }

    debounced.current(userQuery);
  }, [userQuery]);

  if (!(user && user.isAdmin)) {
    return <div>Not authorized</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleAddSelectedUsers = () => {
    if (selectedUser.length !== 1) {
      return;
    }
    const user = selectedUser[0];

    setSaving(true);
    postData(`/api/shops/${selectedShopId}/user_shops`, { user_id: user.id })
      .then((res) => {
        setUsers([user, ...users]);
        setToast("User successfully added!");
        closeAddingUserModal();
        setResults([]);
      })
      .catch((error) => {
        setToast("There was an error adding user.");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleEdit = (user) => {
    // Don't add password field
    setEditing({
      id: user.id,
      name: user.name,
      email: user.email,
      roles: user.roles,
    });
    setPopover(false);
  };

  const handleChange = (key, val) => {
    setEditing({
      ...editing,
      [key]: val,
    });
  };

  const handleCreate = (user) => {
    setSaving(true);
    postData(`/api/users`, { user, shop_ids: [selectedShopId] })
      .then((res) => res.json())
      .then((res) => {
        setUsers([...users, res]);
        setToast("User successfully created!");
        setEditing(false);
        setPopover(false);
      })
      .catch((err) => {
        setToast("There was an error saving user.");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleSave = () => {
    if (editing.id) {
      handleUpdate(editing);
    } else {
      handleCreate(editing);
    }
  };

  const handleUpdate = (user) => {
    setSaving(true);
    const payload = { name: user.name, email: user.email, roles: user.roles };
    if (user.password) {
      payload.password = user.password;
    }

    putData(`/api/users/${user.id}`, { user: payload })
      .then((res) => res.json())
      .then((res) => {
        setUsers(users.map((u) => (u.id === res.id ? res : u)));
        setToast("User successfully updated!");
        setEditing(false);
      })
      .catch((err) => {
        console.log({ err });
        setToast("There was an error saving user.");
      })
      .finally(() => {
        setSaving(false);
        setPopover(false);
      });
  };

  const handleRemoveAccess = (user) => {
    setPopover(false);

    setConfirmation({
      content: `Are you sure you remove ${user.name}'s access to this shop?`,
      handleOk: () => removeUserAccess(user),
    });
  };

  const removeUserAccess = (user) => {
    setSaving(true);
    deleteRequest(`/api/shops/${selectedShopId}/user_shops/${user.id}`)
      .then((res) => {
        setUsers(users.filter((u) => u.id !== user.id));
        setToast("User access successfully removed!");
      })
      .catch((error) => console.log({ error }))
      .finally(() => {
        setSaving(false);
      });
  };

  const handleRemove = (user) => {
    setPopover(false);

    setConfirmation({
      content: "Are you sure you want to cancel this delivery?",
      handleOk: () => deleteUser(user),
    });
  };

  const deleteUser = (user) => {
    deleteRequest(`/api/users/${user.id}`)
      .then((res) => {
        setUsers(users.filter((u) => u.id !== user.id));
        setToast("User successfully deleted!");
      })
      .catch((err) => setToast("There was an error deleting this user."));
  };

  const closeAddingUserModal = () => {
    setAddingUser(false);
    setUserQuery("");
  };

  const headers = [
    { type: "text", label: "Name", col: "name", fn: (x) => x },
    { type: "text", label: "Email", col: "email", fn: (x) => x },
    { type: "text", label: "Roles", col: "roles", fn: (x) => x.join(", ") },
    {
      type: "numeric",
      col: null,
      label: "",
      fn: (user) => {
        return (
          <Popover
            active={popover === user.id}
            activator={
              <MoreIcon
                style={{ cursor: "pointer" }}
                onClick={() => setPopover(user.id)}
              />
            }
            onClose={() => setPopover(false)}
          >
            <ActionList
              items={[
                { content: "Edit", onAction: () => handleEdit(user) },
                {
                  content: "Remove Access",
                  onAction: () => handleRemoveAccess(user),
                  destructive: true,
                },
                {
                  content: "Delete",
                  onAction: () => handleRemove(user),
                  destructive: true,
                },
              ]}
            />
          </Popover>
        );
      },
    },
  ];

  return (
    <Page
      title="Users"
      // primaryAction={{ content: 'Add User', onAction: () => history.push({ pathname: `./users/new` })}}
    >
      <Card>
        <Card.Header
          actions={[
            { content: "Create New User", onAction: () => setEditing(newUser) },
            {
              content: "Add Existing User",
              onAction: () => setAddingUser(true),
            },
          ]}
          title="Users"
        ></Card.Header>

        {loaded && users.length === 0 ? (
          <Card.Section>No users found.</Card.Section>
        ) : null}

        {loaded && users.length > 0 ? (
          <DataTable
            columnContentTypes={headers.map((h) => h.type)}
            headings={headers.map((h) => h.label)}
            rows={users.map((row) =>
              headers.map((header) =>
                header.fn(header.col ? row[header.col] : row)
              )
            )}
          />
        ) : null}
      </Card>

      <Modal
        title={editing.id ? `Edit ${editing.name}` : "Create User"}
        open={editing}
        onClose={() => {
          setEditing(false);
          setEditPassword(false);
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setEditing(false);
              setEditPassword(false);
            },
          },
        ]}
        primaryAction={{
          content: "Save",
          loading: saving,
          onAction: () => handleSave(editing),
          disabled: saving,
        }}
      >
        {editing ? (
          <Modal.Section>
            <FormLayout>
              <TextField
                label="Name"
                type="text"
                value={`${editing.name}`}
                onChange={(val) => handleChange("name", val)}
              />

              <TextField
                label="Email"
                type="email"
                value={`${editing.email}`}
                onChange={(val) => handleChange("email", val)}
              />

              {/* Show password field if editing password */}
              {editing.id && editPassword ? (
                <TextField
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={editing.password || null}
                  onChange={(val) => handleChange("password", val)}
                  connectedRight={
                    <Button onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  }
                />
              ) : null}
              {editing.id && !editPassword ? (
                <Button plain onClick={() => setEditPassword(true)}>
                  Edit Password
                </Button>
              ) : null}

              <Stack spacing="tight">
                {editing.roles.map((role) => (
                  <Tag
                    key={role}
                    onRemove={() =>
                      setEditing({
                        ...editing,
                        roles: editing.roles.filter((r) => r !== role),
                      })
                    }
                  >
                    {role}
                  </Tag>
                ))}
              </Stack>

              <Heading>Roles</Heading>
              {editing.roles.length === 0 ? (
                <p>Select role if necessary</p>
              ) : null}

              <Stack spacing="tight">
                {validRoles
                  .filter(
                    (validRole) => editing.roles.indexOf(validRole) === -1
                  )
                  .map((role) => (
                    <Tag
                      key={role}
                      onClick={() =>
                        setEditing({
                          ...editing,
                          roles: [...editing.roles, role],
                        })
                      }
                    >
                      {role}
                    </Tag>
                  ))}
              </Stack>
            </FormLayout>

            {/* {errors.dates ? <TextStyle variation="negative">{errors.dates}</TextStyle> : '' } */}
          </Modal.Section>
        ) : null}
      </Modal>

      <Modal
        title={editing.id ? `Edit ${editing.name}` : "Create User"}
        open={addingUser}
        onClose={() => closeAddingUserModal()}
        secondaryActions={[
          { content: "Cancel", onAction: () => closeAddingUserModal() },
        ]}
        primaryAction={{
          content: "Add",
          disabled: saving || selectedUser.length !== 1,
          loading: saving,
          onAction: () => handleAddSelectedUsers(),
        }}
      >
        <Modal.Section>
          <FormLayout>
            <TextField
              label="Search Users"
              type="text"
              value={userQuery}
              onChange={(val) => setUserQuery(val)}
            />

            {searching ? "Loading..." : null}

            {userQuery.length > 2 && !searching && results.length === 0
              ? "No results."
              : null}

            {userQuery.length > 2 && !searching && results.length > 0 ? (
              <OptionList
                onChange={setSelectedUser}
                options={results.map((user) => ({
                  value: user,
                  label: `${user.name} (${user.email})`,
                }))}
                selected={selectedUser}
              />
            ) : null}
          </FormLayout>
        </Modal.Section>
      </Modal>
    </Page>
  );
}
