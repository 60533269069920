import React, { useCallback, useEffect, useState } from "react";
import {
  Banner,
  Caption,
  Card,
  DataTable,
  DatePicker,
  DropZone,
  Layout,
  Page,
  Stack,
  Thumbnail,
} from "@shopify/polaris";
import { postData } from "util/fetch";
import { useAdmin } from "context/admin";
import { csvStringToArray } from "util/csv";

export default function SalesPage() {
  const [files, setFiles] = useState([]);
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState(null);
  const admin = useAdmin();

  const today = new Date();

  const [{ month, year }, setDate] = useState({
    month: today.getMonth(),
    year: today.getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: today,
    end: new Date(new Date().setDate(today.getDate() + 7)),
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => acceptedFiles),
    []
  );

  useEffect(() => {
    if (files.length > 0) {
      var reader = new FileReader();
      reader.addEventListener("load", (e) => {
        // TOOD: allow customer to edit uploaded CSV before creating sale items
        const saleItems = csvStringToArray(e.target.result);
        setItems(saleItems);
      });

      reader.readAsBinaryString(files[0]);
    }
  }, [files]);

  const createSale = () => {
    postData(`/api/shops/${admin.selectedShopId}/sales`, {
      start_date: selectedDates.start,
      end_date: selectedDates.end,
      sale_items: items.slice(1).map((x) => ({ sku: x[0], discount: x[1] })),
    })
      .then((res) => res.json())
      .then((res) => {
        setStatus("success");
        setItems([]);
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const headerRow = items && items.length > 0 ? items[0] : [];
  const headers = headerRow.map((item) => ({
    type: "text",
    label: item,
    fn: (x) => x,
  }));
  const valid =
    headerRow.length >= 2 &&
    (headerRow[0].toLowerCase() === "sku" ||
      headerRow[1].toLowerCase() === "discount");

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !files.length && <DropZone.FileUpload />;
  const uploadedFiles = files.length > 0 && (
    <Stack vertical>
      {files.map((file, index) => (
        <Stack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.indexOf(file.type) > 0
                ? window.URL.createObjectURL(file)
                : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
            }
          />
          <div>
            {file.name} <Caption>{file.size} bytes</Caption>
          </div>
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Page
      data-cy="SalesPage"
      title="Sales"
      primaryAction={{
        content: "Create Sale",
        disabled: !valid,
        onAction: createSale,
      }}
    >
      {status === "success" ? (
        <Banner title="Success" status="success">
          <p>Your sale has been successfully created</p>
        </Banner>
      ) : null}
      <Layout>
        <Layout.AnnotatedSection
          title="Upload Sales List"
          description={
            <div>
              Upload a list of skus with sale prices to. <br />
              <br />
              <a
                href="https://grocerbox-image.s3.amazonaws.com/misc/gb-discount-template.csv"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download sample template here.
              </a>
            </div>
          }
        >
          <Card>
            <DropZone onDrop={handleDropZoneDrop} allowMultiple={false}>
              {uploadedFiles}
              {fileUpload}
            </DropZone>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.Section>
          <Card>
            <Card.Section title="Discount start and end date" style={{}}>
              <DatePicker
                month={month}
                year={year}
                onChange={setSelectedDates}
                onMonthChange={handleMonthChange}
                selected={selectedDates}
                allowRange
              />
            </Card.Section>

            <Card.Section title="List of skus and discounts">
              {items && items.length > 0 && !valid ? (
                <Banner
                  title="Invalid CSV File"
                  action={{
                    content: (
                      <a
                        href="https://grocerbox-image.s3.amazonaws.com/misc/gb-discount-template.csv"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download CSV Template
                      </a>
                    ),
                  }}
                  status="critical"
                >
                  <p>
                    Invalid CSV file. There should only be two columns, with the
                    first row being 'sku' and 'discount'.
                  </p>
                </Banner>
              ) : null}
              {items && items.length > 0 && valid ? (
                <DataTable
                  columnContentTypes={headers.map((h) => h.type)}
                  headings={headers.map((h) => h.label)}
                  rows={items
                    .slice(1)
                    .map((row) => headers.map((header, index) => row[index]))}
                  verticalAlign="middle"
                />
              ) : null}
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
