import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import { Card, DataTable, Layout, Page } from "@shopify/polaris";
import { orderTitle } from "util/helpers";
import styled from 'styled-components';

const ListContainer = styled.div`
  display: flex;
`
const List = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
`
const ListItem = styled.li`
  font-size: 12px;
  display: inline-block;
  width: 100%;
`
const Note = styled.p`
  font-size: 12px;
`

const OrderTable = ({ order }) => {
  const headers = [
    { type: 'text', col: 'vendor', label: 'Vendor', fn: (x) => x },
    { type: 'text', label: 'Qty  ', fn: (x) => x.quantity },
    { type: 'text', col: 'name', label: 'Product', fn: (x) => x },
    { type: 'numeric', label: 'Price', fn: (x) => x.price },
    { type: 'numeric', label: 'Total', fn: (x) => (x.price * x.quantity).toFixed(2) },
  ];

  const lineItemRows = order.line_items.filter(item => item.fulfillable_quantity > 0).map(line_item =>
    headers.map(header => header.fn(header.col ? line_item[header.col] : line_item)));

  const shippingLineItems = order.shipping_lines ? order.shipping_lines.map(shipping_item =>
    ['Shipping', ...Array(headers.length - 2).fill(''), shipping_item.price]
  ) : [];

  const taxLineItems = order.tax_lines ? order.tax_lines.map(tax_line => (
    [tax_line.title, ...Array(headers.length - 2).fill(''), tax_line.price]
  )) : [];

  const rows = [...lineItemRows, ...shippingLineItems, ...taxLineItems];

  return (
    <Card>
      <Card.Header title={orderTitle(order)}>
      </Card.Header>
      <Card.Section>
        <ListContainer>
          <List>
            <ListItem><strong>Billing Address</strong></ListItem>
            <ListItem>{order.billing_address.name}</ListItem>
            <ListItem>{order.billing_address.address1}</ListItem>
            <ListItem>{order.billing_address.address2}</ListItem>
            <ListItem>{`${order.billing_address.city} ${order.billing_address.province_code} ${order.billing_address.zip}`}</ListItem>
            <ListItem>{order.billing_address.country}</ListItem>
          </List>

          {order.shipping_address ?
            <List>
              <ListItem><strong>Shipping Address</strong></ListItem>
              <ListItem>{order.shipping_address.name}</ListItem>
              <ListItem>{order.shipping_address.address1}</ListItem>
              <ListItem>{order.shipping_address.address2}</ListItem>
              <ListItem>{`${order.shipping_address.city} ${order.shipping_address.province_code} ${order.shipping_address.zip}`}</ListItem>
              <ListItem>{order.shipping_address.country}</ListItem>
            </List>
            : ''
          }
        </ListContainer>

        <h6><strong>Invoice</strong></h6>
        <ListContainer>
          <List>
            <ListItem>Order Number: {order.name}</ListItem>
            <ListItem>Phone: {order.billing_address.phone || 'None'}</ListItem>
            <ListItem>Email: {order.contact_email || 'None'}</ListItem>
          </List>
          <List>
            <ListItem>Order Date: {new Date(order.created_at).toUTCString()}</ListItem>
            { order.service ?
                <ListItem>
                  <span style={{ textTransform: 'capitalize'}}>{order.service.type} Date</span>: {order.service.date}
                </ListItem>
              : ''
            }
          </List>
        </ListContainer>

        <DataTable
          showTotalsInFooter
          columnContentTypes={headers.map(h => h.type)}
          headings={headers.map(h => h.label)}
          rows={rows}
          totals={[...Array(headers.length - 1).fill(''), order.total_price]}
          totalsName={{
            singular: 'Total',
            plural: 'Total',
          }}
        />
      </Card.Section>
      <Card.Section>
        { order.note ? <Note><b>Customer Note:</b> {order.note}</Note> : '' }
      </Card.Section>
    </Card>
  )
}


class OrdersList extends React.Component {
  render() {
    return (
      <div id="print-only">
        <Page title={this.props.title}>
          <Layout>
            <Layout.Section>
              {this.props.orders.map(order => <OrderTable key={order.id} order={order} />)}
            </Layout.Section>
          </Layout>
        </Page>
      </div>
    );
  }
}

class PrintVendorOrders extends React.Component {
  render() {
    return (
      <div>
        <style>{`
          @media print {
            .Polaris-Card {
              page-break-before: auto;
              page-break-after: always;
              page-break-inside: avoid;
              padding: 0 2rem;
            }

            .Polaris-DataTable__Cell {
              padding: 0.1rem;
            }
          }
        `}</style>
        <ReactToPrint
          trigger={this.props.trigger}
          content={() => this.componentRef}
        />
        <OrdersList title={this.props.title || 'Orders'} orders={this.props.orders} ref={el => (this.componentRef = el)} />
      </div>
    );
  }
}

export default PrintVendorOrders;

PrintVendorOrders.propTypes = {
  trigger: PropTypes.func,
  orders: PropTypes.array
}
