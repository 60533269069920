import React, { useState } from 'react'
import { ActionList, Card, Checkbox, DataTable, FormLayout, Heading, Layout, Modal, Popover, TextContainer, TextField, TextStyle } from '@shopify/polaris'
import { MoreHoriz as MoreIcon } from '@material-ui/icons';
import { useAdmin } from 'context/admin';

export default function AvailabilityRuleSettings() {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [popover, setPopover] = useState(false);
  const admin = useAdmin();

  const handleAddRule = () => {
    const newRule = { type: 'collection', value: '', weekdays: [], start_date: null, end_date: null, lead_time: null };
    setEditing(newRule);
  }

  const handleEdit = (rule) => {
    setEditing(rule);
    setPopover(false);
  }

  const handleChange = (key, val) => {
    setEditing({
      ...editing,
      [key]: val
    });
  }

  const toggleWeekday = (index) => {
    setEditing(e => ({
      ...editing,
      weekdays: editing.weekdays.indexOf(index) === -1
        ? [...editing.weekdays, index].sort()
        : editing.weekdays.filter(x => x !== index).sort()
    }));
  }

  const handleSave = (rule) => {
    setSaving(true);
    setEditing(false);
    setPopover(false);
    admin.saveAvailabilityRule(rule).finally(() => setSaving(false));
  }

  const handleRemove = (rule) => {
    setSaving(true);
    admin.deleteAvailabilityRule(rule.type, rule.value).finally(() => setSaving(false))
  }

  const availabilityRules = admin.selectedShop.shop_config
    ? admin.selectedShop.shop_config.availability_rules.sort((a, b) => a.start_date - b.start_date)
  : [];

  // Disabled if no value, start_date is greater than end_date, only start_date or end_date is given, or if no settings
  const saveDisabled = !editing || !editing.value
    || (editing.start_date && editing.end_date && editing.start_date > editing.end_date)
    || (!editing.start_date && editing.end_date)
    || (editing.start_date && !editing.end_date)
    || (!editing.start_date && !editing.end_date && editing.weekdays.length === 0 && !editing.lead_time);

  const errors = {
    dates: editing && editing.start_date && editing.end_date && editing.start_date > editing.end_date ? 'Start date must be before end date' : null
  };

  const headers = [
    { type: 'text', label: 'Collection', col: 'value', fn: (x) => x },
    { type: 'text', label: 'Start Date', col: 'start_date', fn: (x) => x},
    { type: 'text', label: 'End Date', col: 'end_date', fn: (x) => x},
    { type: 'text', label: 'Weekdays', fn: (x) => x.weekdays.map(i => weekdays[i]).join(', ') },
    { type: 'text', label: 'Lead Time', fn: (x) => x.lead_time },
    { type: 'numeric', col: null, label: '', fn: (rule) => {
      return <Popover
        active={popover === rule.value}
        activator={<MoreIcon style={{cursor: 'pointer'}} onClick={() => setPopover(rule.value)} />}
        onClose={() => setPopover(false)}
      >
        <ActionList items={[
          {content: 'Edit', onAction: () => handleEdit(rule)},
          {content: 'Delete', onAction: () => handleRemove(rule), destructive: true }
        ]} />
      </Popover>
    }}
  ];

  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  return (
    <Layout.AnnotatedSection
      title="Availability Rules"
      description="Have products available only at certain times of the year or on certain weekdays? Create a rule here with the collection name and dates.">
      <Card>
        <Card.Header
          actions={[
            { content: 'Add', onAction: handleAddRule },
          ]}
          title="Rules"
        >
        </Card.Header>
        { availabilityRules.length > 0 ?
          <DataTable
            columnContentTypes={headers.map(h => h.type)}
            headings={headers.map(h => h.label)}
            rows={availabilityRules.map(row => headers.map(header => header.fn(header.col ? row[header.col] : row)))}
          />
          :
          <Card.Section>
            <TextContainer>
              Add availability rules. For example products in the 'Thanksgiving' collection are only available from October 2nd to October 11th.
            </TextContainer>
          </Card.Section>
        }
      </Card>

      <Modal
        title="Product Availability Rule"
        open={editing}
        onClose={() => setEditing(false)}
        secondaryActions={[{ content: 'Cancel', onAction: () => setEditing(false) }]}
        primaryAction={{content: 'Save', loading: saving, onAction: () => handleSave(editing), disabled: saving || saveDisabled }}>
        <Modal.Section>
          <FormLayout>
            <TextField
              label="Collection Name"
              type="text"
              min={0}
              value={`${editing.value}`}
              onChange={(val) => handleChange('value', val)}
            />

            <Heading>Available Only Within A Certain Date Range</Heading>

            <FormLayout.Group>
              <TextField
                label="Start Date"
                type="date"
                value={`${editing.start_date}`}
                onChange={(val) => handleChange('start_date', val || null)}
              />

              <TextField
                label="End Date"
                type="date"
                value={`${editing.end_date}`}
                onChange={(val) => handleChange('end_date', val || null)}
              />
            </FormLayout.Group>

            <Heading>Available Only On Certain Weekdays</Heading>

            <div style={{display: 'flex'}}>
              {weekdays.map((weekday, index) =>
                <div key={weekday} style={{display: 'flex', flexDirection: 'column', marginRight: '1rem'}}>
                  {weekday}
                  <Checkbox
                    disabled={saving}
                    checked={editing.weekdays && editing.weekdays.indexOf(index) !== -1}
                    onChange={() => toggleWeekday(index)}
                    name={weekday}
                    color="primary"
                  />
                </div>
              )}
            </div>

            <Heading>Requires Special Lead Time</Heading>

            <div style={{display: 'flex'}}>
              <TextField
                label="Order lead time (hours)"
                type="number"
                min={0}
                value={`${editing.lead_time}`}
                onChange={(val) => handleChange('lead_time', +val)}
              />
            </div>
          </FormLayout>

          {errors.dates ? <TextStyle variation="negative">{errors.dates}</TextStyle> : '' }
        </Modal.Section>
      </Modal>
    </Layout.AnnotatedSection>
  )
}
