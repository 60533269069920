import ErrorPage from "views/Pages/ErrorPage.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import PricingPage from "views/Pages/PricingPage.js";
// import RegisterPage from "views/Pages/RegisterPage.js";

var authRoutes = [
  // {
  //   path: "/register",
  //   name: "Register Page",
  //   rtlName: "تسجيل",
  //   mini: "R",
  //   rtlMini: "صع",
  //   component: RegisterPage,
  //   layout: "/auth"
  // },
  {
    path: "/login",
    name: "Grocer Box",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth"
  },
  {
    path: "/error",
    name: "Error Page",
    rtlName: "صفحة الخطأ",
    mini: "E",
    rtlMini: "البريد",
    component: ErrorPage,
    layout: "/auth"
  },
  {
    path: "/lock-screen",
    name: "Lock Screen Page",
    rtlName: "اقفل الشاشة",
    mini: "LS",
    rtlMini: "هذاع",
    component: LockScreenPage,
    layout: "/auth"
  },
  {
    path: "/pricing",
    name: "Pricing Page",
    rtlName: "عالتسعير",
    mini: "PP",
    rtlMini: "ع",
    component: PricingPage,
    layout: "/auth"
  }
];
export default authRoutes;
