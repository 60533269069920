import React, { useState } from "react";
import { useAdmin } from "context/admin";
import { DataTable, Page, Layout, Card, Banner, Button } from "@shopify/polaris";
import LoadingPage from "shared/LoadingPage";
import TrackingLinkModal from "./TrackingLinkModal";
import { useHistory, useLocation } from "react-router-dom";
import PrintVendorOrders from "./PrintVendorOrders";

export default function VendorPage() {
  const admin = useAdmin();
  const { pathname } = useLocation();
  const history = useHistory();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!admin.selectedShop || !admin.orders || !admin.orders.shops[admin.selectedShopId] || (admin.orders.shops[admin.selectedShopId].length === 0 && admin.loading)) {
    return <LoadingPage />;
  }

  const handleSelect = (order) => {
    history.push(`${pathname}/${order.id}`);
  }

  const orderIds = admin.orders.shops[admin.selectedShop.id] || [];
  const orders = orderIds.map(id => admin.orders.entities[id]);
  const shippingAddress = ({ shipping_address }) => {
    if (!shipping_address) {
      return 'No shipping address found.'
    }
    return [`${shipping_address.address1} ${shipping_address.address2}`, shipping_address.city, shipping_address.province_code, shipping_address.zip].join(', ');
  }
  const customerName = (x) => x.customer ? `${x.customer.first_name || ''} ${x.customer.last_name}` : '';

  const headers = [
    { type: 'text', label: 'Order #', fn: (order) =>
      <Button size="slim" plain color="transparent" onClick={() => handleSelect(order)}>{order.name}</Button>
    },
    { type: 'text', label: 'Customer', fn: customerName },
    { type: 'text', label: 'Address', fn: shippingAddress },
    { type: 'text', label: 'Tracking', fn: (order) => {
      if (order.tags.indexOf('Vendor Tracking - Sent') === -1) {
        return <Button plain onClick={() => setSelectedOrder(order)}>Add Tracking Link</Button>
      } else {
        return 'Sent';
      }
    }},
  ];

  const onSendTrackingLink = (order, trackingLink) => {
    setLoading(true)
    admin.sendTrackingLink(order.id, trackingLink)
         .finally(() => {
           setLoading(false);
           setSelectedOrder(null);
         });
  }

  return (
    <Page data-cy="VendorPage" title="Orders">
      <TrackingLinkModal
        order={selectedOrder}
        handleSend={(link) => onSendTrackingLink(selectedOrder, link)}
        handleClose={() => setSelectedOrder(null)}
        loading={loading}
      />

      <Layout>
        <Layout.Section>
          <Banner title="Welcome" status="info">
            Customer orders will appear in the list below.<br />
            Once you have shipped the order, you can email the tracking link to the customer.
          </Banner>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <Card.Header>
              <span>{orders.length} Order{orders.length !== 1 ? 's' : ''}</span>

              <PrintVendorOrders
                orders={orders}
                trigger={() => <Button size="slim" primary>Print Orders</Button>}
              />
            </Card.Header>
            <DataTable
              columnContentTypes={headers.map(h => h.type)}
              headings={headers.map(h => h.label)}
              rows={orders.map(order => headers.map(header => header.fn(header.col ? order[header.col] : order)))}
              verticalAlign="middle"
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}