import React, { useEffect, useMemo, useState } from "react";
import { Card, DataTable } from "@shopify/polaris";
import { csvStringToArray } from "util/csv";

export default function CsvPreview({
  file,
  validate = () => true,
  invalidContent,
  limit = 0,
  title = "",
}) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (file) {
      var reader = new FileReader();
      reader.addEventListener("load", (e) => {
        // TODO: allow customer to edit uploaded CSV before creating sale items
        const saleItems = csvStringToArray(e.target.result);
        if (limit > 0) {
          setItems(saleItems.slice(0, limit));
        } else {
          setItems(saleItems);
        }
      });

      reader.readAsBinaryString(file);
    }
  }, [file, limit]);

  const headerRow = items && items.length > 0 ? items[0] : [];
  const headers = headerRow.map((item) => ({
    type: "text",
    label: item,
    fn: (x) => x,
  }));

  const valid = useMemo(() => validate(items), [items, validate]);

  const defaultTitle = `File Preview${limit ? ` (first ${limit})` : ""}`;

  return items && items.length > 0 ? (
    <Card>
      <Card.Section title={title || defaultTitle}>
        {!valid ? invalidContent : null}
        {valid ? (
          <DataTable
            columnContentTypes={headers.map((h) => h.type)}
            headings={headers.map((h) => h.label)}
            rows={items
              .slice(1)
              .map((row) => headers.map((header, index) => row[index]))}
            verticalAlign="middle"
          />
        ) : null}
      </Card.Section>
    </Card>
  ) : null;
}
