import React from "react";
import { Banner, Card, Layout } from "@shopify/polaris";
import FileUpload from "components/FileUpload/FileUpload";

export default function FileImportUpload({
  files,
  setFiles,
  status,
  saving,
  handleUpload,
}) {
  return (
    <>
      {status === "success" ? (
        <Layout.Section>
          <Banner title="Success" status="success">
            <p>Your file has been uploaded!</p>
          </Banner>
        </Layout.Section>
      ) : null}

      <Layout.AnnotatedSection
        title="Upload Product List (CSV)"
        description={
          <div>
            Upload a CSV file of product data to update your store's products.{" "}
            <br />
            <br />
            <a
              href="https://www.howtogeek.com/348960/what-is-a-csv-file-and-how-do-i-open-it/"
              target="_blank"
              rel="noopener noreferrer"
            >
              What is a CSV file?
            </a>
            <br />
            <a
              href="https://support.microsoft.com/en-us/office/import-or-export-text-txt-or-csv-files-5250ac4c-663c-47ce-937b-339e391393ba#:~:text=You%20can%20convert%20an%20Excel,or%20CSV%20(Comma%20delimited)."
              target="_blank"
              rel="noopener noreferrer"
            >
              How to convert Excel to CSV.
            </a>
          </div>
        }
      >
        <Card
          primaryFooterAction={
            files.length > 0
              ? {
                  content: "Upload File",
                  loading: saving,
                  onAction: () => handleUpload(files[0]),
                }
              : null
          }
          secondaryFooterActions={
            files.length > 0
              ? [
                  {
                    content: "Clear",
                    loading: saving,
                    onAction: () => setFiles([]),
                  },
                ]
              : null
          }
        >
          <FileUpload
            files={files}
            setFiles={setFiles}
            allowMultiple={false}
            accept="text/csv"
          />
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
}
