import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import { Card, DataTable, Layout, Page } from "@shopify/polaris";
import { orderTitle } from "util/helpers";
import styled from 'styled-components';

import OrderServiceType from "./components/OrderServiceType";
import OrderCollectionDate from "./components/OrderCollectionDate";
import { orderShippingMethod } from "util/helpers";

const ListContainer = styled.div`
  display: flex;
`
const List = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 0.5rem;
`
const ListItem = styled.li`
  font-size: 12px;
  display: inline-block;
  width: 100%;
  line-height: 16px;
`
const Note = styled.p`
  font-size: 12px;
  line-height: 16px;
`

const OrderTable = ({ order, delivery, showPrices }) => {
  const DELIVERY_NOTE_KEY = 'Delivery Note';
  const STORE_PROVIDER = 'STORE';
  const SUBSTITUTION_NOTE_KEY = 'Substitutions';
  const delivery_notes = order.note_attributes.filter(note => note.name === DELIVERY_NOTE_KEY)[0];
  const substitution_note = order.note_attributes.filter(note => note.name === SUBSTITUTION_NOTE_KEY)[0];

  // Display line item properties but filter our success/error properties
  const lineItemDetails = (item) => (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {item.name}
      {item.properties.filter(x => x.name !== 'success' && x.name !== 'error').map(p => {
        if (p.name === 'Allow Substitutions' && p.value === 'true') {
          return <span key={p.name}><br />Allow Substitutions</span>
        }
        if (p.name === 'Allow Substitutions' && p.value === 'false') {
          return <span key={p.name}><br />No Substitutions</span>
        }
        return <span key={p.name}><br />{p.name}: {p.value}</span>
      })}
    </div>
  )

  let headers = [
    { type: 'text', col: 'vendor', label: 'Vendor', fn: (x) => x },
    { type: 'text', label: 'Qty  ', fn: (x) => x.quantity },
    { type: 'text', label: 'Product', fn: (item) => lineItemDetails(item) },
    { type: 'text', col: 'sku', label: 'Sku', fn: (x) => x },
  ];
  if (showPrices) {
    headers = [
      ...headers,
      { type: 'numeric', label: 'Price', fn: (x) => x.price },
      { type: 'numeric', label: 'Total', fn: (x) => (x.price * x.quantity).toFixed(2) }
    ];
  }

  const lineItemRows = order.line_items
    .sort((itemA, itemB) => {
      // Sort by product_type then vendor

      if (itemA.product && itemB.product) {
        if (itemA.product.product_type > itemB.product.product_type) { return 1; }
        if (itemA.product.product_type < itemB.product.product_type) { return -1; }
      }

      if (itemA.vendor > itemB.vendor) { return 1; }
      if (itemA.vendor < itemB.vendor) { return -1; }
      return 0;
    })
    .filter(item => item.requires_shipping && item.fulfillable_quantity > 0 && item.name.indexOf('Refundable Credit Card Hold') === -1)
    .map(line_item => headers.map(header => header.fn(header.col ? line_item[header.col] : line_item)));

  const shippingLineItems = order.shipping_lines ? order.shipping_lines.map(shipping_item =>
    ['Shipping', ...Array(headers.length - 2).fill(''), shipping_item.price]
  ) : [];

  const taxLineItems = order.tax_lines ? order.tax_lines.map(tax_line => (
    [tax_line.title, ...Array(headers.length - 2).fill(''), tax_line.price]
  )) : [];

  let rows = lineItemRows;
  if (showPrices) {
    rows = [...lineItemRows, ...shippingLineItems, ...taxLineItems];
  }

  return (
    <Card>
      <Card.Header title={orderTitle(order)}>
        <span>Ready By: <OrderCollectionDate order={order} /></span>
        <OrderServiceType
          order={order}
          delivery={delivery}
          type={orderShippingMethod(order)}
        />
      </Card.Header>
      <Card.Section>
        <ListContainer>
          <List>
            <ListItem><strong>Billing Address</strong></ListItem>
            {order.billing_address ?
              <>
                <ListItem>{order.billing_address.name}</ListItem>
                <ListItem>{order.billing_address.address1}</ListItem>
                <ListItem>{order.billing_address.address2}</ListItem>
                <ListItem>{`${order.billing_address.city} ${order.billing_address.province_code} ${order.billing_address.zip}`}</ListItem>
                <ListItem>{order.billing_address.country}</ListItem>
              </>
            : <p>No billing address found.</p>}
          </List>

          {order.shipping_address ?
            <List>
              <ListItem><strong>Shipping Address</strong></ListItem>
              <ListItem>{order.shipping_address.name}</ListItem>
              <ListItem>{order.shipping_address.address1}</ListItem>
              <ListItem>{order.shipping_address.address2}</ListItem>
              <ListItem>{`${order.shipping_address.city} ${order.shipping_address.province_code} ${order.shipping_address.zip}`}</ListItem>
              <ListItem>{order.shipping_address.country}</ListItem>
            </List>
            : ''
          }
        </ListContainer>

        <h6><strong>Invoice</strong></h6>
        <ListContainer>
          <List>
            <ListItem>Order Number: {order.name}</ListItem>
            <ListItem>Phone: {order.billing_address ? order.billing_address.phone : 'None'}</ListItem>
            <ListItem>Email: {order.contact_email || 'None'}</ListItem>
          </List>
          <List>
            <ListItem>Order Date: {new Date(order.created_at).toUTCString()}</ListItem>
            { order.service ?
                <ListItem>
                  <span style={{ textTransform: 'capitalize'}}>{order.service.type} Date</span>: {order.service.date}
                </ListItem>
              : ''
            }
            {delivery ? <ListItem>Shipping Provider: {delivery.shipping_provider}</ListItem> : '' }
            {delivery && delivery.shipping_provider !== STORE_PROVIDER ?
              <ListItem>Shipping Order ID: {delivery.shipping_order_id}</ListItem>
              : ''
            }
          </List>
        </ListContainer>

        <DataTable
          showTotalsInFooter
          columnContentTypes={headers.map(h => h.type)}
          headings={headers.map(h => h.label)}
          rows={rows}
          totals={showPrices ? [...Array(headers.length - 1).fill(''), order.total_price] : []}
          totalsName={{
            singular: 'Total',
            plural: 'Total',
          }}
        />
      </Card.Section>
      <Card.Section>
        { <Note><b>Customer Note:</b> {order.note ? order.note : 'None'}</Note> }
        { <Note><b>Delivery Note:</b> {delivery_notes ? delivery_notes.value : 'None' }</Note> }
        { <Note><b>Substitutions:</b> {substitution_note ? substitution_note.value : 'N/A'}</Note> }
      </Card.Section>
    </Card>
  )
}


class OrdersList extends React.Component {
  render() {
    return (
      <div id="print-only">
        <Page title={this.props.title}>
          <Layout>
            <Layout.Section>
              {this.props.orders.map(order => <OrderTable key={order.id} order={order} delivery={this.props.deliveries.entities[order.id]} showPrices={this.props.showPrices} />)}
            </Layout.Section>
          </Layout>
        </Page>
      </div>
    );
  }
}

class PrintOrders extends React.Component {
  render() {
    return (
      <div>
        <style>{`
          @media print {
            .Polaris-Card {
              page-break-before: auto;
              page-break-after: always;
              page-break-inside: avoid;
              padding: 0 2rem;
            }

            .Polaris-DataTable__Cell {
              padding: 0.1rem;
              font-size: 12px;
            }
          }
        `}</style>
        <ReactToPrint
          trigger={this.props.trigger}
          content={() => this.componentRef}
        />
        <OrdersList
          title={this.props.title || 'Orders'}
          orders={this.props.orders}
          deliveries={this.props.deliveries}
          showPrices={this.props.showPrices}
          ref={el => (this.componentRef = el)}
        />
      </div>
    );
  }
}

export default PrintOrders;

PrintOrders.propTypes = {
  trigger: PropTypes.func,
  orders: PropTypes.array
}
