import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '@shopify/polaris'
import { useHistory } from 'react-router-dom'
import LoadingPage from 'shared/LoadingPage';
import { Button } from '@material-ui/core';

export default function ShopList({ loading, shops }) {
  const history = useHistory();

  if (loading) {
    return <LoadingPage />;
  }

  return (
    shops ? shops.map(shop =>
      <Card
        sectioned
        data-cy="StoreCard"
        key={shop.id}
        title={shop.current.name}
      >
        <p>Customize Shop Settings, view orders and deliveries</p>
        <Button data-cy="goToStoreBtn" onClick={() => history.push(`/shops/${shop.id}`)}>Go To Store</Button>
      </Card>
    ) : ''
  )
}

ShopList.propTypes = {
  shops: PropTypes.array,
  loading: PropTypes.bool
}
