import React, { useState } from 'react'
import { Banner, FormLayout, Modal, Select } from '@shopify/polaris';
import { getMonth, getWeekday } from 'util/helpers';
import { STORE_PROVIDER } from 'shared/constants';

export default function ScheduleDeliveryModal({ order, saving, serviceWindows, handleClose, handleSave, carrier }) {
  const [selected, setSelected] = useState();
  const [provider, setProvider] = useState(carrier ? carrier : STORE_PROVIDER);

  const options = [
    { label: 'Select a time slot', value: null },
    ...serviceWindows.map(window => {
      const split = window.date.split('-');
      const date = new Date(+split[0], +split[1] - 1, +split[2]);
      const month = getMonth(date.getMonth());
      const weekday = getWeekday(date.getDay());
      return {label: `${weekday} ${month} ${date.getDate()} - ${window.name}`, value: window.code};
    })
  ];

  const providerOptions = [{ label: 'Store Delivery', value: STORE_PROVIDER }];
  if (carrier) {
    providerOptions.push({ label: carrier, value: carrier });
  }

  const handleSelectChange = (code) => {
    setSelected(serviceWindows.filter(w => w.code === code)[0]);
  }
  const handleSelectProviderChange = (value) => {
    setProvider(value);
  }

  return (
    <Modal
      title="Schedule Delivery"
      open={!!order}
      onClose={() => handleClose(false)}
      secondaryActions={[{ content: 'Cancel', onAction: () => handleClose(false) }]}
      primaryAction={{content: 'Schedule Delivery', loading: saving, onAction: () => handleSave(order, selected, provider), disabled: saving || !selected || !provider }}>
      <Modal.Section>
        {provider && provider !== STORE_PROVIDER ?
          <Banner>
            This will schedule a delivery with {provider}. Charges may apply.
          </Banner>
        : ''}

        <FormLayout>
          <Select
            label="Select Provider"
            options={providerOptions}
            onChange={handleSelectProviderChange}
            value={provider || ''}
          />
          <Select
            label="Select Time Slot"
            options={options}
            onChange={handleSelectChange}
            value={selected && selected.code}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  )
}
