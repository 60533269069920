import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import { Card, DataTable } from "@shopify/polaris";
import { orderNote } from "util/helpers";
import { DELIVERY_NOTE_KEY } from "shared/constants";

class DeliveriesList extends React.Component {
  render() {
    const rows = this.props.deliveries;

    const headers = [
      { type: 'text', label: 'Order #', fn: (d) => {
        const order = this.props.orderEntities[d.shopify_order_id];
        return order && order.name;
      }},
      { type: 'text', label: 'Ready By', fn: (delivery) => {
        const readyBy = new Date(delivery.data.ready_by);
        return readyBy.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }},
      { type: 'text', label: 'Name', fn: (d) => d.receiver ? d.receiver.name : '' },
      { type: 'text', label: 'Address', fn: (d) =>
        d.destination ? [`${d.destination.address1} ${d.destination.address2}`, d.destination.city, d.destination.zip].join(', ') : ''
      },
      { type: 'text', label: 'Phone', fn: (d) => d.destination ? d.destination.phone : '' },
      { type: 'text', label: 'Notes', fn: (d) => {
        const order = this.props.orderEntities[d.shopify_order_id];
        return (order && orderNote(order, DELIVERY_NOTE_KEY)) || '';
      }},
    ];

    return (
      <div id="print-only">
        <Card title={this.props.title}>
          <DataTable
            columnContentTypes={headers.map(h => h.type)}
            headings={headers.map(h => h.label)}
            rows={rows.map(row => headers.map(header => header.fn(header.col ? row[header.col] : row)))}
          />
        </Card>
      </div>
    )
  }
}

class PrintDeliveries extends React.Component {
  render() {
    return (
      <div>
        <style>{`
          @media print {
            .Polaris-Card {
              page-break-before: auto;
              page-break-after: always;
              page-break-inside: avoid;
              padding: 0 2rem;
            }

            .Polaris-DataTable__Cell {
              padding: 0.75rem 0.5rem;
              white-space: pre-wrap;
              font-size: 1rem;
              max-width: 25rem;
              border-bottom: 1px solid;
            }
          }
        `}</style>
        <ReactToPrint
          trigger={this.props.trigger}
          content={() => this.componentRef}
        />
        <DeliveriesList
          title={this.props.title || 'Deliveries'}
          deliveries={this.props.deliveries}
          orderEntities={this.props.orderEntities}
          ref={el => (this.componentRef = el)}
        />
      </div>
    );
  }
}

export default PrintDeliveries;

PrintDeliveries.propTypes = {
  trigger: PropTypes.func,
  deliveries: PropTypes.array
}
