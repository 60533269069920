import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { AppProvider, Frame, Toast } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';

// import { AdminProvider } from "context/admin";
import { useUser } from "context/user";
import { useAdmin } from "context/admin";

import DashboardSidebar from "./DashboardSidebar";
import DashboardTopBar from "./DashboardTopBar";
// import LoadingPage from "shared/LoadingPage";
import AdminPages from "views/AdminPages/AdminPages";
import ComingSoonPage from "views/Pages/ComingSoonPage";
import ErrorPage from "views/Pages/ErrorPage";
import ShopsPage from "views/ShopsPage/ShopsPage";
import ConfirmModal from './ConfirmModal';

export default function Dashboard() {
  const { toast, setToast, confirmation, setConfirmation } = useAdmin();
  const user = useUser();

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = () => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);

  const toastMarkup = toast ? (
    <Toast content={toast} onDismiss={() => setToast()} />
  ) : null;

  const confirmationMarkup = confirmation ?
    <ConfirmModal
      open={true}
      handleClose={() => setConfirmation(null)}
      {...confirmation}
    /> : null;


  return (
    <AppProvider
      i18n={translations}>
      <Frame
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        navigation={<DashboardSidebar />}>
        <DashboardTopBar handleMobileNavigation={toggleMobileNavigationActive}/>

        <Switch>
          { user && user.isAdmin ? <Route path="/admin" component={AdminPages}  /> : '' }
          <Route exact path="/coming-soon" component={ComingSoonPage} />

          {/* Shop Routes */}
          <Route path="/shops" component={ShopsPage} />

          <Redirect to="/shops" />
          <Route component={ErrorPage} />

        </Switch>
        {toastMarkup}
        {confirmationMarkup}
      </Frame>
    </AppProvider>
  );
}
