import React, { useState } from 'react'

import { useAdmin } from 'context/admin';
import { Button, Layout, Card, Form, FormLayout, TextField } from '@shopify/polaris';

const newShop = {
  shopify_domain: '',
  api_key: '',
  password: '',
  shared_secret: '',
}

export default function RegisterShopPage() {
  const admin = useAdmin();
  const [url, setUrl] = useState('');
  const [form, setForm] = useState(newShop);

  const isValidUrl = (url) => {
    return url.indexOf('https://') > -1 && url.indexOf('@') && url.indexOf(':');
  }

  const handleChange = (value) => {
    setUrl(value)
    if (!isValidUrl(value)) {
      return;
    }

    let [api_key, rest] = value.replace('https://', '').split(':');
    if (!rest) { return }

    let [password, remaining] = rest.split('@');
    if (!remaining) { return }

    const shopify_domain = remaining.split('/')[0];

    setForm({
      api_key,
      password,
      shopify_domain,
    })
  }

  const valid = (form.api_key && form.password && form.shopify_domain) ? true : false;

  const handleSubmit = (e) => {
    e.preventDefault();
    admin.registerShop(form)
  }

  return (
    <Layout.AnnotatedSection
      title="Register Shop"
      description="Register a private app using an authenticated url of the format: https://{apikey}:{password}@{hostname}"
    >
      <Card sectioned>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              label="Private App Authenticated Url"
              error={admin.registerError ? 'Authenticated Url was not valid. Check private app url and try again' : ''}
              value={url}
              onChange={(val) => handleChange(val)}
              helpText={url && !valid ? <span>Url is not valid</span> : ''}
            />

            <Button loading={admin.saving} disabled={!valid} submit>Register</Button>
          </FormLayout>
        </Form>
      </Card>
    </Layout.AnnotatedSection>
  )
}
