import React from 'react'
import { Modal } from '@material-ui/core';

import styled from 'styled-components';
import { Card } from '@shopify/polaris';

const ModalBody = styled.div`
  width: 60rem;
  max-width: 95vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`
const ShippingLabel = styled.img`
  transform: rotate(180deg);
  width: 100%;
  max-width: calc(95vw - 4rem);
`

export default function ShippingLabelsModal({ open, handleClose, label }) {

  const printZpl = (zpl) => {
    var printWindow = window.open();
    printWindow.document.open('text/plain')
    printWindow.document.write(zpl);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalBody>
        {label ?
          <Card sectioned
            title="Shipping Label"
            secondaryFooterActions={[
              {content: 'Print ZPL', onAction: () => printZpl(label.zpl)}
            ]}
            primaryFooterAction={{content: 'Print PDF', onAction: () => window.open(label.pdf_url)}}>
            <ShippingLabel src={`data:image/png;base64, ${label.png}`} />
          </Card>
        : 'Loading...'}
      </ModalBody>
    </Modal>
  )
}
