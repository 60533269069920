import React, { useState } from 'react'
import { Banner, FormLayout, Modal, Select } from '@shopify/polaris';
import { getMonth, getWeekday } from 'util/helpers';

export default function SelectTimeSlotModal({ order, saving, serviceWindows, handleClose, handleSave, content, title, saveText = 'Save' }) {
  const [selected, setSelected] = useState();

  const options = [
    { label: 'Select a time slot', value: null },
    ...serviceWindows.map(window => {
      const split = window.date.split('-');
      const date = new Date(+split[0], +split[1] - 1, +split[2]);
      const month = getMonth(date.getMonth());
      const weekday = getWeekday(date.getDay());
      return {label: `${weekday} ${month} ${date.getDate()} - ${window.name}`, value: window.code};
    })
  ];

  const handleSelectChange = (code) => {
    setSelected(serviceWindows.filter(w => w.code === code)[0]);
  }

  return (
    <Modal
      title={title}
      open={!!order}
      onClose={() => handleClose(false)}
      secondaryActions={[{ content: 'Cancel', onAction: () => handleClose(false) }]}
      primaryAction={{content: saveText, loading: saving, onAction: () => handleSave(order, selected), disabled: saving || !selected }}>
      <Modal.Section>
        <Banner>
          {content}
        </Banner>

        <FormLayout>
          <Select
            label="Select Time Slot"
            options={options}
            onChange={handleSelectChange}
            value={selected && selected.code}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  )
}
