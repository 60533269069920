import React, { useState } from "react";

import styled from 'styled-components';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button, InputAdornment } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useAuth } from "context/auth";
import config from 'config';

const useStyles = makeStyles(styles);

const Logo = styled.img`
  height: auto;
  width: 11rem;
  margin: 2rem auto;
`

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [logo] = useState(require("assets/img/logo_red_vertical.png"));

  const auth = useAuth();

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    auth.login(email, password);
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <Logo src={logo} />
              { auth.error ?
                <Alert severity="error">
                  The username and password combination is invalid
                .</Alert> : ''}
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  error={Boolean(auth.error)}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: 'email',
                    minLength: 3,
                    value: email,
                    onChange: (e) => setEmail(e.target.value)
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                    required: true
                  }}
                  error={Boolean(auth.error)}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    value: password,
                    onChange: (e) => setPassword(e.target.value)
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button disabled={auth.loading} type="submit" variant="outlined" color="primary">
                  Sign In
                </Button>
              </CardFooter>

              <p style={{ textAlign: 'center' }}><a href={`${config.apiBaseUri}/passwords/new`}>Forgot password.</a></p>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
