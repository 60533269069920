import React from 'react'
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogActions, DialogContent, makeStyles,
  List, ListItem, ListItemText, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

import Button from "components/CustomButtons/Button.js";
import { orderNote } from 'util/helpers';

const packingSlipStyles = {
  row: {
    display: 'flex'
  },
  wrapper: {
    maxWidth: '60rem',
    maxHeight: '80rem',
    padding: '2rem',
    margin: 'auto',
  },
  bare: {
  },
  printHide: {}
}

const useStyles = makeStyles(packingSlipStyles);

export default function PackingSlipModal({ order, active, handleClose }) {
  const classes = useStyles();

  if (!order) {
    return '';
  }

  const { line_items, billing_address, shipping_address } = order;

  return (
    <Dialog
      open={active}
      keepMounted
      fullScreen={true}
      onClose={handleClose}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
      className={classes.wrapper}
    >
      <style>
        {`@media print {
          .${classes.printHide} { display: none; }
          .${classes.wrapper} { padding: 0; }
          .${classes.wrapper} * {
            font-size: 12px !important;
            margin: 0 !important;
            padding: 0 !important;
          }
        }`}
      </style>
      <DialogTitle id="classic-modal-slide-title">
        {order.name}
        <br />
        <br />
      </DialogTitle>
      <DialogContent id="classic-modal-slide-description">
        <div className={classes.row}>
          {/* Billing Address */}

          <List dense style={{flex: 1}}>
            <h4>Delivery Notes</h4>
            <ListItem className={classes.bare} disableGutters>
              <ListItemText primary="Order Number" secondary={order.name}/>
            </ListItem>
            <ListItem className={classes.bare} disableGutters>
              <ListItemText primary="Order Date" secondary={new Date(order.created_at).toUTCString()}/>
            </ListItem>
            <ListItem className={classes.bare} disableGutters>
              <ListItemText primary="Email" secondary={order.customer ? order.customer.email : 'None'}/>
            </ListItem>
            <ListItem className={classes.bare} disableGutters>
              <ListItemText primary="Phone" secondary={order.customer ? order.customer.phone : 'None'}/>
            </ListItem>
            {/* todo: get time slot from note_attributes */}
            { order.shipping_lines ?
              <ListItem className={classes.bare} disableGutters>
                <ListItemText primary="Time Slot" secondary={order.service ? `${order.service.date} ${orderNote(order, 'window')}` : ''}/>
              </ListItem>
              : ''
            }
          </List>

          <List dense style={{flex: 1}}>
            <h4>Billing Address</h4>
            <ListItem className={classes.bare} disableGutters><ListItemText primary={billing_address.name} /></ListItem>
            <ListItem className={classes.bare} disableGutters><ListItemText primary={billing_address.address1} /></ListItem>
            <ListItem className={classes.bare} disableGutters><ListItemText primary={billing_address.address2} /></ListItem>
            <ListItem className={classes.bare} disableGutters><ListItemText primary={`${billing_address.city} ${billing_address.province_code} ${billing_address.zip}`} /></ListItem>
            <ListItem className={classes.bare} disableGutters><ListItemText primary={billing_address.country} /></ListItem>
          </List>

          { shipping_address ?
            <List dense style={{flex: 1}}>
              <h4>Shipping Address</h4>
              <ListItem className={classes.bare} disableGutters><ListItemText primary={shipping_address.name} /></ListItem>
              <ListItem className={classes.bare} disableGutters><ListItemText primary={shipping_address.address1} /></ListItem>
              <ListItem className={classes.bare} disableGutters><ListItemText primary={shipping_address.address2} /></ListItem>
              <ListItem className={classes.bare} disableGutters><ListItemText primary={`${shipping_address.city} ${shipping_address.province_code} ${shipping_address.zip}`} /></ListItem>
              <ListItem className={classes.bare} disableGutters><ListItemText primary={shipping_address.country} /></ListItem>
            </List>
            : ''
          }
        </div>
        <br />
        <hr />
        <br />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {line_items.filter(item => item.fulfillable_quantity > 0).map(item =>
              <TableRow className={classes.bare} key={item.id}>
                <TableCell className={classes.bare}>{item.name}</TableCell>
                <TableCell className={classes.bare}>{item.fulfillable_quantity}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions className={classes.printHide}>
        <Button color="success" onClick={window.print}>Print</Button>
        <Button
          onClick={handleClose}
          color="rose"
          simple
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PackingSlipModal.propTypes = {
  order: PropTypes.object,
  active: PropTypes.bool,
  handleClose: PropTypes.func
}