import React, { useEffect } from 'react'

import { Layout, ResourceItem, ResourceList, Card, TextStyle, Page } from '@shopify/polaris';
import { useAuth } from 'context/auth';
import LoadingPage from 'shared/LoadingPage';

export default function UsersPage() {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.loading && !auth.users) {
      auth.getUsers();
    }
  }, [auth]);

  if (!auth.users) {
    return <LoadingPage/>;
  }

  return (
    <Page
      title="Manage Users"
      // primaryAction={{ content: 'Add User', onAction: () => history.push({ pathname: `./users/new` })}}
    >
      <Layout>
        <Layout.AnnotatedSection
          title="Users"
          description=""
        >
          <Card>
            <ResourceList
              resourceName={{singular: 'customer', plural: 'customers'}}
              items={auth.users}
              renderItem={(item) => {
                const {id, name, email} = item;

                return (
                  <ResourceItem
                    id={id}
                  >
                    <h3>
                      <TextStyle variation="strong">{name}</TextStyle>
                    </h3>
                    <div>{email}</div>
                  </ResourceItem>
                );
              }}
            />
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  )
}
