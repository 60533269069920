import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, ChoiceList, DataTable, Filters, TextStyle } from '@shopify/polaris';
import { useAdmin } from 'context/admin';
import useLocalStorage from 'hooks/useLocalStorage';
import { debounce } from 'underscore';

export default function LineItemList({ orders, products, loading, tab }) {
  const admin = useAdmin();
  const actualKey = `actualPrices-${admin.selectedShopId}`;
  const [actual] = useLocalStorage(actualKey, {});
  // const [productTypes, setProductTypes] = useLocalStorage(`lineItemProductTypes-${admin.selectedShopId}-${tab}`, []);
  const [productTypes, setProductTypes] = useState([]);
  const [rows, setRows] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [productType, setProductType] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const [results, setResults] = useState([]);

  const handleProductTypeChange = useCallback((value) => {
    if (value.length === 0) { value = null }
    setProductType(value);
  }, []);
  const handleFiltersQueryChange = useCallback((value) => setQueryValue(value), []);
  const handleProductTypeRemove = useCallback(() => setProductType(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleProductTypeRemove();
    handleQueryValueRemove();
  }, [
    handleProductTypeRemove,
    handleQueryValueRemove,
  ]);

  useEffect(() => {
    if (rows.length > 0 && productTypes.length === 0) {
      const uniqueTypes = rows.reduce((acc, item) => {
        if (item.product && item.product.product_type && acc.indexOf(item.product.product_type) === -1) {
          return [...acc, item.product.product_type];
        }
        return acc;
      }, []).sort().map(type => ({ label: type, value: type }));
      setProductTypes(uniqueTypes);
    }
  }, [rows, productTypes]);

  useEffect(() => {
    const result = orders.map(order =>
      order.line_items.map(item => (
        {
          id: item.id,
          order_name: order.name,
          data: item,
          product: products ? products.entities[item.product_id] : null
        }
      ))
    ).reduce((acc, val) => acc.concat(val), [])
      .filter(x => x.product && x.product.title.toLowerCase().indexOf('refundable') === -1 && x.product.title.toLowerCase().indexOf('hold') === -1)
      .sort(sortItems);
    setRows(result);
  }, [orders, products]);

  useEffect(() => {
    if (productType) {
      const key = 'productType';
      setAppliedFilters([{
        key,
        label: `Product Type: ${productType}`,
        onRemove: handleProductTypeRemove,
      }]);
    } else {
      setAppliedFilters([]);
    }
  }, [productType, handleProductTypeRemove]);

  const filters = [
    {
      key: 'productType',
      label: 'Product Type',
      filter: (
        <ChoiceList
          title="Product Type"
          titleHidden
          choices={productTypes}
          selected={productType || []}
          onChange={handleProductTypeChange}
          allowMultiple
        />
      ),
      shortcut: true,
    }
  ];

  const debounced = useRef(debounce((query, itemsToSearch) => {
    if (itemsToSearch) {
      const r = itemsToSearch.filter(item => item.data.name.toLowerCase().indexOf(`${query}`.toLowerCase()) !== -1);
      setResults(r);
    }
  }, 300));

  useEffect(() => {
    if (rows.length === 0) {
      return;
    }
    if (!queryValue && appliedFilters.length === 0) {
      setResults(rows);
      return;
    }
    let filtered;
    if (appliedFilters.length > 0) {
      filtered = rows.filter(row => {
        let passes = true;
        appliedFilters.forEach(filter => {
          if (filter.key === 'productType') {
            const values = filter.label.split('Product Type: ')[1].split(',');
            if (values.indexOf(row.product.product_type) === -1) {
              passes = false;
            }
          }
        });
        return passes;
      });
    }

    if (queryValue && queryValue.length > 2) {
      debounced.current(queryValue, (filtered || rows))
    } else if (filtered) {
      setResults(filtered);
    }
  }, [rows, queryValue, appliedFilters]);

  useEffect(() => {
    if (actual) {
      localStorage.setItem(actualKey, JSON.stringify(actual));
    }
  }, [actualKey, actual]);

  if (loading) {
    return <div>Loading...</div>
  }

  const sortItems = (a, b) => {
    if (!a.product || !b.product) {
      return 0;
    }

    let type1 = a.product.product_type.toLowerCase();
    let type2 = b.product.product_type.toLowerCase();

    let tags1 = a.product.tags.toLowerCase();
    let tags2 = b.product.tags.toLowerCase();

    let vendor1 = a.product.vendor.toLowerCase();
    let vendor2 = b.product.vendor.toLowerCase();

    let name1 = a.data.name.toLowerCase();
    let name2 = b.data.name.toLowerCase();

    if (type1 < type2) return -1;
    if (type1 > type2) return 1;
    if (tags1 < tags2) return -1;
    if (tags1 > tags2) return 1;
    if (vendor1 < vendor2) return -1;
    if (vendor1 > vendor2) return 1;
    if (name1 < name2) return -1;
    if (name1 > name2) return 1;

    return 0;
  }

  const headers = [
    { type: 'text', label: 'Order #', fn: (row) => row.order_name },
    { type: 'text', label: 'Item', fn: (row) => {
      return (
        <div>
          <TextStyle variation="subdued">{row.product ? row.product.product_type : 'no type'}</TextStyle><br />
          <TextStyle variation="strong">{row.data.name}</TextStyle><br />
          <TextStyle variation="subdued">{row.data.vendor}</TextStyle>
        </div>
      )
    }},
    { type: 'text', label: 'Qty', fn: (row) => row.data.fulfillable_quantity },
    { type: 'text', label: 'Price', fn: (row) => row.data.price },
    { type: 'text', label: 'Discount', fn: (row) => row.data.total_discount },
    { type: 'text', label: 'Total', fn: (row) => `${(+row.data.price * +row.data.fulfillable_quantity - +row.data.total_discount).toFixed(2)}` },
    // { type: 'text', label: 'Actual Price', fn: (row) => (
    //   <TextField
    //     type="number"
    //     min="0"
    //     value={`${actual[row.data.id]}` || ''}
    //     onChange={(val) => setActual(state => ({ ...state, [row.data.id]: +val })) }
    //   />
    // )},
  ];

  return (
    <React.Fragment>
      <Card.Header>
        <span>{results.length} Line Item{results.length !== 1 ? 's' : ''}</span>
      </Card.Header>
      <Card.Section>
        <Filters
          queryValue={queryValue}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleQueryValueRemove}
          onClearAll={handleFiltersClearAll}
        />

        <DataTable
          columnContentTypes={headers.map(h => h.type)}
          headings={headers.map(h => h.label)}
          sortable={headers.map(col => col.sortable ? true : false)}
          rows={results.map(order => headers.map(header => header.fn(header.col ? order[header.col] : order)))}
        />
      </Card.Section>
    </React.Fragment>
  )
}
