import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Card, DataTable, Layout, TextField } from '@shopify/polaris';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';

import { Checkbox } from '@material-ui/core';
// import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import ModalEditWindow from './ModalEditWindow';
import { deepEqual } from '../../util/helpers';

export default function WeeklySchedule({ schedule, handleScheduleChange, saving }) {
  const [form, setForm] = useState(schedule);
  const [editingWindow, setEditingWindow] = useState(false);

  useEffect(() => {
    setForm(schedule);
  }, [schedule]);

  const handleChange = (wkday, val) => {
    setForm({
      ...form,
      [wkday]: {
        ...form[wkday],
        ...val,
      },
    })
  }

  const handleToggleDayClosed = (wkday) => {
    handleChange(wkday, { closed: !form[wkday].closed });
  }

  const handleSave = () => {
    handleScheduleChange({...form});
    setEditingWindow(false);
  }

  // Set the limit on every window to be the same
  const handleChangeLimit = (wkday, limit) => {
    handleChange(wkday, { windows: form[wkday].windows.map(w => ({...w, limit }))})
  }

  const handleChangeWindow = (wkday, window) => {
    handleChange(wkday, {
      windows: form[wkday].windows.map(w => w.code === window.code ? window : w),
    });
  }

  const headings = ['Open', 'Day', 'Windows', 'Limit Per Window']

  const rows = Object.keys(form).map(wkday => {
    const day = form[wkday];

    return [
      <Checkbox
        disabled={saving}
        checked={day.closed}
        tabIndex={-1}
        checkedIcon={<CloseIcon />}
        icon={<CheckIcon  />}
        onClick={() => handleToggleDayClosed(wkday)}
      />,
      day.label,
      day.closed ? 'Shop is closed.' :
      <>
        {day.windows.map(window =>
          <span key={window.code}>
            <Checkbox
              disabled={saving}
              checked={window.active}
              onChange={() => handleChangeWindow(wkday, { ...window, active: !window.active })}
              name={day.label}
              color="primary"
            />
            <span onClick={() => setEditingWindow({ date: wkday, day: form[wkday], window })}>{' ' + window.name}</span>
          </span>
        )}
      </>,
      <TextField
        type="number"
        step={5}
        min={0}
        value={`${day.windows[0].limit}`}
        onChange={(val) => handleChangeLimit(wkday, +val)}
      />
    ]
  });

  const disabled = saving || deepEqual(form, schedule);

  return (
    <Layout.AnnotatedSection
      title="Weekly Schedule"
      description="Customize store pick up and delivery windows. Close store on certain days or disable specific windows to suit your needs.">
      <Card primaryFooterAction={{content: 'Save', loading: saving, onAction: handleSave, disabled }}>
        <DataTable
          columnContentTypes={['text', 'text', 'text']}
          headings={headings}
          rows={rows}
        />
      </Card>

      <ModalEditWindow
        open={!!editingWindow}
        editingWindow={editingWindow}
        setEditingWindow={setEditingWindow}
        saving={saving}
        onClose={() => setEditingWindow(false)}
        onSave={() => {
          handleChangeWindow(editingWindow.date, { ...editingWindow.window });
          setEditingWindow(false);
        }}
      />
    </Layout.AnnotatedSection>
  );
}

WeeklySchedule.propTypes = {
  config: PropTypes.object
}