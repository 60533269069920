import React, { useEffect, useState } from 'react'

import { Page, DataTable, Card } from '@shopify/polaris'
import { useAdmin } from 'context/admin';
import DeliveryReadyBy from 'views/DeliveriesPage/components/DeliveryReadyBy';
import { fetcher } from 'util/fetch';

export default function AdminDeliverySummaryPage() {
  const admin = useAdmin();
  const [loading, setLoading] = useState();
  const [deliveries, setDeliveries] = useState([]);

  useEffect(() => {
    // If loading is undefined then we've either started loading the summary or its done.
    if (loading !== undefined) {
      return;
    }

    setLoading(true)
    fetcher('/api/deliveries/summary')
      .then(res => res.json())
      .then(summary => setDeliveries(summary.deliveries))
      .finally(() => setLoading(false))
  }, [admin, loading]);

  const headers = [
    { type: 'text', label: 'provider', fn: (d) => d.shipping_provider },
    { type: 'text', label: 'Shop', fn: (d) => d.shop_name },
    { type: 'text', label: 'Destination', fn: (d) => d.data && (d.data.destination.street || d.data.destination.address1) },
    { type: 'text', label: 'Ready By', fn: (delivery) => <DeliveryReadyBy delivery={delivery} /> },
    { type: 'text', label: 'Status', fn: (delivery) => {
      if (!delivery.status_url) { return }

      return <a href={delivery.status_url} target="_blank" rel="noopener noreferrer">Status</a>
    }},
  ];


  return (
    <Page title="Dashboard">
      {/* deliveries */}
      <Card>
        <DataTable
          columnContentTypes={headers.map(h => h.type)}
          headings={headers.map(h => h.label)}
          rows={deliveries.map(row => headers.map(header => header.fn(header.col ? row[header.col] : row)))}
        />
      </Card>
    </Page>
  )
}
