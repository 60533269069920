import React from "react";
import { FormLayout, Modal, Select } from "@shopify/polaris";

export default function ModalEditWindow({
  open,
  onClose,
  onSave,
  editingWindow,
  setEditingWindow,
  saving,
}) {
  const windowTimeOptions = [
    { label: "4am", value: "4am", hour: 4 },
    { label: "5am", value: "5am", hour: 5 },
    { label: "6am", value: "6am", hour: 6 },
    { label: "7am", value: "7am", hour: 7 },
    { label: "8am", value: "8am", hour: 8 },
    { label: "9am", value: "9am", hour: 9 },
    { label: "10am", value: "10am", hour: 10 },
    { label: "11am", value: "11am", hour: 11 },
    { label: "12pm", value: "12pm", hour: 12 },
    { label: "1pm", value: "1pm", hour: 13 },
    { label: "2pm", value: "2pm", hour: 14 },
    { label: "3pm", value: "3pm", hour: 15 },
    { label: "4pm", value: "4pm", hour: 16 },
    { label: "5pm", value: "5pm", hour: 17 },
    { label: "6pm", value: "6pm", hour: 18 },
    { label: "7pm", value: "7pm", hour: 19 },
    { label: "8pm", value: "8pm", hour: 20 },
    { label: "9pm", value: "9pm", hour: 21 },
    { label: "10pm", value: "10pm", hour: 22 },
    { label: "11pm", value: "11pm", hour: 23 },
    { label: "12am", value: "12am", hour: 0 },
  ];
  const timeConverter = (time) => {
    // take a 12 hour time string '2pm' and convert to 24 hour time
    let hour = 0;
    windowTimeOptions.forEach((option) => {
      if (option.value === time) {
        hour = option.hour;
      }
    });
    return hour;
  };

  return (
    <Modal
      title="Edit Window"
      open={open}
      onClose={onClose}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
      primaryAction={{
        content: "Save",
        loading: saving,
        onAction: onSave,
      }}
    >
      <Modal.Section>
        {editingWindow && editingWindow.window ? (
          <FormLayout>
            <Select
              label="From"
              options={windowTimeOptions}
              onChange={(val) =>
                setEditingWindow({
                  ...editingWindow,
                  window: {
                    ...editingWindow.window,
                    name: `${val} - ${
                      editingWindow.window.name.split(" - ")[1]
                    }`,
                    collection_time: timeConverter(val),
                  },
                })
              }
              value={editingWindow.window.name.split(" - ")[0]}
            />
            <Select
              label="To"
              options={windowTimeOptions}
              onChange={(val) =>
                setEditingWindow({
                  ...editingWindow,
                  window: {
                    ...editingWindow.window,
                    name: `${
                      editingWindow.window.name.split(" - ")[0]
                    } - ${val}`,
                    due_time: timeConverter(val),
                  },
                })
              }
              value={editingWindow.window.name.split(" - ")[1]}
            />
          </FormLayout>
        ) : null}
      </Modal.Section>
    </Modal>
  );
}
