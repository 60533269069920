import React, { useCallback, useMemo, useState } from "react";
import { DataTable, Modal, Pagination, Stack, Tabs } from "@shopify/polaris";

export default function FileImportResultModal({ open, file, handleClose }) {
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(0);

  const pageSize = 10;

  const failed = useMemo(() => file.result.failed, [file]);
  const skipped = useMemo(() => file.result.skipped, [file]);
  const updated = useMemo(() => file.result.updated, [file]);

  const tabs = useMemo(
    () => [
      {
        id: "failed",
        content: `Failed (${failed.length})`,
        accessibilityLabel: "Failed",
        panelID: "failed",
      },
      {
        id: "skipped",
        content: `Skipped (${skipped.length})`,
        accessibilityLabel: "Skipped",
        panelID: "skipped",
      },
      {
        id: "updated",
        content: `Updated (${updated.length})`,
        accessibilityLabel: "Updated",
        panelID: "updated",
      },
    ],
    [failed, skipped, updated]
  );

  const headers = [
    { type: "text", label: "sku", fn: (row) => row.sku },
    { type: "text", label: "reason", fn: (row) => row.reason },
  ];

  const data = useMemo(() => file.result[tabs[selected].id], [
    file,
    tabs,
    selected,
  ]);

  const rows = useMemo(
    () => data.slice((page - 1) * pageSize, page * pageSize + 1 - 1),
    [data, page]
  );

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  return (
    <Modal
      title={`${file.filename} Import Results`}
      open={open}
      onClose={() => handleClose()}
      primaryAction={{
        content: "Close",
        onAction: () => {
          handleClose();
        },
      }}
    >
      <Modal.Section>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {rows.length > 0 ? (
            <DataTable
              columnContentTypes={headers.map((h) => h.type)}
              headings={headers.map((h) => h.label)}
              rows={rows.map((row) => headers.map((header) => header.fn(row)))}
              verticalAlign="middle"
            />
          ) : (
            "No results found"
          )}
        </Tabs>

        {data.length > pageSize ? (
          <Stack distribution="center" alignment="center">
            <div style={{ padding: "1rem" }}>
              <Pagination
                label="Files"
                hasPrevious={page > 1}
                previousKeys={[74]}
                previousTooltip="j"
                onPrevious={() => {
                  setPage(page - 1);
                }}
                hasNext={page * pageSize < data.length}
                nextKeys={[75]}
                nextTooltip="k"
                onNext={() => {
                  setPage(page + 1);
                }}
              />
            </div>
          </Stack>
        ) : null}
      </Modal.Section>
    </Modal>
  );
}
