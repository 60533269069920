import config from "config";

export const handleErrors = function(response) {
  if (!response.ok) {
    response.json().then((res) => console.log({ error: res }));
    throw Error(response.statusText);
  }
  return response;
};

function updateOptions(options) {
  const update = { ...options };
  if (localStorage.auth_token) {
    update.headers = {
      ...update.headers,
      Authorization: `Bearer ${localStorage.auth_token}`,
    };
  }
  return update;
}

export function fetcher(url, options) {
  return fetch(
    url.startsWith("/api") ? `${config.apiBaseUri}${url}` : url,
    updateOptions(options)
  );
}

export async function postData(url = "", data = {}) {
  return fetcher(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "omit", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  }).then(handleErrors);
}

export async function putData(url = "", data = {}) {
  return fetcher(url, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "omit", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  }).then(handleErrors);
}

export function fetchData(url, successCb, failureCb, finallyCb = () => {}) {
  fetcher(url)
    .then((response) => response.json())
    .then(handleErrors)
    .then(successCb)
    .catch(failureCb)
    .finally(finallyCb);
}

export function deleteRequest(url = "") {
  return fetcher(url, {
    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "omit", // include, *same-origin, omit
  }).then(handleErrors);
}
