import React from 'react'
import { Modal } from '@shopify/polaris';

export default function ScheduleDeliveryModal({ open, handleClose, handleOk, title = 'Confirm', content = 'Are you sure?', confirmText = 'Confirm', cancelText = 'Cancel' }) {
  return (
    <Modal
      title={title}
      open={open}
      onClose={() => handleClose()}
      secondaryActions={[{ content: cancelText, onAction: () => handleClose() }]}
      primaryAction={{content: confirmText, onAction: () => {
        handleOk();
        handleClose();
      }}}>
      <Modal.Section>
        <p>{content}</p>
      </Modal.Section>
    </Modal>
  )
}
