import React, { useState } from 'react'

import { Layout, Card, SettingToggle, Badge, OptionList, Button, Modal, FormLayout, TextField, Stack, Tag, Checkbox } from '@shopify/polaris'

import { useAdmin } from 'context/admin';

export default function PickupSettings({ shop_config }) {
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState(shop_config);
  const [editingLocation, setEditingLocation] = useState(false);
  const [newCollection, setNewCollection] = useState('');

  const admin = useAdmin();

  const handleUpdate = (key, value) => {
    setSaving(key);
    admin.updateShopConfig({ [key]: value })
         .then(shopConfig => setForm(shopConfig))
         .finally(() => setSaving(false))
  }

  const changedElement = (arr1, arr2) => {
    return arr1
      .filter(x => !arr2.includes(x))
      .concat(arr2.filter(x => !arr1.includes(x)))[0];
  }

  return (
    <Layout.AnnotatedSection
      title="Pickup"
      description={<p>Grocer Box pick up option.<br /><b>Please note that this should not be used alongside Shopify's local pick up feature</b>.</p>}
    >

      <SettingToggle
        action={{
          content: form.enable_pickups ? 'Deactivate' : 'Activate',
          onAction: () => handleUpdate('enable_pickups', !form.enable_pickups),
          loading: saving === 'enable_pickups',
          destructive: form.enable_pickups
        }}
        enabled={form.enable_pickups ? true : false}
      >
        Pickups are <Badge status={form.enable_pickups ? 'success' : 'default'}>{form.enable_pickups ? 'Activated' : 'Deactivated'}</Badge>.
      </SettingToggle>

      {form.enable_pickups ?
        <Card>
          {!admin.loadingShopData && admin.selectedShop.locations?.length > 0 ?
            <OptionList
              title="Activate pick up for specific locations"
              options={
                admin.selectedShop.locations
                  .filter(location => location.active)
                  .map(location => {
                    const settings = form.location_settings[location.id.toString()];
                    const collections = settings?.pickup_collections ? settings.pickup_collections.join(', ') : null;
                    return { value: `${location.id}`, label:
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div>{location.name}</div>
                        <div>{collections ? `Collections: ${collections}`: 'All collections'}</div>
                      </div>
                    } // ids are saved as a string in the backend
                  })
              }
              selected={form.pickup_location_ids}
              allowMultiple
              onChange={(val) => {
                const selected = changedElement(form.pickup_location_ids, val);
                const location = admin.selectedShop.locations.filter(location => location.id.toString() === selected)[0];
                setEditingLocation(location);
              }}
            />
          : null}

          {admin.loadingShopData ?
            'Loading locations...'
          : null}
        </Card>
      : null}

      <Modal
        title={`Location Settings | ${editingLocation?.name}`}
        open={editingLocation}
        onClose={() => setEditingLocation(false)}
        secondaryActions={[{ content: 'Done', onAction: () => setEditingLocation(false) }]}
        // primaryAction={{content: 'Save', loading: saving, onAction: () => handleSaveRecipient(editing), disabled: saving }}>
      >
        <Modal.Section>
          {editingLocation && form.location_settings ?
            <FormLayout>
              <Checkbox
                label="Enable Pick Ups at this location"
                checked={form.pickup_location_ids.indexOf(editingLocation.id.toString()) !== -1}
                onChange={(selected) => {
                  if (selected) {
                    handleUpdate('pickup_location_ids', [...form.pickup_location_ids, editingLocation.id.toString()]);
                  } else {
                    handleUpdate('pickup_location_ids', form.pickup_location_ids.filter(id => id !== editingLocation.id.toString()));
                  }
                }}
              />


              <TextField
                label="Only allow pick ups for specific collections"
                type="string"
                helpText="If this field is empty all products will be available for pickup at this location"
                value={newCollection}
                onChange={(val) => setNewCollection(val)}
                connectedRight={
                  <Button
                    onClick={() => {
                      handleUpdate('location_settings', {
                        ...form.location_settings,
                        [editingLocation.id.toString()]: {
                          ...(form.location_settings[editingLocation.id.toString()] || {}),
                          pickup_collections: [...(form.location_settings[editingLocation.id.toString()]?.pickup_collections || []), newCollection]
                        }
                      });
                      setNewCollection('');
                    }}
                  >
                    Add
                  </Button>}
              />

              <Stack spacing="tight">
                {form.location_settings[editingLocation.id.toString()]?.pickup_collections?.map(collection =>
                  <Tag key={collection} onRemove={() => {
                    handleUpdate('location_settings', {
                        ...form.location_settings,
                        [editingLocation.id.toString()]: {
                          ...(form.location_settings[editingLocation.id.toString()] || {}),
                          pickup_collections: (form.location_settings[editingLocation.id.toString()]?.pickup_collections || []).filter(c => c !== collection)
                        }
                      });
                  }}>
                    {collection}
                  </Tag>
                )}
              </Stack>
            </FormLayout>
          : null}
        </Modal.Section>
      </Modal>
    </Layout.AnnotatedSection>
  )
}
