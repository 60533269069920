// Orders
export const orderCustomerName = (order) =>
  order.customer
    ? `${order.customer.first_name || ""} ${order.customer.last_name}`
    : "";
export const orderTitle = (order) =>
  `Order ${order.name} for ${orderCustomerName(order)}`;

export const getWeekday = (weekday) => {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return weekdays[weekday];
};

export const getMonth = (index) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return months[index];
};

export const orderShippingMethod = (order) => {
  const METHOD_KEY = "Method";
  const methodNote = order.note_attributes.filter(
    (x) => x.name === METHOD_KEY
  )[0];
  if (methodNote) {
    return methodNote.value;
  }

  return order.service ? order.service.type : "manual";
};

export const parseOrderData = (order) => {
  const DATE_KEY = "Date";
  const PICKUP_TIME_KEY = "Pickup Time";

  // let zapietOrder = order.line_items.filter(x => x.properties.map(p => p.name).indexOf('_ZapietId') !== -1).length > 0;

  // For special pickup times
  let pickupTime = order.note_attributes.filter(
    (x) => x.name === PICKUP_TIME_KEY
  )[0];
  pickupTime = pickupTime ? pickupTime.value : null;

  // Get data from service and fallback to note attributes
  const date =
    order.service.date ||
    order.note_attributes.filter((x) => x.name === DATE_KEY)[0];

  if (!date || date.value === "") {
    return {
      collectionDate: null,
      date: null,
      year: null,
      month: null,
      day: null,
      hour: null,
      pickupTime,
    };
  }

  let year, month, day;
  [year, month, day] = date.split("-");
  const hour = order.service.hour;

  let collectionDate = new Date(year, month - 1, day);
  collectionDate.setHours(hour);

  return { collectionDate, date, year, month, day, hour, pickupTime };
};

export const noteKeys = {
  pickupLocation: "Pickup Location",
};

export const orderNote = (order, name) => {
  const note = order.note_attributes.filter(
    (n) => n.name.toLowerCase() === name.toLowerCase()
  )[0];
  return note ? note.value : null;
};

export function validEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  /* eslint-disable-next-line no-unused-vars */
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

export function isObject(object) {
  return object != null && typeof object === "object";
}
