import React from "react";
import { useAuth } from "./auth";

const UserContext = React.createContext();

const UserProvider = (props) => {
  let user = useAuth().user;
  if (user && user.roles) {
    user = {
      ...user,
      isAdmin: user.roles.indexOf("admin") !== -1,
      betaAccess: user.roles.indexOf("beta") !== -1,
      isVendor: user.roles.indexOf("vendor") !== -1,
      importAccess: user.roles.indexOf("import") !== -1,
    };
  }
  return <UserContext.Provider value={user} {...props} />;
};

const useUser = () => React.useContext(UserContext);

export { UserProvider, useUser };
