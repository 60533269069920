import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, TextField, Page, ResourceItem, ResourceList, TextStyle, ContextualSaveBar, Thumbnail, Button } from '@shopify/polaris';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon, CompareArrows as CompareArrowsIcon, Email as EmailIcon, Phone as PhoneIcon } from '@material-ui/icons';
import { useAdmin } from 'context/admin';
import { useUser } from 'context/user';
import LoadingPage from 'shared/LoadingPage';
import ShippingLabelsModal from 'views/Modals/ShippingLabelsModal';
import PackingSlipModal from 'views/Modals/PackingSlipModal';
import { orderTitle } from 'util/helpers';
import { postData } from 'util/fetch';
import styled from 'styled-components';
import SearchProductsModal from './SearchProductsModal';
import PrintOrders from './PrintOrders';

const ListItem = styled.div`
  display: flex;

  @media screen and (min-width: 600px) {
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`
const changeTypes = {
  updatePrice: 'UPDATE_PRICE',
  updateQuantity: 'UPDATE_QUANTITY',
  substituteCustom: 'SUBSTITUTE_CUSTOM',
  substituteVariant: 'SUBSTITUTE_VARIANT'
}

// const changes = {
//   [line_item_id]: { type: 'UPDATE_PRICE', payload: { actual_price: '', original_price: '' } },
//   [line_item_id]: { type: 'SUBSTITUTE_CUSTOM', payload: { id: '', title: '', price: '' } },
//   [line_item_id]: { type: 'SUBSTITUTE_VARIANT', payload: { id: '', quantity: '' } }
// }

export default function OrderPage() {
  const { orderId } = useParams();
  const { orders, deliveries, selectedShop, navigateToShopify, navigateExternal, selectedShopId, updateOrder, setToast, loadingProducts } = useAdmin();

  const user = useUser();
  const userCanView = true; // user && (user.isAdmin || user.betaAccess || user.isVendor);
  const userCanEdit = false; // userCanView && !user.isVendor;

  const [selectedShippingLabel, setSelectedShippingLabel] = useState();
  const [showPackingSlipModal, setShowPackingSlipModal] = useState(false);
  const history = useHistory();

  const [changes, setChanges] = useState({});
  const [editing, setEditing] = useState({});
  const [saving, setSaving] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false)

  const order = orders.entities[orderId];
  const delivery = deliveries.entities[orderId];

  useEffect(() => {
    if (order && (!changes || !editing)) {
      setChanges(order.line_items.reduce((acc, item) => ({ ...acc, [item.id]: item.price }), {}));
      setEditing(order.line_items.reduce((acc, item) => ({ ...acc, [item.id]: false }), {}));
    }
  }, [order, changes, editing]);

  // useEffect(() => {
  //   console.log({ changes, order });
  // }, [changes, order]);

  // todo:
  // - Add save button and remove onBlur

  // Show loading indicator if order and shop are not loaded
  if(!order || !selectedShop) {
    return <LoadingPage />
  }

  let items = order ? order.line_items.sort((a, b) => a.title < b.title ? -1 : 1) : [];
  if (!user.isVendor) {
    items = items.filter(item => item.fulfillable_quantity > 0 && item.title.indexOf('Refundable') === -1);
  }


  const isDelivery = order.service && order.service === 'delivery';
  const navigateToOrder = (orderId) => {
    navigateToShopify(`admin/orders/${orderId}`);
  };

  const adminLinks = user && user.isAdmin && isDelivery ? [{
    content: 'Log Service Object',
    onAction: () => console.log({ service: order.service })
  }] : [];

  const handleSave = () => {
    setSaving(true);
    // console.log({ changes })
    postData(`/api/shops/${selectedShopId}/orders/${order.id}/shop`, { changes: changes })
      .then(res => res.json())
      .then(res => {
        updateOrder(res.order);
        setChanges({});
        setEditing({});
        setToast('Order updated!')
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  const cancelEdit = (item) => {
    setEditing({ ...editing, [item.id]: false });
    resetChange(item);
  }

  const resetChange = (item) => {
    const temp = { ...changes };
    delete temp[item.id];
    setChanges(temp);
  }

  const startEdit = (item) => {
    setEditing({ ...editing, [item.id]: true });
  }

  const updateChange = (itemId, type, payload) => {
    const change = { type, payload };
    const existing = changes[itemId] && changes[itemId].find(x => x.type === type);

    if (existing) {
      setChanges({
        ...changes,
        [itemId]: changes[itemId].map(x => x.type === type ? change : x)
      });
    } else {
      setChanges({
        ...changes,
        [itemId]: [...(changes[itemId] || []), change]
      });
    }
  }

  const changePrice = (item, original_price, actual_price) => {
    if (original_price === actual_price) {
      resetChange(item);
    } else {
      updateChange(item.id, changeTypes.updatePrice, { original_price, actual_price })
    }
  }

  const changeQuantity = (item, quantity) => {
    updateChange(item.id, changeTypes.updateQuantity, { quantity });
  }

  const substitute = (item) => {
    setOpenSearchModal(item);
  }

  const handleCloseSubstituteModal = (variant) => {
    setOpenSearchModal(false);
    if (variant) {
      const item = openSearchModal;
      updateChange(item.id, changeTypes.substituteVariant, { variant });
    }
  }

  const hasChanges = Object.keys(changes).length > 0;

  const getChangeValue = (itemId, type) => {
    const change = changes[itemId] && changes[itemId].find(x => x.type === type);
    if (!change) { return null; }

    if (type === changeTypes.updatePrice) {
      return change.payload.actual_price;
    }
    if (type === changeTypes.updateQuantity) {
      return `${change.payload.quantity}`
    }
    if (type === changeTypes.substituteVariant) {
      return change.payload.variant.title
    }
  }

  return (
    <Page
      title={orderTitle(order)}
      breadcrumbs={[{ content: 'Orders', onAction: () => history.push({ pathname: '../orders' })}]}
      subtitle={`${order.id}`}
      secondaryActions={[
        { content: 'View Order', onAction: () => navigateToOrder(order.id) },
        { content: 'Packing Slip', onAction: () => setShowPackingSlipModal(true)},
        delivery && delivery.shipping_label ? { content: 'Shipping Label', onAction: () => navigateExternal(delivery.shipping_label.replace('http://', 'https://')) } : {},
        { content: <span style={{display: 'flex', alignItems: 'center'}}><EmailIcon style={{marginRight: '1rem'}} /> {order.contact_email}</span>, onAction: () => window.open(`mailto:${order.contact_email}`, '_blank') },
        { content: order.shipping_address && order.shipping_address.phone ? <span style={{display: 'flex', alignItems: 'center'}}><PhoneIcon style={{marginRight: '1rem'}} />{order.shipping_address.phone}</span> : '', onAction: () => window.open(`tel:${order.shipping_address.phone}`, '_blank') },
        ...adminLinks
      ]}
      separator
    >
      <Card sectioned>
        <Card.Header>
          <PrintOrders
            orders={[order]}
            deliveries={deliveries}
            title={order.name}
            trigger={() => <Button size="slim" primary>Print Order</Button>}
          />
        </Card.Header>
      </Card>
      {hasChanges ?
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: () => handleSave(),
            loading: saving,
            disabled: saving,
          }}
          discardAction={{
            onAction: () => {
              setChanges({});
              setEditing({});
            },
          }}
        />
      :'' }

      <ShippingLabelsModal
        open={selectedShippingLabel ? true : false}
        label={selectedShippingLabel}
        handleClose={() => setSelectedShippingLabel(false)}
      />

      <PackingSlipModal
        order={order}
        active={showPackingSlipModal}
        handleClose={() => setShowPackingSlipModal(false)}
      />

      <SearchProductsModal
        active={openSearchModal}
        handleClose={handleCloseSubstituteModal}
        loadingProducts={loadingProducts}
      />

      {saving ? 'Saving... This may take a moment' : '' }
      {/* changes ? changes.filter(x => x.type === changeTypes.updatePrice).map(x => x.payload.actual_price - x.payload.original_price).reduce((a, b) => a + b, 0) : 'None' */ }

      {userCanView ?
        <Card>
          <ResourceList
            resourceName={{singular: 'product', plural: 'products'}}
            items={items}
            loading={saving}
            renderItem={(item) => {
              const {id, variant_id, product, name, vendor, price, total_discount, quantity, fulfillable_quantity } = item;
              const qty = user.isVendor ? quantity : fulfillable_quantity;
              const totalPrice = `${(+price * +qty - +total_discount).toFixed(2)}`;
              const subVariant = getChangeValue(id, changeTypes.substituteVariant)
              const substitution = subVariant ? <TextStyle variation="subdued">Substitute with "{subVariant}"</TextStyle> : null;
              const thumbnail = <Thumbnail source={product && product.image ? product.image.src : null} />

              return (
                <ResourceItem
                  id={id}
                  media={thumbnail}
                  onClick={(itemId) => {
                    if (userCanEdit && !editing[id]) {
                       startEdit(item, totalPrice);
                    }
                  }}
                  accessibilityLabel={`View details for ${name}`}
                >
                  <ListItem>
                    <div>
                      <TextStyle variation="subdued">{vendor}</TextStyle><br />
                      <TextStyle variation="strong">{name}</TextStyle>{editing[id] ? '' : variant_id ? ` x ${qty}` : ''}<br />
                      { substitution }
                      <div>Price: ${totalPrice}</div>
                    </div>
                    {
                      editing[id] ?
                      <div style={{display: 'flex', marginLeft: 'auto'}}>
                        <div style={{ maxWidth: '9rem', marginRight: '1rem' }}>
                          <TextField
                            type="quantity"
                            min="0"

                            value={getChangeValue(id, changeTypes.updateQuantity) || `${qty}`}
                            onChange={(val) => changeQuantity(item, parseInt(val || 0))}
                          />
                        </div>

                        <div style={{ maxWidth: '11rem', marginRight: '1rem' }}>
                          <TextField
                            prefix="$"
                            type="price"
                            min="0"
                            value={getChangeValue(id, changeTypes.updatePrice) || totalPrice}
                            onChange={(val) => changePrice(item, totalPrice, val || '0')}
                          />
                        </div>

                        <IconButton onClick={() => substitute(item)}><CompareArrowsIcon /></IconButton>
                        <IconButton onClick={() => cancelEdit(item)}><CloseIcon /></IconButton>
                      </div>
                      : ''
                    }
                  </ListItem>
                </ResourceItem>
              );
            }}
          />
        </Card>
      : ''}
    </Page>
  )
}
