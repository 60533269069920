import React, { useEffect, useState } from 'react'
import { ActionList, Card, DataTable, FormLayout, Layout, Modal, Popover, Spinner, TextContainer, TextField } from '@shopify/polaris'
import { MoreHoriz as MoreIcon } from '@material-ui/icons';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import { useAdmin } from 'context/admin';
import ModalEditWindow from './ModalEditWindow';

export default function CalendarSettings() {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingWindow, setEditingWindow] = useState(false);
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [form, setForm] = useState({});
  const admin = useAdmin();

  // TODO: Get courier from shop's carrier
  const WINDOWS = {
    default: [
      { id: 'MO', name: '10am - 2pm', limit: 0, active: true, collection_time: 10, due_time: 14 },
      { id: 'AF', name: '2pm - 5pm', limit: 0, active: true, collection_time: 14, due_time: 17 }
    ],
    SAMEDAY: [
      { id: 'AF', name: '12pm - 7pm', limit: 0, active: true, collection_time: 12, due_time: 19 }
    ],
    BoxKnight: [
      { id: 'AF', name: '4pm - 7pm', limit: 0, active: true, collection_time: 16, due_time: 19 }
    ],
    METROSPEEDY: [
      { id: 'AF', name: '12pm - 4pm', limit: 0, active: true, collection_time: 12, due_time: 16 }
    ]
  }

  const calendar = admin.selectedShop.shop_config ? admin.selectedShop.shop_config.calendar : {};
  const defaultWindows = WINDOWS[admin.selectedShop.carrier] || WINDOWS.default;

  useEffect(() => {
    setForm(calendar);
  }, [calendar])

  const handleAddDate = () => {
    const newDay = {
      date: '',
      day: {
        closed: false,
        windows: defaultWindows
      }
    };
    setEditing(newDay);
  }

  const handleChange = (key, val) => {
    setEditing({
      ...editing,
      [key]: val
    });
  }

  const handleSave = (calendar) => {
    setSaving(true);
    admin.saveCalendar(calendar)
         .finally(() => {
           setSaving(false);
           setEditing(false);
           setCalendarPopup(false);
           setEditingWindow(false);
         });
  }

  const handleRemove = (date) => {
    setSaving(true);
    delete calendar[date];
    admin.saveCalendar(calendar).finally(() => setSaving(false))
  }

  const saveDisabled = !editing || !editing.date;

  const headers = [
    { type: 'text', label: 'Open', fn: ({ date, day }) =>
      <Checkbox
        disabled={saving}
        checked={day.closed}
        tabIndex={-1}
        checkedIcon={<CloseIcon />}
        icon={<CheckIcon  />}
        onClick={() => handleSave({ ...calendar, [date]: { ...day, closed: !day.closed }})}
      />
    },
    { type: 'text', label: 'Date', fn: ({ date }) => date },
    { type: 'text', label: 'Windows', fn: ({ date, day }) => day.closed ? 'Shop is closed.' :
    <div>
      {day.windows.map(window =>
      <span key={window.id}>
        <Checkbox
          disabled={saving}
          checked={window.active}
          onChange={() => handleSave({ ...calendar, [date]: { ...day, windows: day.windows.map(w => w.id === window.id ? { ...window, active: !window.active } : w) }})}
          name={window.id}
          color="primary"
        />
        <span onClick={() => setEditingWindow({ date, day, window })}>{' ' + window.name}</span>
      </span>
      )}
    </div>
    },
    { type: 'text', label: 'Limit Per Window', fn: ({ date, day }) =>
      <TextField
        type="text"
        min={0}
        value={`${form[date] ? form[date].windows[0].limit : 0}`}
        onBlur={() => {
          handleSave(form)
        }}
        onChange={(val) => {
          if (!isNaN(val)) {
            setForm({ ...form, [date]: { ...day, windows: day.windows.map(w => ({ ...w, limit: +val })) }})
          }
        }}
      />
    },
    { type: 'numeric', col: null, label: '', fn: ({ date }) => {
      return <Popover
        active={calendarPopup === date}
        activator={saving ? <Spinner accessibilityLabel="Saving" size="small" /> : <MoreIcon style={{cursor: 'pointer'}} onClick={() => setCalendarPopup(date)} />}
        onClose={() => setCalendarPopup(false)}
      >
        <ActionList items={[
          {content: 'Delete', onAction: () => handleRemove(date), destructive: true }
        ]} />
      </Popover>
    }}
  ];

  return (
    <Layout.AnnotatedSection
      title="Calendar"
      description="Override weekly schedule on specific dates.">
      <Card>
        <Card.Header
          actions={[
            { content: 'Add', onAction: handleAddDate },
          ]}
          title="Calendar Settings"
        >
        </Card.Header>
        { Object.keys(calendar).length > 0 ?
          <DataTable
            columnContentTypes={headers.map(h => h.type)}
            headings={headers.map(h => h.label)}
            rows={Object.keys(calendar).map(date => headers.map(header => header.fn(header.col ? { date, [header.col]: calendar[date][header.col] } : { date, day: calendar[date] })))}
          />
          :
          <Card.Section>
            <TextContainer>
              Close on certain dates, override order limits and open different pickup and delivery windows.
            </TextContainer>
          </Card.Section>
        }
      </Card>

      <Modal
        title="Add Date"
        open={editing}
        onClose={() => setEditing(false)}
        secondaryActions={[{ content: 'Cancel', onAction: () => setEditing(false) }]}
        primaryAction={{content: 'Save', loading: saving, onAction: () => handleSave({ ...calendar, [editing.date]: editing.day }), disabled: saving || saveDisabled }}>
        <Modal.Section>
          <FormLayout>
            <TextField
              label="Date"
              type="date"
              min={0}
              value={`${editing.date}`}
              onChange={(val) => handleChange('date', val)}
            />
          </FormLayout>
        </Modal.Section>
      </Modal>

      <ModalEditWindow
        open={!!editingWindow}
        editingWindow={editingWindow}
        setEditingWindow={setEditingWindow}
        saving={saving}
        onClose={() => setEditingWindow(false)}
        onSave={() => {
          handleSave({ ...calendar, [editingWindow.date]: { ...editingWindow.day, windows: editingWindow.day.windows.map(w => w.id === editingWindow.window.id ? editingWindow.window : w) }})
        }}
      />
    </Layout.AnnotatedSection>
  )
}
