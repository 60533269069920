import React from "react";
import ReactDOM from "react-dom";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import AppProviders from "AppProviders";
import App from "App";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#a6d4fa",
      main: "#f73d51",
      dark: "#648dae",
    },
    // secondary: {
    //   light: "#f6a5c0",
    //   main: "#f48fb1",
    //   dark: "#aa647b",
    // },
    // error: {
    //   light: "#e57373",
    //   main: "#f44336",
    //   dark: "#d32f2f",
    // },
    // warning: {
    //   light: "#ffb74d",
    //   main: "#ff9800",
    //   dark: "#f57c00",
    // },
    // info: {
    //   light: "#64b5f6",
    //   main: "#2196f3",
    //   dark: "#1976d2",
    // },
    // success: {
    //   light: "#81c784",
    //   main: "#4caf50",
    //   dark: "#388e3c",
    // }
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <AppProviders>
      <App />
    </AppProviders>
  </MuiThemeProvider>,
  document.getElementById("root")
);
