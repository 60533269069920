import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Card, FormLayout, Layout, TextField } from "@shopify/polaris";

export default function ServiceWindowSettings({ onSave, shop_config, saving }) {
  const [form, setForm] = useState({
    lead_time: shop_config.lead_time,
    window_count: shop_config.window_count,
    sameday_limit: shop_config.sameday_limit,
  });

  useEffect(() => {
    setForm({
      lead_time: shop_config.lead_time,
      window_count: shop_config.window_count,
      sameday_limit: shop_config.sameday_limit,
    });
  }, [shop_config]);

  const handleChange = (key, val) => {
    setForm((form) => ({
      ...form,
      [key]: val,
    }));
  };

  const handleSave = (_event) => onSave(form);

  return (
    <Layout.AnnotatedSection
      title="Service Window Settings"
      description="Customize pickup and delivery window settings"
    >
      <Card
        sectioned
        primaryFooterAction={{
          content: "Save",
          loading: saving,
          onAction: handleSave,
          disabled: saving,
        }}
      >
        <FormLayout>
          {/* <TextField
            label="Pickup Fee ($)"
            type="number"
            min={0}
            value={`${form.pickup_fee / 100.0}`}
            onChange={(val) => handleChange('pickup_fee', (+val * 100))}
          /> */}

          <TextField
            label="Order lead time (hours)"
            type="number"
            min={0}
            value={`${form.lead_time}`}
            onChange={(val) => handleChange("lead_time", +val)}
          />

          <TextField
            label="Maximum number of delivery windows to show"
            type="number"
            min={1}
            max={24}
            value={`${form.window_count}`}
            onChange={(val) => handleChange("window_count", +val)}
          />

          <TextField
            label="Same day order limit (Optional)"
            type="number"
            min={0}
            value={`${form.sameday_limit || 0}`}
            helpText="If you support same day orders, you can set a limit so that same day windows are not available if they already have this number of orders scheduled."
            onChange={(val) => handleChange("sameday_limit", +val)}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}

ServiceWindowSettings.propTypes = {
  onSave: PropTypes.func,
  shop_config: PropTypes.object,
  saving: PropTypes.bool,
};
