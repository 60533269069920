import React, { useState } from 'react'

import { Layout, SettingToggle, FormLayout, TextField, Select, Badge } from '@shopify/polaris'

import { useAdmin } from 'context/admin';
import { useUser } from 'context/user';

export default function GeneralSettings({ shop_config }) {
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState(shop_config);
  const admin = useAdmin();
  const user = useUser();

  const handleUpdate = (key, value) => {
    setSaving(key);
    admin.updateShopConfig({ [key]: value })
         .then(shopConfig => setForm(shopConfig))
         .finally(() => setSaving(false))
  }

  return (
    <>
      <Layout.AnnotatedSection
        title="General Settings"
        description="Configure general shop settings."
      >
        <FormLayout>
          <SettingToggle
            action={{
              content: form.enable_delivery ? 'Deactivate' : 'Activate',
              onAction: () => handleUpdate('enable_delivery', !form.enable_delivery),
              loading: saving === 'enable_delivery',
              destructive: form.enable_delivery
            }}
            enabled={form.enable_delivery ? true : false}
          >
            Delivery is <Badge status={form.enable_delivery ? 'success' : 'default'}>{form.enable_delivery ? 'Activated' : 'Deactivated'}</Badge>.
          </SettingToggle>
        </FormLayout>

        <FormLayout>
          <TextField
            label="Logo Url"
            value={form.logo_url}
            onChange={(val) => setForm({ ...form, logo_url: val })}
            onBlur={(event) => handleUpdate('logo_url', event.target.value)}
            helpText="Url of logo"
          />
        </FormLayout>
      </Layout.AnnotatedSection>

      { user.isAdmin ?
        (
          <Layout.AnnotatedSection
            title="POS Settings"
            description="Activate and configure POS integrations."
          >
            <SettingToggle
              action={{
                content: form.enable_pos_integration ? 'Deactivate' : 'Activate',
                onAction: () => handleUpdate('enable_pos_integration', !form.enable_pos_integration),
                loading: saving === 'enable_pos_integration',
                destructive: form.enable_pos_integration
              }}
              enabled={form.enable_pos_integration ? true : false}
            >
              POS integration is <Badge status={form.enable_pos_integration ? 'success' : 'default'}>{form.enable_pos_integration ? 'Activated' : 'Deactivated'}</Badge>.
            </SettingToggle>

            {form.enable_pos_integration ? (
              <div style={{ marginTop: '1rem', marginBottom: '2rem' }}>
                <Select
                  label="Select POS"
                  options={[
                    { label: 'ECRS', value: 'ecrs' },
                    { label: 'Globe POS', value: 'globe_pos' }
                  ]}
                  onChange={(val) => handleUpdate('pos', val)}
                  value={form.pos}
                />
              </div>
            ) : ''}

            {form.pos === 'globe_pos' ?
              <FormLayout>
                <TextField
                  label="Globe POS Cashier Number"
                  value={form.globe_pos_cashier}
                  onChange={(val) => setForm({ ...form, globe_pos_cashier: val })}
                  onBlur={(event) => handleUpdate('globe_pos_cashier', event.target.value)}
                />

                <TextField
                  label="Globe POS Cashier ID"
                  value={form.globe_pos_cashier_id}
                  onChange={(val) => setForm({ ...form, globe_pos_cashier_id: val })}
                  onBlur={(event) => handleUpdate('globe_pos_cashier_id', event.target.value)}
                />

                <TextField
                  label="Globe POS Store Number"
                  value={form.globe_pos_store}
                  onChange={(val) => setForm({ ...form, globe_pos_store: val })}
                  onBlur={(event) => handleUpdate('globe_pos_store', event.target.value)}
                />

                <TextField
                  label="Globe POS Terminal Number"
                  value={form.globe_pos_terminal}
                  onChange={(val) => setForm({ ...form, globe_pos_terminal: val })}
                  onBlur={(event) => handleUpdate('globe_pos_terminal', event.target.value)}
                />
                <TextField
                  label="Globe POS Shipping PLU"
                  value={form.globe_shipping_plu}
                  onChange={(val) => setForm({ ...form, globe_shipping_plu: val })}
                  onBlur={(event) => handleUpdate('globe_shipping_plu', event.target.value)}
                />
                <TextField
                  label="Globe POS Shipping Department Number"
                  value={form.globe_shipping_dep}
                  onChange={(val) => setForm({ ...form, globe_shipping_dep: val })}
                  onBlur={(event) => handleUpdate('globe_shipping_dep', event.target.value)}
                />
                <TextField
                  label="Globe POS Shipping Sub Department Number"
                  value={form.globe_shipping_sub_dep}
                  onChange={(val) => setForm({ ...form, globe_shipping_sub_dep: val })}
                  onBlur={(event) => handleUpdate('globe_shipping_sub_dep', event.target.value)}
                />
                <TextField
                  label="Globe POS Scalable Unit"
                  value={form.globe_scalable_unit}
                  onChange={(val) => setForm({ ...form, globe_scalable_unit: val })}
                  onBlur={(event) => handleUpdate('globe_scalable_unit', event.target.value)}
                />
              </FormLayout>
            : null}
          </Layout.AnnotatedSection>
        )
        : '' }

      <Layout.AnnotatedSection
        title="Delivery Settings"
        description="Configure delivery settings."
      >
        <FormLayout>
          <TextField
            label="Delivery Notes"
            value={form.delivery_notes}
            onChange={(val) => setForm({ ...form, delivery_notes: val })}
            onBlur={(event) => handleUpdate('delivery_notes', event.target.value)}
            multiline
            helpText="Add instructions for delivery drivers."
          />
        </FormLayout>
      </Layout.AnnotatedSection>
    </>
  )
}
