import React from 'react'

import { useUser } from './context/user'
import AuthenticatedApp from 'AuthenticatedApp';
import UnauthenticatedApp from 'UnauthenticatedApp';

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "assets/scss/style.scss";

export default function App() {
  const user = useUser();

  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />
}
