import React, { useState } from 'react'

import { Page, Layout } from '@shopify/polaris'

import { useAdmin } from 'context/admin'
import LoadingPage from 'shared/LoadingPage';
// import ShopCalendar from './ShopCalendar';
import DeliverySettings from './DeliverySettings';
import GeneralSettings from './GeneralSettings';
import ServiceWindowSettings from './ServiceWindowSettings';
import TestWindows from './TestWindows';
import WeeklySchedule from './WeeklySchedule';
import AvailabilityRuleSettings from './AvailabilityRuleSettings';
import DeliveryDiscountSettings from './DeliveryDiscountSettings';
import EmailSummarySettings from './EmailSummarySettings';
import CalendarSettings from './CalendarSettings';
import CarrierSettings from './CarrierSettings';
import PickupSettings from './PickupSettings';
import AdminSettings from './AdminSettings';

export default function SettingsPage({ match }) {
  const admin = useAdmin();
  const [savingConfig, setSavingConfig] = useState(false)
  const [savingSchedule, setSavingSchedule] = useState(false)

  if (!admin.selectedShop) {
    return <LoadingPage />;
  }

  const handleScheduleChange = (schedule) => {
    setSavingSchedule(true)
    admin.saveSchedule(schedule).finally(() => setSavingSchedule(false))
  }

  const handleConfigChange = (shop_config) => {
    setSavingConfig(true)
    admin.updateShopConfig(shop_config).finally(() => setSavingConfig(false))
  }

  return (
    <Page title="Settings">
      <Layout>
        <GeneralSettings
          shop_config={admin.selectedShop.shop_config}
        />

        <PickupSettings
          shop_config={admin.selectedShop.shop_config}
        />

        <DeliverySettings />

        <CarrierSettings />

        <DeliveryDiscountSettings />

        <ServiceWindowSettings
          saving={savingConfig}
          shop_config={admin.selectedShop.shop_config}
          onSave={handleConfigChange}
        />

        <WeeklySchedule
          saving={savingSchedule}
          schedule={admin.selectedShop.shop_config.schedule}
          handleScheduleChange={handleScheduleChange}
        />

        <CalendarSettings />

        <AvailabilityRuleSettings />

        <EmailSummarySettings />

        <TestWindows shop={admin.selectedShop} />

        <AdminSettings />
      </Layout>
    </Page>
  )
}
