import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import { DataTable, Page } from "@shopify/polaris";
import styled from 'styled-components';

const PrintPage = styled(Page)`
  padding: 1rem;
  margin: 1rem;
`;

const PrintTableWrapper = styled.div`
  padding: 3rem;
`;

const LineItemSummary = ({ line_items, title }) => {
  const headers = [
    { type: 'text', col: 'total', label: 'Count', fn: (x) => x },
    { type: 'text', col: 'variant_title', title: 'Unit', fn: (x) => x },
    { type: 'text', label: 'Title', fn: (item) =>
      <div>
        {item.title}
        <p>
          {Object.keys(item.order_counts).map(orderName => `${orderName}: ${item.order_counts[orderName]}`).join('  |  ')}
        </p>
      </div>
    },
  ];

  const rows = line_items.map(line_item =>
    headers.map(header => header.fn(header.col ? line_item[header.col] : line_item)));

  return (
    <div id="print-only">
      <PrintPage title={title}>
        <PrintTableWrapper>
          <DataTable
            columnContentTypes={headers.map(h => h.type)}
            headings={headers.map(h => h.label)}
            rows={rows}
          />
        </PrintTableWrapper>
      </PrintPage>
    </div>
  );
}


class LineItemSummaryWrapper extends React.Component {
  render() {
    return <LineItemSummary title={this.props.title} line_items={this.props.line_items} />
  }
}

class PrintLineItemSummary extends React.Component {
  render() {
    return (
      <div>
        <style>{`
          @media print {
            .Polaris-Card {
              page-break-before: auto;
              page-break-after: auto;
              page-break-inside: avoid;
            }

            .Polaris-DataTable__Cell {
              padding: 0.1rem;
              font-size: 12px;
            }
          }
        `}</style>
        <ReactToPrint
          trigger={this.props.trigger}
          onBeforeGetContent={this.props.beforeGetContent}
          content={() => this.componentRef}
        />
        <LineItemSummaryWrapper
          title={this.props.title || 'Orders'}
          line_items={this.props.line_items || []}
          ref={el => (this.componentRef = el)} />
      </div>
    );
  }
}

export default PrintLineItemSummary;

PrintLineItemSummary.propTypes = {
  trigger: PropTypes.func,
  line_items: PropTypes.array
}
