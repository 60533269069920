import React, { useEffect } from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import { Page } from "@shopify/polaris";

import { useAdmin } from "context/admin";
import ShopList from "./ShopList";
import LoadingPage from "shared/LoadingPage";

import ShopPage from "views/ShopPage/ShopPage";

export default function ShopsPage({ match }) {
  const admin = useAdmin();

  useEffect(() => {
    if (!admin.loadingShops && !admin.shops) {
      admin.fetchShops();
    }
  }, [admin]);

  return (
    <Switch>
      <Route path={`${match.url}/:shopId`} component={ShopPage}  />
      <Route exact path={`${match.url}`}>
      { admin.loadingShops ? <LoadingPage /> :
        <div data-cy="ShopsPage">
          <Page>
            <ShopList loading={admin.loadingShops} shops={admin.shops}/>
          </Page>
        </div>
      }
      </Route>
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}
