import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Filters, Modal, ResourceList, TextStyle, Thumbnail } from '@shopify/polaris';
import { debounce } from 'underscore';
import { useAdmin } from 'context/admin';
import { fetcher } from 'util/fetch';

export default function SearchProductsModal({ active, handleClose, loadingProducts }) {
  const [taggedWith, setTaggedWith] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const { selectedShopId } = useAdmin();

  const debounced = useRef(debounce((query) => {
    setLoading(true);
    fetcher(`/api/shops/${selectedShopId}/products?query=${encodeURIComponent(query)}`)
      .then(res => res.json())
      .then(res => setResults(res))
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, 500));

  useEffect(() => {
    if (queryValue && queryValue.length > 2) {
      debounced.current(queryValue)
    }
  }, [queryValue]);


  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const appliedFilters = [];
  if (taggedWith) {
    const key = 'taggedWith';
    appliedFilters.push({
      key,
      label: `Tagged with: ${taggedWith}`,
      onRemove: handleTaggedWithRemove,
    });
  }

  const handleSubstitute = () => {
    const variantId = selectedItems[0];
    let variant = null;
    results.forEach(p => p.variants.forEach(v => v.id === variantId ? variant = { ...v, title: `${p.title} - ${v.title}` } : null));
    handleClose(variant);
  }

  return (
    <Modal
      instant
      open={active}
      onClose={() => handleClose(null)}
      title="Search Products"
      primaryAction={{
        content: 'Substitute',
        onAction: handleSubstitute,
        disabled: selectedItems.length !== 1
      }}
    >
      <ResourceList
        resourceName={{singular: 'product', plural: 'products'}}
        loading={loading || loadingProducts}
        selectedItems={selectedItems}
        onSelectionChange={(x) => {
          setSelectedItems(x.length > 1 ? [x[x.length - 1]] : x)
        }}
        selectable
        filterControl={
          <Filters
            queryValue={queryValue}
            filters={[]}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        }
        items={results}
        renderItem={(item) => {
          const {title, image, variants, vendor } = item;

          const media = <Thumbnail source={image && image.src} alt={title} />
          return (
            <React.Fragment>
              {variants.map((variant) => (
                <ResourceList.Item
                  id={variant.id}
                  key={variant.id}
                  media={media}
                  onClick={() => setSelectedItems([variant.id])}
                  accessibilityLabel={`View details for ${title} - ${variant.title}`}
                >
                  <TextStyle variation="subdued">{vendor}</TextStyle><br />
                  <TextStyle variation="strong">{title} - {variant.title}</TextStyle>
                  <p>${variant.price}</p>
                </ResourceList.Item>
              ))}
            </React.Fragment>
          )
        }}
      />
    </Modal>
  );
}
