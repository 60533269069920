import React, { useEffect, useState } from 'react'
import { postData, putData, deleteRequest, fetcher } from 'util/fetch';
import { useHistory } from 'react-router-dom';
// import useLocalStorage from 'hooks/useLocalStorage';

const AdminContext = React.createContext();

const AdminProvider = props => {
  const [shops, setShops] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingShops, setLoadingShops] = useState(false);
  const [loadingShopData, setLoadingShopData] = useState(false);
  const [loadingEmailPickupInstructions, setLoadingEmailPickupInstructions] = useState(false);
  const [orders, setOrders] = useState({ entities: {}, shops: {} });
  // const [products, setProducts] = useState({ entities: {}, shops: {} });
  const [deliveries, setDeliveries] = useState({ entities: {}, shops: {} });
  const [rates, setRates] = useState();
  const [saving, setSaving] = useState(false);
  const [registerError, setRegisterError] = useState();
  const [selectedShopId, setSelectedShopId] = useState();
  const [shippingLabels, setShippingLabels] = useState({});
  const [toast, setToast] = useState();
  const history = useHistory();
  const [confirmation, setConfirmation] = useState();

  const selectedShop = shops && shops.find(shop => shop.id === +selectedShopId);

  const fetchShippingLabels = async (orderId) => {
    try {
      const res = await fetcher(`/api/shops/${selectedShopId}/orders/${orderId}/shipping_labels`);
      const labels = await res.json();
      setShippingLabels({
        ...shippingLabels,
        [orderId]: labels,
      });
    } catch (error) {
      console.log({ error });
    }
  }

  const registerShop = async (shop) => {
    setSaving(true)
    try {
      const res = await postData('/api/shops', { shop });
      const newShop = await res.json();
      setShops([newShop, ...shops]);
      history.push(`/shops/${newShop.id}`)
      setRegisterError(null);
    } catch (error) {
      console.log({ error });
      setRegisterError(error);
    } finally {
      setSaving(false);
    }
  }

  const updateShop = (partial) => {
    return putData(`/api/shops/${selectedShopId}`, { shop: { ...selectedShop, ...partial} })
      .then(res => res.json())
      .then(updated => {
        setShops(shops.map(existing => existing.id === selectedShopId ? updated : existing));
        setToast('Shop updated')
        setRates();
      });
  }

  const fetchRates = async (shopId) => {
    try {
      setLoading(true)
      // todo: allow sending custom packages
      const res = await postData(`/api/shops/${shopId}/rates`, { packages: [] });
      const data = await res.json();
      setRates(data);
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!selectedShopId) {
      return;
    }

    const fetchOrdersAndDeliveries = () => {
      setLoading(true)
      Promise.all([
        fetcher(`/api/shops/${selectedShopId}/orders`).then(res => res.json()),
        fetcher(`/api/shops/${selectedShopId}/deliveries`).then(res => res.json())
      ]).then(([orders, deliveries]) => {
          setOrders((state => {
            const entities = orders.reduce((acc, d) => ({ ...acc, [d.id]: d }), state.entities)
            return {
              entities: {
                ...state.entities,
                ...entities
              },
              shops: {
                ...state.shops,
                [selectedShopId]: orders.map(r => r.id)
              }
            }
          }));

          setDeliveries((state => {
            const entities = deliveries.reduce((acc, d) => ({ ...acc, [d.shopify_order_id]: d }), state.entities)
            return {
              entities: {
                ...state.entities,
                ...entities
              },
              shops: {
                ...state.shops,
                [selectedShopId]: deliveries.map(r => r.shopify_order_id)
              }
            }
          }));
        })
        .catch(error => console.log({ error }))
        .finally(() => setLoading(false))
    };

    fetchOrdersAndDeliveries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShopId]);

  const updateOrder = (order) => {
    setOrders((state) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [order.id]: order
        }
      }
    });
  }

  // const searchProducts = async (query) => {
  //   try {
  //     setLoadingProducts(true);
  //     const res = await fetcher(`/api/shops/${selectedShop.id}/products?query=${encodeURIComponent(query)}`);
  //     return await res.json();
  //   } catch (error) {
  //     console.log({ error });
  //   } finally {
  //     setLoadingProducts(false)
  //   }
  // }


  // useEffect(() => {
  //   if (!selectedShop || loadingProducts || products.shops[selectedShop.id]) {
  //     return;
  //   }

  //   const fetchProducts = async () => {
  //     try {
  //       setLoadingProducts(true);
  //       const res = await fetcher(`/api/shops/${selectedShop.id}/products`);
  //       const response = await res.json();
  //       if (response.length > 0) {
  //         setProducts(state => {
  //           const entities = response.reduce((acc, d) => ({ ...acc, [d.product_id]: d }), state.entities)
  //           return {
  //             entities,
  //             shops: {
  //               ...state.shops,
  //               [selectedShop.id]: response.map(p => p.id)
  //             }
  //           }
  //         });
  //       }
  //     } catch (error) {
  //       console.log({ error });
  //     } finally {
  //       setLoadingProducts(false)
  //     }
  //   }

  //   fetchProducts();
  // }, [selectedShop, products, loadingProducts]);

  useEffect(() => {
    if (!selectedShop) {
      return;
    }

    const fetchShopData = async () => {
      try {
        setLoadingShopData(true);
        const res = await Promise.all([
          fetcher(`/api/shops/${selectedShop.id}/locations`).then(res => res.json()),
          fetcher(`/api/shops/${selectedShop.id}/service_windows`).then(res => res.json()),
          fetcher(`/api/shops/${selectedShop.id}/products/types`).then(res => res.json())
        ]);
        const locations = res[0];
        const { available_windows, service_windows } = res[1];
        const productTypes = res[2];

        setShops(s => s.map(shop => shop.id === selectedShop.id ? ({
          ...shop,
          locations,
          available_windows,
          service_windows,
          productTypes
        }) : shop));
      } catch (error) {
        console.log({ error });
      } finally {
        setLoadingShopData(false)
      }
    }

    if (!selectedShop.available_windows || !selectedShop.locations || !selectedShop.productTypes) {
      fetchShopData();
    }
  }, [shops, selectedShop]);

  // useEffect(() => {
  //   console.log({ products })
  // }, [products]);

  const sortShops = (shops) => {
    return shops.sort((a, b) => a.current.name.toLowerCase() < b.current.name.toLowerCase() ? -1 : 1)
  }

  const fetchShops = async () => {
    setLoadingShops(true);
    try {
      const res = await fetcher('/api/shops');
      const fetched = await res.json();
      // Sort shops alphabetically by name
      if (!fetched) {
        setToast(`There was an issue loading shops. Please wait a moment and try again.`);
        setShops([]);
        window.location.pathname = "/auth/login"
        setLoadingShops(false);
      } else {
        setShops(sortShops(fetched));
      }
    } catch (error) {
      // setToast(`Error loading shops.`);
      console.log({ error });
      window.location.pathname = "/auth/login"
      setToast(`There was an issue loading shops. Please wait a moment and try again.`);
      setShops([]);
      setLoadingShops(false);
    } finally {
      setLoadingShops(false);
    }
  };

  const configureShop = async () => {
    try {
      setLoading(true)
      const res = await fetcher(`/api/shops/${selectedShopId}/configure`);
      const fetched = await res.json();
      setShops((s) => s.map(shop => shop.id === selectedShopId ? fetched : shop));
      setToast('Webhooks and carrier registered.')
    } catch (error) {
      setToast('Error configuring webhooks and carrier. Ensure that Carrier Calculated Rates is enabled for this shop.')
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  const navigateExternal = url => window.open(url, '_blank') || window.location.replace(url);

  const navigateToShopify = (path) => {
    const url = `//${selectedShop.shopify_domain}/${path}`;
    navigateExternal(url);
  };

  const saveDeliveryRate = (deliveryRate) => {
    return postData(`/api/shops/${selectedShopId}/shop_config/delivery_rates`, { delivery_rate: deliveryRate })
      .then(res => res.json())
      .then(delivery_rates => {
        setShops(shops.map(shop => shop.id === +selectedShopId ? {
          ...shop,
          shop_config: {
            ...shop.shop_config,
            delivery_rates
          }
        }
        : shop))
        setToast('Delivery rate saved.')
      })
  }

  const deleteDeliveryRate = (deliveryRate) => {
    return deleteRequest(`/api/shops/${selectedShopId}/shop_config/delivery_rates/${encodeURIComponent(deliveryRate.name)}`)
      .then(res => res.json())
      .then(() => {
        setShops(shops.map(shop => shop.id === +selectedShopId ? {
          ...shop,
          shop_config: {
            ...shop.shop_config,
            delivery_rates: shop.shop_config.delivery_rates.filter(d => d.name !== deliveryRate.name)
          }
        }
        : shop))
        setToast('Delivery rate deleted.')
      })
  }

  const saveAvailabilityRule = (availability_rule) => {
    return postData(`/api/shops/${selectedShopId}/shop_config/availability_rules`, { availability_rule })
      .then(res => res.json())
      .then(availability_rules => {
        setShops(shops.map(shop => shop.id === +selectedShopId ? {
          ...shop,
          shop_config: {
            ...shop.shop_config,
            availability_rules
          }
        }
        : shop))
        setToast('Availability rule saved.')
      })
  }

  const deleteAvailabilityRule = (type, value) => {
    return deleteRequest(`/api/shops/${selectedShopId}/shop_config/availability_rules/${encodeURIComponent(type)}/${encodeURIComponent(value)}`)
      .then(res => res.json())
      .then((availability_rules) => {
        setShops(shops.map(shop => shop.id === +selectedShopId ? {
          ...shop,
          shop_config: {
            ...shop.shop_config,
            availability_rules
          }
        }
        : shop))
        setToast('Availability rule deleted.')
      })
  }

  const saveDeliveryDiscount = (delivery_discount) => {
    return postData(`/api/shops/${selectedShopId}/shop_config/delivery_discounts`, { delivery_discount })
      .then(res => res.json())
      .then(delivery_discounts => {
        setShops(shops.map(shop => shop.id === +selectedShopId ? {
          ...shop,
          shop_config: {
            ...shop.shop_config,
            delivery_discounts
          }
        }
        : shop))
        setToast('Delivery discount saved.')
      })
  }

  const deleteDeliveryDiscount = (name) => {
    return deleteRequest(`/api/shops/${selectedShopId}/shop_config/delivery_discounts/${encodeURIComponent(name)}`)
      .then(res => res.json())
      .then((delivery_discounts) => {
        setShops(shops.map(shop => shop.id === +selectedShopId ? {
          ...shop,
          shop_config: {
            ...shop.shop_config,
            delivery_discounts
          }
        }
        : shop))
        setToast('Delivery discount deleted.')
      })
  }

  const saveCalendar = (calendar) => {
    return postData(`/api/shops/${selectedShopId}/shop_config/calendar`, { calendar })
      .then(res => res.json())
      .then(calendar => {
        setShops(shops.map(shop => shop.id === +selectedShopId ? {
          ...shop,
          shop_config: {
            ...shop.shop_config,
            calendar
          }
        }
        : shop))
        setToast('Calendar saved.')
      })
      .catch(() => {
        setToast('Error saving calendar.')
      })
  }

  const saveSchedule = (schedule) => {
    return postData(`/api/shops/${selectedShopId}/shop_config/schedule`, { schedule })
      .then(res => res.json())
      .then(schedule => {
        setShops(shops.map(shop => shop.id === +selectedShopId ? {
          ...shop,
          shop_config: {
            ...shop.shop_config,
            schedule
          }
        }
        : shop))
        setToast('Schedule saved.')
      })
      .catch(() => {
        setToast('Error saving schedule.')
      })
  }

  const updateShopConfig = (data) => {
    return putData(`/api/shops/${selectedShopId}/shop_config`, { shop_config: data })
      .then(res => res.json())
      .then((shop_config) => {
        setShops(shops.map(shop => shop.id === +selectedShopId ? { ...shop, shop_config } : shop));
        setToast('Setting updated.');
        return shop_config;
      })
  }

  const updateOrderNotes = (orderId, note_attributes) => {
    return putData(`/api/shops/${selectedShopId}/orders/${orderId}/update_note`, { note_attributes })
      .then(res => res.json())
      .then((order) => {
        setOrders({
          ...orders,
          entities: {
            ...orders.entities,
            [orderId]: order
          }
        });
        setToast('Order notes updated.');
      });
  }

  const sendTrackingLink = (orderId, trackingLink) => {
    return postData(`/api/shops/${selectedShopId}/orders/${orderId}/vendor_tracking_link`, { tracking_link: trackingLink })
      .then(res => res.json())
      .then((order) => {
        setOrders({
          ...orders,
          entities: {
            ...orders.entities,
            [orderId]: order
          }
        });
        setToast('Tracking Link Sent.');
      })
      .catch(() => setToast('There was an error sending tracking link. Please try again.'));
  }

  const cancelDelivery = (orderId) => {
    return postData(`/api/shops/${selectedShopId}/orders/${orderId}/cancel_delivery`)
      .then(res => res.json())
      .then(({ delivery }) => {
        setDeliveries(state => ({
          ...state,
          entities: {
            ...state.entities,
            [orderId]: delivery
          }
        }));
        setToast('Delivery cancelled.');
      })
      .catch(() => {
        setToast('Failed to cancel delivery, it may have already been processed.');
      });
  }

  const scheduleDelivery = (orderId, windowCode, provider) => {
    return postData(`/api/shops/${selectedShopId}/orders/${orderId}/deliver`, { window_code: windowCode, provider: provider })
      .then(res => res.json())
      .then(({ order, delivery }) => {
        setOrders(state => ({
          ...state,
          entities: {
            ...state.entities,
            [orderId]: order
          }
        }));
        setDeliveries(state => ({
          ...state,
          entities: {
            ...state.entities,
            [orderId]: delivery
          }
        }));
        setToast('Delivery scheduled.');
      })
      .catch(() => {
        setToast('Error scheduling delivery.')
      });
  }

  const changeOrderDate = (orderId, windowCode) => {
    return postData(`/api/shops/${selectedShopId}/orders/${orderId}/change_date`, { window_code: windowCode })
      .then(res => res.json())
      .then(({ order, delivery }) => {
        setOrders(state => ({
          ...state,
          entities: {
            ...state.entities,
            [orderId]: order
          }
        }));
        setDeliveries(state => ({
          ...state,
          entities: {
            ...state.entities,
            [orderId]: delivery
          }
        }));
        setToast('Date changed.');
      })
      .catch(() => {
        setToast('Error changing order date.')
      });
  }

  const downloadFromLink = (link, filename = null) => {
    fetcher(link)
      .then(res => res.blob())
      .then(blob => {
        window.download(blob, filename);
      })
  }

  const emailPickupInstructions = (date) => {
    setLoadingEmailPickupInstructions(true);
    return postData(`/api/shops/${selectedShopId}/orders/email_pickup_instructions`, { date })
      .then(() => setToast('Email sent.'))
      .catch(() => {
        setToast('Error sending pickup instructions.')
      })
      .finally(() => setLoadingEmailPickupInstructions(false));
  }

  return <AdminContext.Provider value={{
    shops,
    setShops,
    fetchShops,
    selectedShop,
    selectedShopId,
    setSelectedShopId,
    registerShop,
    registerError,
    configureShop,
    updateShop,
    fetchRates,
    rates,
    orders,
    setOrders,
    updateOrder,
    cancelDelivery,
    scheduleDelivery,
    changeOrderDate,
    deliveries,
    updateOrderNotes,
    fetchShippingLabels,
    shippingLabels,
    navigateExternal,
    navigateToShopify,
    saving,
    loading,
    loadingShops,
    loadingShopData,
    saveDeliveryRate,
    deleteDeliveryRate,
    saveAvailabilityRule,
    deleteAvailabilityRule,
    saveDeliveryDiscount,
    deleteDeliveryDiscount,
    updateShopConfig,
    saveCalendar,
    saveSchedule,
    toast,
    setToast,
    confirmation,
    setConfirmation,
    downloadFromLink,
    sendTrackingLink,
    emailPickupInstructions,
    loadingEmailPickupInstructions
  }} {...props} />
}

const useAdmin = () => React.useContext(AdminContext)

export {AdminProvider, useAdmin}