import React, { useState } from 'react'

import styled from 'styled-components';

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { useAuth } from 'context/auth';

import { NavLink } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Menu as MenuIcon, AccountCircle as AccountCircleIcon } from '@material-ui/icons';

import useMediaQuery from '@material-ui/core/useMediaQuery';

const AppTopBar = styled(AppBar)`
  background: transparent !important;
  color: inherit !important;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.1), 0px 0px 2px 0px rgba(0,0,0,0.04), 0px 0px 4px 0px rgba(0,0,0,0.02) !important;
`

const Logo = styled.img`
  max-height: 4rem;
`

const Brand = styled(NavLink)`
  color: inherit;
  font-size: 2.5rem;
  font-weight: bold;

  &:hover {
    color: inherit;
  }
`

export default function DashboardTopBar({ handleMobileNavigation }) {
  const auth = useAuth();
  const [logo] = useState(require("assets/img/logo_red_horizontal.png"));
  const [anchorEl, setAnchorEl] = useState(null);
  const matches = useMediaQuery('(max-width: 768px)');
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    auth.logout();
    handleClose();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppTopBar position="static">
      <Toolbar>
        { matches ?
          <IconButton edge="start" onClick={handleMobileNavigation} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
        : ''}

        <Brand to="/">
          <Logo src={logo} />
        </Brand>

        {auth && (
            <div style={{marginLeft: 'auto'}}>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircleIcon style={{ fontSize: '2.5rem' }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
      </Toolbar>
    </AppTopBar>
  )
}

// import React from "react";
// // nodejs library to set properties for components
// import PropTypes from "prop-types";
// import cx from "classnames";

// // @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import Hidden from "@material-ui/core/Hidden";

// // material-ui icons
// import Menu from "@material-ui/icons/Menu";
// import MoreVert from "@material-ui/icons/MoreVert";
// import ViewList from "@material-ui/icons/ViewList";

// // core components
// import AdminNavbarLinks from "./AdminNavbarLinks";
// import Button from "components/CustomButtons/Button.js";

// import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";

// const useStyles = makeStyles(styles);

// export default function AdminNavbar(props) {
//   const classes = useStyles();
//   const { color, rtlActive, brandText } = props;
//   const appBarClasses = cx({
//     [" " + classes[color]]: color
//   });
//   const sidebarMinimize =
//     classes.sidebarMinimize +
//     " " +
//     cx({
//       [classes.sidebarMinimizeRTL]: rtlActive
//     });
//   return (
//     <AppBar className={classes.appBar + appBarClasses}>
//       <Toolbar className={classes.container}>
//         <Hidden smDown implementation="css">
//           <div className={sidebarMinimize}>
//             {props.miniActive ? (
//               <Button
//                 justIcon
//                 round
//                 color="white"
//                 onClick={props.sidebarMinimize}
//               >
//                 <ViewList className={classes.sidebarMiniIcon} />
//               </Button>
//             ) : (
//               <Button
//                 justIcon
//                 round
//                 color="white"
//                 onClick={props.sidebarMinimize}
//               >
//                 <MoreVert className={classes.sidebarMiniIcon} />
//               </Button>
//             )}
//           </div>
//         </Hidden>
//         <div className={classes.flex}>
//           {/* Here we create navbar brand, based on route name */}
//           <Button href="#" className={classes.title} color="transparent">
//             {brandText}
//           </Button>
//         </div>
//         <Hidden smDown implementation="css">
//           <AdminNavbarLinks rtlActive={rtlActive} />
//         </Hidden>
//         <Hidden mdUp implementation="css">
//           <Button
//             className={classes.appResponsive}
//             color="transparent"
//             justIcon
//             aria-label="open drawer"
//             onClick={props.handleDrawerToggle}
//           >
//             <Menu />
//           </Button>
//         </Hidden>
//       </Toolbar>
//     </AppBar>
//   );
// }

// AdminNavbar.propTypes = {
//   color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
//   rtlActive: PropTypes.bool,
//   brandText: PropTypes.string,
//   miniActive: PropTypes.bool,
//   handleDrawerToggle: PropTypes.func,
//   sidebarMinimize: PropTypes.func
// };
