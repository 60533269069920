import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import RegisterShopPage from 'views/RegisterShopPage/RegisterShopPage'
import UsersPage from "views/UsersPage/UsersPage";
import NewUserPage from 'views/UsersPage/NewUserPage';
import AdminDeliverySummaryPage from 'views/AdminDeliverySummaryPage/AdminDeliverySummaryPage';
import { useAdmin } from 'context/admin';
import AdminDashboardPage from 'views/AdminDashboardPage/AdminDashboardPage';

export default function AdminPages({ match }) {
  const admin = useAdmin();

  useEffect(() => {
    if (!admin.loadingShops && !admin.shops) {
      admin.fetchShops();
    }
  }, [admin])

  return (
    <Switch>
      <Route exact path={`${match.path}/deliveries`} component={AdminDeliverySummaryPage} />
      <Route exact path={`${match.path}/shops/register`} component={RegisterShopPage} />
      <Route exact path={`${match.path}/users/new`} component={NewUserPage} />
      <Route path={`${match.path}/users`} component={UsersPage} />
      <Route path={`${match.path}/dashboard`} component={AdminDashboardPage} />
    </Switch>
  )
}
