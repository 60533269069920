export const courier_info = {
  amazon: {
    patterns: [new RegExp(/^TB[A-D][0-9]{12}/)],
  },
  canada_post: {
    patterns: [
      new RegExp(
        /\b(^[0-9]{16}$|^[A-Z]{2}[0-9]{9}[A-Z]{2}$)\b/i
      ,)
    ],
    tracking_url:
      "https://www.canadapost.ca/trackweb/en#/search?searchFor=",
  },
  ups: {
    patterns: [
      new RegExp(
        /\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|T\d{3} ?\d{4} ?\d{3})\b/i
      ),
    ],
    tracking_url:
      "http://wwwapps.ups.com/WebTracking/processInputRequest?TypeOfInquiryNumber=T&InquiryNumber1=",
  },
  usps: {
    patterns: [
      new RegExp(
        /\b((420 ?\d{5} ?)?(91|92|93|94|95|01|03|04|70|23|13)\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4}( ?\d{2,6})?)\b/i
      ),
      new RegExp(
        /\b((M|P[A-Z]?|D[C-Z]|LK|E[A-C]|V[A-Z]|R[A-Z]|CP|CJ|LC|LJ) ?\d{3} ?\d{3} ?\d{3} ?[A-Z]?[A-Z]?)\b/i
      ),
      new RegExp(/\b(82 ?\d{3} ?\d{3} ?\d{2})\b/i),
    ],
    tracking_url: "https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=",
  },
  ontrac: {
    patterns: [new RegExp(/\b(C\d{14})\b/i)],
    tracking_url: "http://www.ontrac.com/trackres.asp?tracking_number=",
  },
  dhl: {
    patterns: [
      new RegExp(
        /\b(\d{4}[- ]?\d{4}[- ]?\d{2}|\d{3}[- ]?\d{8}|[A-Z]{3}\d{7})\b/i
      ),
    ],
    tracking_url:
      "http://www.dhl.com/content/g0/en/express/tracking.shtml?brand=DHL&AWB=",
  },
  fedex: {
    patterns: [
      new RegExp(
        /\b(((96\d\d|6\d)\d{3} ?\d{4}|96\d{2}|\d{4}) ?\d{4} ?\d{4}( ?\d{3})?)\b/i
      ),
    ],
    tracking_url:
      "http://www.fedex.com/Tracking?language=english&cntry_code=us&tracknumbers=",
  },
};

export const getCourier = (tr) =>
  Object.keys(courier_info).filter(
    (c) => courier_info[c].patterns.filter((p) => p.test(tr)).length > 0
  );

export const getCourierOne = (tr) => getCourier(tr)[0];

export const isCourier = (tr, courier) =>
  getCourier(tr).indexOf(courier.toLowerCase()) > -1;

export const getTrackingUrl = (tr, courier) => {
  let link = null;
  if (courier) {
    link = courier_info[courier.toLowerCase()].tracking_url + tr;
  } else {
    const c = getCourier(tr)[0];
    if (c && courier_info[c].tracking_url)
      link = courier_info[c].tracking_url + tr;
  }

  return link;
};

export const injectPatterns = (courier, patt) =>
  !courier || !courier_info[courier.toLowerCase()]
    ? false
    : courier_info[courier.toLowerCase()].patterns.push(new RegExp(patt));

export const isValid = (tr) => getCourier(tr).length > 0;

// Tested Against
// UPS:

// "1Z9999W99999999999": "ups",
// "1Z12345E1512345676": "ups",
// "1Z12345E0205271688": "ups",
// "1Z12345E6605272234": "ups",
// "1Z12345E0305271640": "ups",
// "1Z12345E0393657226": "ups",
// "1Z12345E1305277940": "ups",
// "1Z12345E6205277936": "ups",
// "1Z12345E1505270452": "ups",
// "1Z648616E192760718": "ups",
// "1ZWX0692YP40636269": "ups",
// T9999999999: "ups",
// FEDEX:

// "9999 9999 9999": "fedex",
// "9999 9999 9999 999": "fedex",
// "999999999999": "fedex",
// "999999999999999": "fedex",
// "661377569221": "fedex",
// "624893691092": "fedex",
// "61299995669352455464": "fedex",
// "61299995669151880177": "fedex",
// USPS:

// "9400 1000 0000 0000 0000 00": "usps",
// "9205 5000 0000 0000 0000 00": "usps",
// "9407 3000 0000 0000 0000 00": "usps",
// "9303 3000 0000 0000 0000 00": "usps",
// "82 000 000 00": "usps",
// "EC 000 000 000 US": "usps",
// "9270 1000 0000 0000 0000 00": "usps",
// "EA 000 000 000 US": "usps",
// "CP 000 000 000 US": "usps",
// "9208 8000 0000 0000 0000 00": "usps",
// "9202 1000 0000 0000 0000 00": "usps",
// "9400100000000000000000": "usps",
// "9205500000000000000000": "usps",
// "9407300000000000000000": "usps",
// "9303300000000000000000": "usps",
// "8200000000": "usps",
// EC000000000US: "usps",
// "9270100000000000000000": "usps",
// EA000000000US: "usps",
// CP000000000US: "usps",
// "9208800000000000000000": "usps",
// "9202100000000000000000": "usps",
// "92748963438592543475924253": "usps",
// ONTRAC:

// C00000000000000: "ontrac",
// C99999999999999: "ontrac",
// DHL:

// "125-12345678": "dhl",
// "125 12345678": "dhl",
// "12512345678": "dhl",
// SEA1234567: "dhl",
// LAX1234567: "dhl",