import React, { useCallback, useEffect, useState } from "react";
import { Layout, Page } from "@shopify/polaris";
import { useAdmin } from "context/admin";
import { fetcher } from "util/fetch";
import CsvPreview from "components/CsvPreview/CsvPreview";
import FileImportsTable from "./FileImportsTable";
import FileImportUpload from "./FileImportUpload";
import FileImportResultModal from "./FileImportResultModal";

export default function FileImportsPage() {
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(null);
  const [saving, setSaving] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const { selectedShopId, setToast } = useAdmin();

  const fetchFileImports = useCallback(() => {
    fetcher(`/api/shops/${selectedShopId}/file_imports`)
      .then((res) => res.json())
      .then((files) => {
        setUploadedFiles(files);
      })
      .catch((error) => {
        console.log(error);
        setToast("There was an error loading previously uploaded files.");
      });
  }, [selectedShopId, setToast]);

  useEffect(() => {
    if (selectedShopId) {
      fetchFileImports();
    }
  }, [selectedShopId, fetchFileImports]);

  const uploadFile = () => {
    setStatus(null);
    const formData = new FormData();
    formData.append("file", files[0]);

    setSaving(true);
    return fetcher(`/api/shops/${selectedShopId}/file_imports`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        setSaving(false);
        setStatus("success");
        setFiles([]);
        setUploadedFiles((files) => [res, ...files]);
      })
      .catch((error) => {
        setSaving(false);
        setToast("There was an error uploading the file");
        console.log({ error });
      });
  };

  const processFile = (file) => {
    setStatus(null);
    setProcessing(file.id);
    return fetcher(
      `/api/shops/${selectedShopId}/file_imports/${file.id}/process_file`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) {
          res.json();
        } else {
          res.json().then(({ message }) => setToast(message));
          throw new Error();
        }
      })
      .then((res) => {
        console.log(res);
        setProcessing(false);
        setUploadedFiles((files) =>
          files.map((f) => (f.id === file.id ? res : f))
        );
      })
      .catch((error) => {
        setProcessing(false);
        setToast("There was an error processing the file");
        console.log({ error });
      });
  };

  return (
    <Page data-cy="FileImport" title="File Imports">
      <Layout>
        <FileImportUpload
          files={files}
          setFiles={setFiles}
          status={status}
          saving={saving}
          handleUpload={uploadFile}
        />
        {files.length > 0 ? (
          <Layout.Section>
            <CsvPreview file={files[0]} limit={20} />
          </Layout.Section>
        ) : null}

        <Layout.Section title="Uploaded Files">
          <FileImportsTable
            processing={processing}
            data={uploadedFiles}
            handleProcess={processFile}
            handleReresh={fetchFileImports}
            handleViewResults={(file) => setSelectedFile(file)}
          />
        </Layout.Section>

        {selectedFile ? (
          <FileImportResultModal
            open={true}
            file={selectedFile}
            handleClose={() => setSelectedFile(null)}
          />
        ) : null}
      </Layout>
    </Page>
  );
}
