import React, { useCallback, useEffect, useState } from "react";

import { Card, DataTable, DatePicker, Page, Spinner } from "@shopify/polaris";
import { useAdmin } from "context/admin";
import { Chart } from "react-charts";
import { fetcher } from "util/fetch";

// TODO:
// - Add orders by date to summary
export default function AdminDashboarPage() {
  const today = new Date();
  const admin = useAdmin();
  const [loading, setLoading] = useState(undefined);
  const [summary, setSummary] = useState();
  const [formattedSales, setFormattedSales] = useState();
  const [orderCounts, setOrderCounts] = useState([]);
  const [ordersByDate, setOrdersByDate] = useState({});
  const [ordersByDateRows, setOrdersByDateRows] = useState([]);
  const [ordersByDateChartData, setOrdersByDateChartData] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDateMonthPicker, setShowDateMonthPicker] = useState(false);
  const [{ month, year }, setDate] = useState({
    month: today.getMonth(),
    year: today.getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: today,
    end: today,
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const totalOrdersHeaders = [
    { type: "text", label: "Shop", fn: (d) => d.shop },
    { type: "text", label: "Deliveries", fn: (x) => x.deliveries },
    { type: "text", label: "Pickups", fn: (x) => x.pickups },
  ];

  const ordersByDateHeaders = [
    { type: "text", label: "Shopify Domain", fn: (d) => d.shopifyDomain },
    { type: "text", label: "Orders", fn: (x) => x.data.orders },
    { type: "text", label: "Cancelled", fn: (x) => x.data.cancelled },
    { type: "text", label: "Refunded", fn: (x) => x.data.refunded },
    {
      type: "text",
      label: "Gross Sales",
      fn: (x) => `$${x.data.gross_sales.toFixed(2)}`,
    },
  ];

  useEffect(() => {
    // If loading is undefined then we've either started loading the summary or its done.
    if (loading !== undefined) {
      return;
    }

    setLoading(true);

    fetcher("/api/shops/summary")
      .then((res) => res.json())
      .then((res) => {
        setSummary(res);
      })
      .finally(() => setLoading(false));
  }, [admin, loading]);

  useEffect(() => {
    if (summary && summary.order_counts) {
      setOrderCounts(
        Object.keys(summary.order_counts).map((shop) => {
          const data = summary.order_counts[shop];
          return { shop, deliveries: data.deliveries, pickups: data.pickups };
        })
      );
    }

    if (summary && summary.orders_by_date_chart) {
      setOrdersByDateChartData(summary.orders_by_date_chart);
    }
  }, [summary]);

  useEffect(() => {
    if (summary && selectedDates.start && summary.orders_by_date) {
      const dateSummary =
        summary.orders_by_date[selectedDates.start.toLocaleDateString("en-ca")];
      setOrdersByDate(dateSummary);

      if (dateSummary) {
        const rows = Object.keys(dateSummary.stores)
          .map((shopifyDomain) => ({
            shopifyDomain,
            data: dateSummary.stores[shopifyDomain],
          }))
          .sort((a, b) => (a.data.gross_sales < b.data.gross_sales ? 1 : -1))
          .map((payload) =>
            ordersByDateHeaders.map((header) => header.fn(payload))
          );
        setOrdersByDateRows(rows);
      } else {
        setOrdersByDateRows([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary, selectedDates]);

  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
        stacked: true,
        elementType: "bar",
      },
    ],
    []
  );

  const [selectedMonth, setSelectedMonth] = useState({
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
  });

  useEffect(() => {
    console.log({ ordersByDateChartData });
    if (ordersByDateChartData && ordersByDateChartData.length > 0) {
      const formattedDate = `${selectedMonth.start.getFullYear()}-${selectedMonth.start.getMonth() +
        1}-0${selectedMonth.start.getDate()}`;
      setFormattedSales(
        ordersByDateChartData.map((item) => {
          const foundItem = item.data.find((o) => o.primary === formattedDate);
          const secondaryValue = foundItem
            ? foundItem.secondary.toFixed(2)
            : "0";
          return [item.label, secondaryValue];
        })
      );
      console.log({ formattedDate });
    }
  }, [ordersByDateChartData, selectedMonth]);

  console.log(formattedSales);

  function exportToCsv(formattedSales) {
    const headers = ["Store Name", "Total Sales"];

    const formattedDate = `${selectedMonth.start.getFullYear()}-${selectedMonth.start.getMonth() +
      1}-0${selectedMonth.start.getDate()}`;

    // Add the headers to the start of the array
    const dataWithHeaders = [headers, ...formattedSales];

    const filename = `${formattedDate}-sales.csv`;

    // Convert dataWithHeaders to CSV
    const csvContent = dataWithHeaders.map((row) => row.join(",")).join("\n");

    // Create a Blob from the CSV string
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    // This will download the file when the link is clicked
    link.click();
  }

  return (
    <Page title="Dashboard">
      <Card title="Sales Over Time" sectioned>
        {loading ? (
          <Spinner />
        ) : ordersByDateChartData ? (
          <div style={{ height: "60rem" }}>
            <Chart
              options={{
                data: ordersByDateChartData,
                primaryAxis,
                secondaryAxes,
              }}
            />
          </div>
        ) : null}
      </Card>
      <Card
        title="Orders By Date"
        sectioned
        actions={[
          {
            content: selectedDates.start.toDateString(),
            onAction: () => setShowDatePicker(!showDatePicker),
          },
        ]}
      >
        {showDatePicker ? (
          <DatePicker
            month={month}
            year={year}
            onChange={({ start, end }) => {
              setSelectedDates({ start, end });
              setShowDatePicker(false);
            }}
            onMonthChange={handleMonthChange}
            selected={selectedDates}
          />
        ) : null}

        {loading ? (
          <Spinner />
        ) : ordersByDate &&
          ordersByDate.stores &&
          selectedDates &&
          selectedDates.start ? (
          <DataTable
            columnContentTypes={ordersByDateHeaders.map((h) => h.type)}
            headings={ordersByDateHeaders.map((h) => h.label)}
            rows={ordersByDateRows}
          />
        ) : (
          `No orders found for ${selectedDates.start.toDateString()}.`
        )}
      </Card>

      <Card title="Total Orders" sectioned>
        {loading ? (
          <Spinner />
        ) : orderCounts.length > 0 ? (
          <DataTable
            columnContentTypes={totalOrdersHeaders.map((h) => h.type)}
            headings={totalOrdersHeaders.map((h) => h.label)}
            rows={orderCounts.map((delivery) =>
              totalOrdersHeaders.map((header) =>
                header.fn(header.col ? delivery[header.col] : delivery)
              )
            )}
          />
        ) : null}
      </Card>

      <Card
        title="Monthly Sales Total"
        sectioned
        actions={[
          {
            content: "Export CSV",
            onAction: () =>
              formattedSales.length > 0 ? exportToCsv(formattedSales) : null,
          },
          {
            content: selectedMonth.start.toDateString(),
            onAction: () => setShowDateMonthPicker(!showDateMonthPicker),
          },
        ]}
      >
        {showDateMonthPicker ? (
          <DatePicker
            month={month}
            year={year}
            onChange={({ start, end }) => {
              start.setDate(1);
              setSelectedMonth({ start, end });
              setShowDateMonthPicker(false);
            }}
            onMonthChange={handleMonthChange}
            selected={selectedMonth}
          />
        ) : null}

        {formattedSales ? (
          <DataTable
            columnContentTypes={["text", "text"]}
            headings={["Store", "Total Sales"]}
            rows={formattedSales}
          />
        ) : null}
      </Card>
    </Page>
  );
}
