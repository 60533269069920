import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core';
import { Warning, Store as StoreIcon, LocalShipping as LocalShippingIcon } from '@material-ui/icons';
import { STORE_PROVIDER } from 'shared/constants'
import { orderNote, noteKeys } from 'util/helpers';
import { Tooltip } from '@shopify/polaris';

const OrderType = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${props => props.color };
`
const OrderTypeLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${props => props.color };
  text-decoration: underline;
  cursor: pointer;
  &:visited, &:active, &:hover {
    color: ${props => props.color };
  }
  &:hover {
    text-decoration: underline;
  }
`

export default function OrderServiceType({ type, order, delivery, navigateExternal }) {
  const theme = useTheme();

  if (type === 'pickup') {
    const pickupLocation = orderNote(order, noteKeys.pickupLocation)
    return <OrderType>{pickupLocation ? `Pickup from ${pickupLocation}` : 'Pickup'}</OrderType>;
  }

  let deliveryColor;
  if (!delivery) {
    deliveryColor = theme.palette.text.primary;
  } else if (delivery.status === 'error') {
    deliveryColor = theme.palette.warning.main;
  } else if (delivery.status === 'pending' || delivery.status === 'delivered' || delivery.status === 'completed') {
    deliveryColor = theme.palette.success.main;
  } else {
    deliveryColor = theme.palette.text.primary;
  }

  if (!delivery) {
    const sources = order.shipping_lines.map(l => l.source).join(', ');
    if (sources.indexOf('Grocer Box') !== -1) {
        return (
          <OrderType color={deliveryColor}>
            <Warning style={{marginRight: '1rem'}}/>No Delivery
          </OrderType>
        )
    }
    return (
      <OrderType color={deliveryColor}>
        {order.shipping_lines[0]?.title || order.service?.provider}
      </OrderType>
    )
  }

  if (delivery.status === 'error') {
    return (
      <Tooltip content={delivery.extra ? delivery.extra.error : 'No delivery found'}>
        <OrderType color={deliveryColor}>
          <Warning style={{marginRight: '1rem'}}/>Delivery Error
        </OrderType>
      </Tooltip>
    )
  }
  if (delivery.shipping_provider === STORE_PROVIDER) {
    return <OrderType color={deliveryColor}><StoreIcon style={{marginRight: '1rem'}} />
      Store Delivery {delivery.status === 'cancelled' ? 'Cancelled' : ''}
    </OrderType>
  }
  if (delivery.status === 'cancelled') {
    return <OrderType color={deliveryColor}><Warning style={{marginRight: '1rem'}}/>Delivery Cancelled</OrderType>;
  }

  if (delivery.shipping_label) {
    return (
      <OrderTypeLink color={deliveryColor} onClick={() => navigateExternal(delivery.shipping_label.replace('http://', 'https://'))}>
        <LocalShippingIcon style={{marginRight: '1rem'}} />
        Delivery Scheduled
      </OrderTypeLink>
    )
  }

  return (
    <OrderType color={deliveryColor}>
      <LocalShippingIcon style={{marginRight: '1rem'}} />
      Delivery Scheduled
    </OrderType>
  );
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //   return 'Delivery';
  // } else {
  //   return 'Delivery Error';
  // }
}
