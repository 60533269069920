import React from 'react'

import { CircularProgress } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

export default function LoadingPage() {
  return (
    <GridContainer justify="center">
      <GridItem
        style={{ textAlign: 'center', marginTop: '10%' }}
        xs={12} sm={6} md={4}>
        <CircularProgress />
      </GridItem>
    </GridContainer>
  )
}
