import React, { useState } from 'react'

import { useAdmin } from 'context/admin';
import { Button, Layout, Card, Form, FormLayout, TextField, ChoiceList } from '@shopify/polaris';
import { useAuth } from 'context/auth';
import { useHistory } from 'react-router-dom';

const newUser = {
  name: 'Test',
  email: "itsmikias@gmail.com",
  password: "password",
  shop_ids: [9, 10, 11]
}

export default function NewUserPage() {
  const admin = useAdmin();
  const auth = useAuth();
  const [form, setForm] = useState(newUser);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    auth.register(form).then(() => history.push({ pathname: '../users' }));
  }

  const valid = Object.keys(form).map(key => !!form[key]).indexOf(false) === -1;

  return (
    <Layout.AnnotatedSection
      title="Create User"
      description=""
    >
      <Card sectioned>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              label="Name"
              type="text"
              value={form.name}
              onChange={(name) => setForm({ ...form, name })}
            />

            <TextField
              label="Email"
              type="email"
              value={form.email}
              onChange={(email) => setForm({ ...form, email })}
            />

            <TextField
              label="Password"
              type="password"
              value={form.password}
              onChange={(password) => setForm({ ...form, password })}
            />

            <ChoiceList
              allowMultiple
              title="Shops"
              choices={admin.shops ? admin.shops.map(shop => ({ label: shop.current.name, value: shop.id })) : []}
              selected={form.shop_ids || []}
              onChange={(shop_ids) => setForm({ ...form, shop_ids })}
            />

            <Button loading={admin.saving} disabled={!valid} submit>Create User</Button>
          </FormLayout>
        </Form>
      </Card>
    </Layout.AnnotatedSection>
  )
}
