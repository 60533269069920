import React, { useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  DataTable,
  Pagination,
  Stack,
} from "@shopify/polaris";

export default function FileImportsTable({
  data = [],
  processing,
  handleProcess,
  handleReresh,
  handleViewResults,
}) {
  const [page, setPage] = useState(1);
  const pageSize = 20;

  const headers = [
    { type: "text", label: "id", fn: (row) => row.id },
    { type: "text", label: "filename", fn: (row) => row.filename },
    // { type: "text", label: "content_type", fn: (row) => row.content_type },
    // { type: "text", label: "created_at", fn: (row) => row.created_at },
    // { type: "text", label: "import_type", fn: (row) => row.import_type },
    { type: "text", label: "status", fn: (row) => row.status },
    {
      type: "text",
      label: "",
      fn: (row) => {
        return (
          <ButtonGroup>
            <Button
              size="slim"
              disabled={row.status === "processing"}
              loading={processing === row.id}
              onClick={() => handleProcess(row)}
            >
              {row.status === "completed"
                ? "Import again"
                : row.status === "pending"
                ? "Process"
                : "Retry"}
            </Button>
            {row.status !== "pending" &&
            row.status !== "processing" &&
            row.result ? (
              <Button
                size="slim"
                loading={processing === row.id}
                onClick={() => handleViewResults(row)}
              >
                Results
              </Button>
            ) : null}
          </ButtonGroup>
        );
      },
    },
  ];

  const rows = useMemo(
    () => data.slice((page - 1) * pageSize, page * pageSize + 1 - 1),
    [data, page]
  );

  return data && data.length > 0 ? (
    <Card actions={[{ content: "Refresh List", onAction: handleReresh }]}>
      <DataTable
        columnContentTypes={headers.map((h) => h.type)}
        headings={headers.map((h) => h.label)}
        rows={rows.map((row) => headers.map((header) => header.fn(row)))}
        verticalAlign="middle"
        onClick={(row) => {
          console.log(row);
        }}
      />
      {data.length > pageSize ? (
        <Stack distribution="center" alignment="center">
          <div style={{ padding: "1rem" }}>
            <Pagination
              label="Files"
              hasPrevious={page > 1}
              previousKeys={[74]}
              previousTooltip="j"
              onPrevious={() => {
                setPage(page - 1);
              }}
              hasNext={page * pageSize < data.length}
              nextKeys={[75]}
              nextTooltip="k"
              onNext={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </Stack>
      ) : null}
    </Card>
  ) : null;
}
